import axios from 'axios';
import { apiHost } from "../config"

export async function deleteCredentials( clientId ) {
    var url = apiHost + '/api/admin/credentials/' + clientId;
    var response = await axios.delete( url );
    return response;
}

export async function regenerateCredentials( clientId ) {
    var url = apiHost + '/api/admin/credentials/' + clientId + "/regenerate";
    var response = await axios.post( url );
    return response;
}