import { autocomplete } from "../../services/site-owner-service";
import { __ } from "../../app"
import AjaxAutocomplete from './index'

export default function AutocompleteSiteOwner( {
    owner,
    onOwnerChanged,
    isOwnerLoading,
    label = __("Site Owner"),
    wrapperProps = {},
    disabled = false
} ) {


    // Assign component classname
    if( !wrapperProps?.className ) {
        wrapperProps.className = 'components__site-owner-autocomplete';
    } else {
        wrapperProps.className = 'components__site-owner-autocomplete ' + wrapperProps.className;
    }
 
    // Render
    return (
        <div { ...wrapperProps }>

            {/* Render primary component when loaded */}
           <AjaxAutocomplete
                label={ label }
                value={ owner }
                onValueChanged={ owner => onOwnerChanged?.( { ...owner } ) }
                isValueLoading={ isOwnerLoading }
                allowEmptyTerm={ true }
                getAutocompleteResults={ 
                    async ( term, abortController ) => await autocomplete( term, { abortController } ) 
                }
                disabled={ disabled }
            />

        </div>
    )
}