import React from "react"
import { Card, CardBody } from "reactstrap"

import { __ } from "../../app"
import { apiHost } from "../../config"
import DataTable from '../../components/datatable'
import { Link, useHistory } from 'react-router-dom'
import PageHeader from "../../components/page-header"

var url = apiHost + "/api/admin/Customer/datatables";
function getColumns( { history } ) {
    return [
        {
            headerName: __( 'Customer Id' ),
            field: 'customerId',
            headerAlign: 'left',
            align: 'left',
            flex: 1
        },
        {
            headerName: __( 'Account No' ),
            field: 'accountId',
            flex: 1,
            hide: true
        },
        {
            headerName: __( 'Units Owned' ),
            field: 'unitsOwned',
            flex: 1,
        },
        {
            headerName: __( 'Vendor Id' ),
            field: 'vendorId',
            flex: 1,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/vendors/id/" + row.vendorId } >{ formattedValue }</Link>
            ),
            hide: true
        },
        {
            headerName: __( 'Vendor' ),
            field: 'vendor',
            flex: 3,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/vendors/id/" + row.vendorId } >{ formattedValue }</Link>
            )
        }
    ]
}

export default function ListCustomers() {
    const history = useHistory();
    const columns = getColumns( { history } )
    
    return (
        <div className="table_list table__customers">

            <PageHeader
                header={ __( 'Customers' ) }
                breadcrumbs={ [ 
                    { 
                        to: '/customers',
                        label: __( 'Customers' ),
                        active: true
                    }
                ] }
            />
            
            <Card>
                <CardBody>
                    <div style={{ height: 'calc( 100vh - 190px)', width: '100%' }}>
                        <div style={{ display: 'flex', height: '100%' }}>
                            <div style={{ flexGrow: 1 }}>
                                <DataTable 
                                    url={ url }
                                    columns={ columns }
                                    getRowId={ row => row.customerId }
                                />
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>

        </div>
    )
}