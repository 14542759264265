import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { __ } from "../../app";


export function ContinueCancelDialog( { isOpen = false, setOpen = false, title = '', content = false, onContinueClicked, ConfirmText = __( 'Continue' ), CancelText = __( 'Cancel' ) } ) {
    return (
        <Dialog
            open={ isOpen }
            onClose={ () => setOpen( false ) }
        >
            
            <DialogTitle>{ title }</DialogTitle>
                
                { content != false && <DialogContent>{content}</DialogContent> }

                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={ () => setOpen( false ) }
                    
                    >{ CancelText }</Button>
                    <Button
                        variant="contained"
                        onClick={ () => onContinueClicked() }
                    
                    >{ ConfirmText }</Button>

                </DialogActions>

        </Dialog>
    )

}