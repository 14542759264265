
import { __ } from "../app"
import { apiHost, authHost } from "../config"
import { fetchResultsByTerm, useFetchResultByUrl } from './helpers/autocomplete-service';

export async function autocomplete( term, { abortController = AbortController } ) {
    var url = apiHost + '/api/admin/Biome/autocomplete';
    return await fetchResultsByTerm( url, term, { abortController } )
}

export function useBiome( biomeId = false, { context = 'autocomplete' } = {} ) {
    if( context == 'autocomplete' ) {
        return useFetchResultByUrl( {
            getId: r => r?.biomeId,
            getUrl: r => apiHost + '/api/admin/Biome/autocomplete/' + parseInt( r?.biomeId ),
            initialState: {
                biomeId
            }
        } )
    }

    throw 'Unsupported site context "' + context + '".';
}