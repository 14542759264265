import React, { Fragment } from 'react'
import { Button, IconButton, TextField, Tooltip  } from '@mui/material'
import { Col, Row } from 'reactstrap'
import { defaultFieldVariant, __ } from '../../app'
import MuiPhoneNumber from 'material-ui-phone-number'
import AddressFields from '../address-fields/address-fields'
import { useSelector } from 'react-redux'
import { Delete, Cached } from '@mui/icons-material';
import { deleteCredentials, regenerateCredentials } from '../../services/credentials-service'
import { createCredentials } from '../../services/vendor-service'



export default function VendorEditor( { vendorModel, isVendorModelLoading, onVendorModelChanged, ActionsComponent = false } ) {
    const role = useSelector(state => state.auth.user?.profile?.role)
    const isEditsDisabled = role != 'AdminUser';
    
    function onNameChanged( event ) {
        onVendorModelChanged?.( {
            ...vendorModel,
            name: event.target.value
        } )
    }

    function onEmailChanged( event ) {
        onVendorModelChanged?.( {
            ...vendorModel,
            email: event.target.value
        } )
    }

    function onContactNameChanged( event ) {
        onVendorModelChanged?.( {
            ...vendorModel,
            contactName: event.target.value
        } )
    }

    function onCompanyNameChanged( event ) {
        onVendorModelChanged?.( {
            ...vendorModel,
            companyName: event.target.value
        } )
    }

    function onPhoneChanged( value ) {
        onVendorModelChanged?.( {
            ...vendorModel,
            phone: value
        } )
    }

    function onAddressChanged( address ) {
        onVendorModelChanged?.( {
            ...vendorModel,
            address: {
                ... address
            }
        } )
    }

    async function onDeleteKeyClicked( index ) {
        var client = vendorModel?.clients[ index ];
        if( !client ) {
            return;
        }

        if( !window.confirm( __( "Are you sure you want to delete this API Key. This may cause interuptions for any connected services" ) ) ) {
            return;
        }

        var response = await deleteCredentials( client.clientId );
        if( response.status != 200 ) {
            return;
        }

        var clients = [ ...vendorModel?.clients ];
        clients.splice( index, 1 );

        onVendorModelChanged?.( {
            ...vendorModel,
            clients
        } ) 
    }

    async function onRegenerateSecretClicked( index ) {
        var client = vendorModel?.clients[ index ];
        if( !client ) {
            return;
        }

        if( !window.confirm( __( "Are you sure you want to regenerate this API Key. This may cause interuptions for any connected services" ) ) ) {
            return;
        }

        var response = await regenerateCredentials( client.clientId );
        if( response.status != 200 ) {
            return;
        }

        var clients = [ ...vendorModel?.clients ];
        clients[ index ] = {
            ...client,
            secret: response.data.secret
        }

        onVendorModelChanged?.( {
            ...vendorModel,
            clients
        } ) 
    }

    async function onAddKeyClicked() {
        if( !vendorModel?.id ) {
            return;
        }

        var keyName = window.prompt( 'Please enter a name for your API key:' );
        if( keyName == null || keyName.trim() == '' ) {
            alert( 'API Key name is required.' );
            return;
        }

        var response = await createCredentials( vendorModel?.id, keyName );
        if( response.status != 200 ) {
            return;
        }

        var client = response.data;
        onVendorModelChanged?.( {
            ...vendorModel,
            clients: [ ...vendorModel.clients, client ]
        } );

    }

    return (
        <div className="components__vendor-editor">
            <Row>
                <Col md="7" className='mb-5'>
                    <h4 className='mb-3'>{__( "Vendor Details" )}</h4>

                    { vendorModel?.id > 0 &&
                        <TextField
                            className="w-100 mb-3" 
                            label={ __( "Vendor Id" ) }
                            disabled={ true || isEditsDisabled }
                            variant={ defaultFieldVariant } 
                            value={ isVendorModelLoading ? __( 'Loading...' ) : vendorModel.id }
                            onChange={ e => false }
                        />
                    }    

                    { vendorModel?.accountId > 0 &&
                        <TextField
                            className="w-100 mb-3" 
                            label={ __( "Account Id" ) }
                            disabled={ true || isEditsDisabled }
                            variant={ defaultFieldVariant } 
                            value={ isVendorModelLoading ? __( 'Loading...' ) : vendorModel.accountid }
                            onChange={ e => false }
                        />
                    }    

                    <TextField
                        className="w-100 mb-3" 
                        label={ __( "Name" ) }
                        variant={ defaultFieldVariant } 
                        disabled={ isVendorModelLoading || isEditsDisabled }
                        value={ isVendorModelLoading ? __( 'Loading...' ) : ( vendorModel?.name || '' ) }
                        onChange={ e => onNameChanged( e ) }
                    />

                    <TextField
                        className="w-100 mb-3" 
                        label={ __( "Email" ) }
                        variant={ defaultFieldVariant } 
                        disabled={ isVendorModelLoading || isEditsDisabled}
                        value={ isVendorModelLoading ? __( 'Loading...' ) : ( vendorModel?.email || '' ) }
                        onChange={ e => onEmailChanged( e ) }
                    />

                    <TextField
                        className="w-100 mb-3" 
                        label={ __( "Contact Name" ) }
                        variant={ defaultFieldVariant } 
                        disabled={ isVendorModelLoading || isEditsDisabled }
                        value={ isVendorModelLoading ? __( 'Loading...' ) : ( vendorModel?.contactName || '' ) }
                        onChange={ e => onContactNameChanged( e ) }
                    />

                    <TextField
                        className="w-100 mb-3" 
                        label={ __( "Company Name" ) }
                        variant={ defaultFieldVariant } 
                        disabled={ isVendorModelLoading || isEditsDisabled }
                        value={ isVendorModelLoading ? __( 'Loading...' ) : ( vendorModel?.companyName || '' ) }
                        onChange={ e => onCompanyNameChanged( e ) }
                    />

                    <MuiPhoneNumber
                        defaultCountry='au'
                        label={ __( "Phone" ) }
                        variant={ defaultFieldVariant } 
                        className="w-100 mb-3" 
                        disabled={ isVendorModelLoading || isEditsDisabled }
                        value={ vendorModel?.phone }
                        onChange={ value => onPhoneChanged( value ) }
                    />

                    <AddressFields
                        address={ vendorModel?.address || {} }
                        onChange={ address => onAddressChanged( address ) }
                        isAddressLoading={ isVendorModelLoading }
                        disabled={ isVendorModelLoading || isEditsDisabled  }
                    />

                    { !isEditsDisabled && ActionsComponent && <ActionsComponent /> }
                </Col>
                <Col md="4" className='mb-5'>
                    <h4 className='mb-3'>{__( "API Keys" )}</h4>

                    { vendorModel?.clients?.map( ( client, index )  => {

                        var nameClaim = client.claims.find( claim => claim.type == 'ClientName' );

                        return (
                            <Fragment key={ client.clientId }>
                                <div className="client-id d-flex mb-3 mt-2">
                                    <TextField
                                        className="flex-fill" 
                                        label={ nameClaim?.value || __( 'Untitled key' ) }
                                        variant={ defaultFieldVariant } 
                                        value={ isVendorModelLoading ? __( 'Loading...' ) : ( client.clientId ) }
                                        onChange={ () => false }
                                    />

                                    <Tooltip title={ __( 'Delete Key' ) } >
                                        <IconButton
                                            className='danger'
                                            color="error"
                                            onClick={ () => onDeleteKeyClicked( index ) }
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>

                                </div>
                                <div className="client-secret d-flex mb-5 mt-2 ">
                                    <TextField
                                        className="flex-fill" 
                                        label={ "Secret" }
                                        variant={ defaultFieldVariant } 
                                        value={ isVendorModelLoading ? __( 'Loading...' ) : ( client.secret || '*************************' ) }
                                        onChange={ () => false }
                                    />

                                    <Tooltip title={ __( 'Regenerate Secret' ) } >
                                        <IconButton
                                            className='danger'
                                            onClick={ () => onRegenerateSecretClicked( index ) }
                                        >
                                            <Cached />
                                        </IconButton>
                                    </Tooltip>

                                </div>
                            </Fragment>
                        )

                    } ) }
                    <Button 
                        variant="contained"
                        onClick={ () => onAddKeyClicked() }
                    >{ __( 'Create API Key' ) }</Button>

                </Col>
            </Row>
        </div>
    );
}


