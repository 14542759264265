
import AjaxAutocomplete from './index'
import { __ } from "../../app"
import { autocompleteSitesWithBalance } from "../../services/account-service";
import { Box } from '@mui/material';

export default function AutocompleteSiteForSender( {
    site,
    onSiteChanged,
    isSiteLoading,
    senderAccountId,
    label = __("From Site"),
    wrapperProps = {},
    readonly = false
} ) {


    // Assign component classname
    if( !wrapperProps?.className ) {
        wrapperProps.className = 'components__autocomplete-site-for-sender';
    } else {
        wrapperProps.className = 'components__autocomplete-site-for-sender ' + wrapperProps.className;
    }
 
    // Render
    return (
        <div { ...wrapperProps }>

            {/* Render primary component when loaded */}
           <AjaxAutocomplete
                label={ label }
                value={ site }
                onValueChanged={ site => onSiteChanged?.( { ...site } ) }
                isValueLoading={ isSiteLoading }
                allowEmptyTerm={ true }
                readonly={ readonly }
                getAutocompleteResults={ 
                    async ( term, abortController ) => await autocompleteSitesWithBalance( senderAccountId, term, { abortController } ) 
                }
                autocompleteProps={{
                    renderOption: (props, option) => {
                        return (
                            <li {...props}>
                                <Box
                                    sx={{
                                        flexGrow: 1
                                    }}
                                >
                                    <b>{option.label}</b>
                                    <br />
                                    <span>{ __('No of Zones: ') + option.noOfZones}</span>
                                    <br />
                                    <span>{ __('Units Available: ') + option.unitsOwned}</span>
                                </Box>
                            </li>
                        )
                    }
                }}
            />

        </div>
    )
}