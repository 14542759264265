import { __ } from "../../app"
import { autocomplete } from "../../services/biome-service";
import AjaxAutocomplete from './index'

export default function AutocompleteBiome( {
    biome,
    onBiomeChanged,
    isBiomeLoading,
    label = __("Vegetation Type"),
    wrapperProps = {},
    disabled = false
} ) {


    // Assign component classname
    if( !wrapperProps?.className ) {
        wrapperProps.className = 'components__biome-autocomplete';
    } else {
        wrapperProps.className = 'components__biome-autocomplete ' + wrapperProps.className;
    }
 
    // Render
    return (
        <div { ...wrapperProps }>

            {/* Render primary component when loaded */}
           <AjaxAutocomplete
                label={ label }
                value={ biome }
                onValueChanged={ biome => onBiomeChanged?.( { ...biome } ) }
                isValueLoading={ isBiomeLoading }
                allowEmptyTerm={ true }
                getAutocompleteResults={ 
                    async ( term, abortController ) => await autocomplete( term, { abortController } ) 
                }
                disabled={ disabled }
            />

        </div>
    )
}