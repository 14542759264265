import { __ } from "../../app"
import { autocomplete } from "../../services/unit-type-service";
import AjaxAutocomplete from './index'

export default function AutocompleteUnitType( {
    unitType,
    onUnitTypeChanged,
    isUnitTypeLoading,
    label = __("Vegetation Type"),
    wrapperProps = {},
    disabled = false
} ) {


    // Assign component classname
    if( !wrapperProps?.className ) {
        wrapperProps.className = 'components__unit-type-autocomplete';
    } else {
        wrapperProps.className = 'components__unit-type-autocomplete ' + wrapperProps.className;
    }
 
    // Render
    return (
        <div { ...wrapperProps }>

            {/* Render primary component when loaded */}
           <AjaxAutocomplete
                label={ label }
                value={ unitType }
                onValueChanged={ unitType => onUnitTypeChanged?.( { ...unitType } ) }
                isValueLoading={ isUnitTypeLoading }
                allowEmptyTerm={ true }
                getAutocompleteResults={ 
                    async ( term, abortController ) => await autocomplete( term, { abortController } ) 
                }
                disabled={ disabled }
            />

        </div>
    )
}