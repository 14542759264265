import { Button, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { defaultFieldVariant, __ } from '../../app'
import { useSiteOwner } from '../../services/site-owner-service'
import AddressFields from '../address-fields/address-fields'
import AutocompleteSiteOwner from '../autocomplete/autocomplete-site-owner'

export default function SiteEditor( { siteModel, isSiteModelLoading, onSiteModelChanged = false, ActionsComponent = false } ) {

    // Autoload Site Owner
    const [ owner, setOwner, isOwnerLoading ] = useSiteOwner( siteModel?.siteOwner?.id, { context: 'autocomplete' }  )
    const role = useSelector(state => state.auth.user?.profile?.role)

    const isEditsDisabled = role != 'AdminUser';
    
    function onSiteNameChanged( event ) {
        onSiteModelChanged?.( {
            ...siteModel,
            siteName: event.target.value
        } )
    }
    
    function onSecurityMechanismChanged( event ) {
        onSiteModelChanged?.( {
            ...siteModel,
            securityMechanism: event.target.value
        } )
    }

    function onReferenceChanged( event ) {
        onSiteModelChanged?.( {
            ...siteModel,
            reference: event.target.value
        } )
    }
    
    function onAddressChanged( address ) {
        onSiteModelChanged?.( {
            ...siteModel,
            address: {
                ... address
            }
        } )
    }

    function onSiteOwnerChanged( owner ) {
        onSiteModelChanged?.( {
            ...siteModel,
            siteOwner: {
                id: owner.ownerId
            }
        } )
        setOwner( owner );
    }
    
    return (
        <div className="components__zone-editor">
            <Row>
                <Col>
                    <h4 className='mb-3'>{__( "Site Details" )}</h4>

                    { siteModel?.Id > 0 &&
                        <TextField
                            className="w-100 mb-3" 
                            label={ __( "Site Id" ) }
                            variant={ defaultFieldVariant } 
                            disabled={ true }
                            value={ isSiteModelLoading ? __( 'Loading...' ) : siteModel.id }
                            onChange={ e => false  }

                        />
                    }
                                        
                    <TextField
                        className="w-100 mb-3" 
                        label={ __( "Site Name" ) }
                        variant={ defaultFieldVariant } 
                        disabled={ isSiteModelLoading || isEditsDisabled}
                        value={ isSiteModelLoading ? __( 'Loading...' ) : ( siteModel?.siteName || '' ) }
                        onChange={ e => onSiteNameChanged( e ) }
                    />
                                        
                    <TextField
                        className="w-100 mb-3" 
                        label={ __( "Site Reference" ) }
                        variant={ defaultFieldVariant } 
                        disabled={ isSiteModelLoading || isEditsDisabled }
                        value={ isSiteModelLoading ? __( 'Loading...' ) : ( siteModel?.reference || '' ) }
                        onChange={ e => onReferenceChanged( e ) }
                    />
                                        
                    <TextField
                        className="w-100 mb-3" 
                        label={ __( "Security Mechanism" ) }
                        variant={ defaultFieldVariant } 
                        disabled={ isSiteModelLoading || isEditsDisabled }
                        value={ isSiteModelLoading ? __( 'Loading...' ) : ( siteModel?.securityMechanism || '' ) }
                        onChange={ e => onSecurityMechanismChanged( e ) }
                    />

                    <AutocompleteSiteOwner 
                        owner={ owner }
                        onOwnerChanged={ owner => onSiteOwnerChanged( owner ) }
                        isOwnerLoading={ isOwnerLoading }
                        wrapperProps={{
                            className: "mb-3"
                        }}
                        disabled={ isSiteModelLoading || isEditsDisabled }
                    />
                    

                    <AddressFields
                        address={ siteModel?.address || {} }
                        onChange={ address => onAddressChanged( address ) }
                        isAddressLoading={ isSiteModelLoading }
                        disabled={ isSiteModelLoading || isEditsDisabled  }
                    />

                    { !isEditsDisabled && ActionsComponent && <ActionsComponent /> }
                </Col>
            </Row>
        </div>
    )
}