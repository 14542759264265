export const enumOrderStatus = [
    'COMPLETED',
    'PENDING',
    'CANCELLED',
    'REFUNDED'
];

export const enumVegetationTypes = [
    'Coastlands',
    'Dry Forests',
    'Grasslands',
    'Heathlands',
    'Mallee Woodlands',
    'Rainforests',
    'Riverlands',
    'Shrublands',
    'Tall Forests',
    'Wetlands',
    'Woodlands'
]

export const enumCreditNames = [
    'VBU'
];

export const enumZoneStatus = [
    'ACTIVE',
    'PENDING',
    'RETIRED'
]