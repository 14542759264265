import { DataGridPro, GridFilterInputValue, GridToolbar } from '@mui/x-data-grid-pro';
import React, { useState, useEffect, useRef, useCallback, Fragment } from "react";
import { __ } from '../../app';
import { urlEncode, urlDecode } from '../../helpers/url-encoding';
import { datatables } from '../../services/helpers/datatables-service';
import { DateTimePicker } from '@mui/lab';
import axios from 'axios';

export function timeCellRenderer( { value } ) {
    if( !value ) {
        return false;
    }

    var expr = /(\d{1,2}):(\d{2})/
    var matches = value.match( expr );

    if( !matches ) {
        return false;
    }

    return matches[1] + ":" + matches[2];
}


const DataTable = ( { 
    url, 
    columns: _columns, 
    getRowId = row => row.id,
    DialogsComponent = false,
    hideRows = false,
    queryParams = {},
    removeRowsOnLoad = false
} ) => {
    

    // Load initial state from query params
    const initialState = urlDecode( window.location.search )?.query;
    
    const [ isLoading, setLoading ]  = useState( false )
    const [ columns, setColumns ]    = useState( [ ... loadColumnDefaults( _columns ) ])
    const [ state, setState ]        = useState( {
        sorting:     initialState?.sorting || [],
        filters:     initialState?.filters || { items: [] },
        currentPage: initialState?.currentPage || 1,
        pageSize:    initialState?.pageSize || 100,
        rows:        [],
        rowCount:    0
    } );


    var defaultVisibilityModel = {};
    for( var i in columns ) {
        var col = columns[i];
        if( col.field ) {
            defaultVisibilityModel[col.field] = col?.hide == true ? false : true;
        }
    }
    
    const [ columnVisibilityModel, setColumnVisibilityModel ] = useState( defaultVisibilityModel )

    // Check this before making state updates
    const isMounted = useRef(true)
    useEffect(() => {
        isMounted.current = true;
        return function cleanup()  {
            isMounted.current = false;
        }
    })

    // Reload when query params have been changed
    const lastQueryParams = useRef( queryParams );
    useEffect(() => {
        if( isLoading ) return;
        
        if( JSON.stringify(queryParams) == JSON.stringify(lastQueryParams.current) ) {
            return; // Query params have not changed
        }

        lastQueryParams.current = queryParams;
        updateRowsFromApi();


    }, [ queryParams, lastQueryParams, isLoading ])

    // Can be used to load additional column defaults
    function loadColumnDefaults( columns ) {
        columns = columns.map( col => {
            return col
        } );

        return columns;
    }

    function onColumnVisibilityChange( event ) {
        
    }
    
    // Loads ENUM values from the filter schema so a dropdown can be used with the filter
    function getOperatorProps( filterSchema, field ) {

        if( filterSchema.valueType === 'NUMBER' ) {
            return {
                InputComponent: GridFilterInputValue,
                InputComponentProps: { type: 'number' },
            }
        }

        if( filterSchema.valueType === 'DATE' ) {
            return {
                InputComponent: GridFilterInputValue,
                InputComponentProps: { type: 'date' },
            }
        }

        if( filterSchema.valueType === 'TIME' ) {
            return {
                InputComponent: GridFilterInputValue,
                InputComponentProps: { type: 'time' },
            }
        }

        if( filterSchema.valueType === 'DATETIME' ) {
            return {
                InputComponent: DateTimePicker,
                InputComponentProps: { type: 'datetime-local' },
            }
        }

        if( filterSchema.valueType === 'BOOLEAN' ) {
            return {
                InputComponent: GridFilterInputValue,
                InputComponentProps: { type: 'checkbox' },
            }
        }

        // if( filterSchema.valueType === 'ENUM' || field?.type ) {
        //     return {
        //         InputComponent: GridFilterInputValue,
        //         InputComponentProps: { type: 'singleSelect' },
        //     }
        // }

        return {
            InputComponent: GridFilterInputValue,
            InputComponentProps: { type: field?.type || 'text' }
        };
    }

    const operatorLabels = {
        'EQUAL':                 __('equals'),
        'NOT_EQUAL':             __('is not equal to'),
        'GREATER_THAN':          __("is greater than"),
        'GREATER_THAN_OR_EQUAL': __('is greater than or equal to' ),
        'LESS_THAN':             __("is less than"),
        'LESS_THAN_OR_EQUAL':    __('is less than or equal to'),
        'CONTAINS':              __('contains'),
        'NOT_CONTAINS':          __('does not contain'),
        'STARTS_WITH':           __('starts with'),
        'ENDS_WITH':             __('ends with'),
        'BEFORE':                __('is before'),
        'AFTER':                 __('is after'),
        'AT_OR_BEFORE':          __('is at or before'),
        'AT_OR_AFTER':           __('is at or after'),
    };

    // For some silly reason DataTables returns the label not the value in the filter model...
    // this is a hotfix for that.
    function getOperatorValue( value ) {

        if( operatorLabels[ value ] ) {
            // Incase we patch to a new version where this bug is fixed
            return value;
        }

        return Object.keys(operatorLabels).find(key => operatorLabels[key] === value);
    }

    function addColumnSchemaModifications( _column, fieldSchema ) {
        
        var column = {
            ... _column,
            filterable: fieldSchema?.filterable,
            sortable: fieldSchema?.sortable,
            hide: !columnVisibilityModel[ _column['field'] ]
        };

        if(fieldSchema?.supportedFilters) {
            column.filterOperators = Object.entries(fieldSchema.supportedFilters)?.map( ( [ value, filter ] ) => ({
                value,
                label: operatorLabels[ value ] || value,
                ...getOperatorProps( filter, column )
            }))
        }

        return column;
    }

    function pushQueryArgsToWindowHistory() {
        
        const { sorting, filters, currentPage, pageSize } = state;

        var query = {};
        if( sorting?.length ) {
            query.sorting = sorting;
        }

        if( filters?.items?.length ) {
            query.filters = filters;
        }

        if( currentPage != 1 ) {
            query.currentPage = currentPage;
        }

        if( pageSize != 50 ) {
            query.pageSize = pageSize;
        }
        
        window.history.replaceState( {}, '', urlEncode( { data: Object.keys(query).length == 0 ? {} : { query } } ) )
    }

    const lastRequest = useRef({});
    const lastRequestController = useRef();
    
    const updateRowsFromApi = useCallback( async () => {
        const { sorting, filters, currentPage, pageSize } = state;



        // Premodify request filters
        const _filters = filters?.items?.filter( filter => {
            var column = columns[filter];
            if( column?.type == 'date' || column?.type == 'time' || column?.type == 'datetime' ) {
                return filter?.value; // date / time / datetime columns need to be completed to be submitted.
            }

            return true;
        } )

        // Build request object
        const request = { 
            url,
            pageNumber:         currentPage,
            pageSize:           pageSize,
            sortBy:             sorting?.[0]?.field,
            sortingDirection:   sorting?.[0]?.sort,
            queryParams:        lastQueryParams.current,
            filters: _filters?.map(filter => ({
                field: filter.columnField,
                // value must be a string hotfix
                value: "" + filter.value,
                operator: getOperatorValue( filter.operatorValue ),
                logicalOperator: filters?.linkOperator || "AND",
            })) || []
        }

        // Don't duplicate similar requests
        if( JSON.stringify( request ) == JSON.stringify( lastRequest.current ) ) {
            return;
        }

        // Set this as last request
        lastRequest.current = request;

        // Cancel the last request
        if( lastRequestController.current ) {
            lastRequestController.current.abort();
        }

        // Assign a new abortion controller
        var controller = new AbortController();
        lastRequestController.current = controller;

        // Set is loading
        setLoading( true );

        // Push query updates to URL history
        pushQueryArgsToWindowHistory();
        
        // Maybe remove rows on load
        if( removeRowsOnLoad ) {
            setState( {
                ...state,
                rows: [],
                rowCount: 0
            } )
        }

        // Send Request, Get response
        var response
        try {
            response = await datatables( request, controller );

        } catch( e ) {

            if( !isMounted.current ) {
                return; // component is no longer mounted
            }

            setLoading( false );

            // Request was cancelled, don't throw the exception
            if( axios.isCancel( e ) ) {
                return;
            }

            console.error( 'Could not load rows from api', e );
            return;
        }

        //todo: error handling
        
        var pagination = JSON.parse( response.headers['x-pagination'] );
        if( !isMounted.current ) {
            return; // component is no longer mounted
        }

        // Map in columns
        setColumns( columns?.map( column => addColumnSchemaModifications( column, response.data.schema.fields[ column?.field ] ) ) );
        
        setState( {
            ... state,
            currentPage: pagination.pageNumber,
            pageSize:    pagination.pageSize,
            rowCount:    pagination.count || 0,
            rows:        response?.data?.rows || []
        } )
 
        setLoading( false );

    }, [ state, isLoading ] )

    // Update rows from API on state changes
    useEffect(() => {
        updateRowsFromApi();
    }, [ state ] )


    function onPageSizeChanged( pageSize ) {
        setState( { ... state, pageSize } );
    }

    function onFilterModelChanged( filters ) {

        filters.items = ( filters?.items || [] ).map( ( filter, i ) => {
            var currentFilter = state?.filters?.items[i];
            if( currentFilter && filter.columnField == currentFilter.columnField ) {
                return filter;
            }

            var defaultOperator = columns.find( c => c.field == filter.columnField )?.filterOperators[0]?.value
            if( !defaultOperator ) {
                return filter;
            }

            return { ...filter, operatorValue: defaultOperator }
        } );

        setState( { ... state, filters } );
    }
    function onSortingModelChanged( sorting ) {
        setState( { ... state, sorting } );
    }

    function onPageChanged( currentPage ) {
        setState( { ... state, currentPage } );
    }

    function onColumnOrderChanged( ordering ) {
        setColumns( arrayMove( columns, ordering.oldIndex - 1, ordering.targetIndex - 1 ) )
    }

    function arrayMove( arr, oldIndex, newIndex ) {
        if (newIndex >= arr.length) {
            var k = newIndex - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
        return arr; // for testing
    }

    return (
        <Fragment>

            {/* Used to render dialogs that change the datagrid */}
            { DialogsComponent && <DialogsComponent 
                state={ state }
                setState={ state => setState( state ) }
            /> }

            <DataGridPro
                checkboxSelection={true}
                
                components={{
                    Toolbar: GridToolbar,
                }}

                density="comfortable"

                rows={ hideRows ? [] : state.rows }
                columns={ columns }
                onColumnOrderChange={ ordering => onColumnOrderChanged( ordering ) }
                getRowId={ getRowId }

                // Server-side page-size
                autoPageSize={ false }
                pageSize={ state.pageSize }
                onPageSizeChange={ pageSize => onPageSizeChanged( pageSize )}

                // Server-side sorting
                sortingMode="server"
                sortModel={ state.sorting }
                onSortModelChange={ model => onSortingModelChanged( model ) }

                // // Server-side pagination
                pagination
                rowsPerPageOptions={[ 100, 250, 500, 1000, 2500, 5000 ]}
                rowCount={ state.rowCount }


                paginationMode="server"
                page={ state.currentPage - 1 }
                onPageChange={ page => onPageChanged( page + 1 ) }

                // // Server-side filters
                filterMode="server"
                filterModel={ state.filters }
                onFilterModelChange={ model => onFilterModelChanged( model ) }

                // Track column visibility
                columnVisibilityModel={ columnVisibilityModel }
                onColumnVisibilityModelChange={ model => {
                    console.log( 'model changed ', model );
                    setColumnVisibilityModel( model )
                }}

                // Server-side is loading results
                loading={ isLoading }
            />

        </Fragment>
    )
}

export default DataTable;