import React, { Fragment, useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Container } from "reactstrap"
import { __ } from "../../app"
import { createOrder, useOrder } from "../../services/order-service"
import {  useHistory } from 'react-router-dom'
import { Button, CircularProgress } from "@mui/material";

import PageHeader from "../../components/page-header"
import OrderEditor from "../../components/order-editor"

export default function AddOrder( {
} ) {
   
    const [ orderModel, setOrderModel, isOrderModelLoading ] = useOrder( false, { context: 'object' } )
    const [ isLoading, setLoading ] = useState( false );
    const [ errors, setErrors ] = useState( [] );

    const history = useHistory();

    useEffect( () => {
        console.log( 'Model Updated', orderModel )
    }, [ orderModel ] )

    async function onCreateOrderClicked( processOrder = false ) {

        if( isLoading ) return;
        setLoading( true );


        var errors = validateOrder();

        if( errors.length ) {
            setErrors( errors );
            setLoading( false );
            return;
        }

        var request = {
            receiverId: orderModel.receiverId,
            unitTransfers: orderModel.transfers.map( transfer => {
                return {
                    zoneId: transfer.zoneId,
                    senderId: transfer.senderId,
                    amount: transfer.unitsTransfered,
                    allocateBy: 'CREDIT'
                }
            } )
        }

        var response = await createOrder( request, processOrder );
        setLoading( false );

        if( !response?.data ) {
            setErrors( [ 'An unknown error occured, check console for more details.' ] );
            return;
        }

        if( !response.data.successful ) {
            setErrors( [ response.data.errorMessage ] );
            return;
        }

        var orderId = response.data.orderId;
        history.push( '/orders/id/' + orderId, { created: true });
    }

    function validateOrder() {
        var errors = [];

        if( !orderModel?.receiverId ) {
            errors.push( 'Receiver cannot be empty.' );
        }

        if( !orderModel?.transfers?.length ) {
            errors.push( 'Order must have at least one transfer.' );
        }

        var transfers = orderModel?.transfers || [];
        for( var i in transfers ) {
            var transfer = transfers[i];

            if( !transfer?.zoneId || !transfer?.unitsTransfered ) {
                errors.push( 'Missing or incomplete transfer information.' );
            }
        }

        return errors;
    }

    function ActionsComponent() {
        return (
            <Fragment>
                { isLoading && (
                    <CircularProgress />
                ) }

                <Button 
                    variant="outlined"
                    onClick={ () => onCreateOrderClicked( false ) }
                    disabled={ isLoading }
                >
                    { __( 'Create Order' ) }
                </Button>&nbsp;
                
                {/* <Button 
                    variant="contained"
                    onClick={ () => onCreateOrderClicked( true ) }
                    disabled={ isLoading }
                >
                    { __( 'Create & Process order' ) }
                </Button> */}
            </Fragment>
        )
    }

    return (
        
        <div className="views_view views__add-order">

            <PageHeader
                header={ __( 'Add Order' ) }
                breadcrumbs={ [ 
                    { 
                        to: '/orders',
                        label: __( 'Orders' )
                    } ,
                    { 
                        to: '/orders/add',
                        label: __( 'Add Order' ),
                        active: true
                    } 
                ] }
                alerts={ errors?.length > 0 ? [ 
                    {
                        severity: 'error',
                        title: __( 'Error(s) while creating order.' ),
                        withList: errors
                    }
                ] : [] }
            />

            <Card>
                <CardBody>
            
                    <OrderEditor 
                        orderModel={ orderModel }
                        isOrderModelLoading={ isOrderModelLoading }
                        onOrderModelChanged={ orderModel => {
                            setOrderModel( orderModel ) 
                        } }
                        isOrderModelLoading={ isOrderModelLoading }
                        ActionsComponent={ ActionsComponent }
                    />
                </CardBody>
            </Card>
        </div>
    )
}