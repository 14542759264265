import React, { Fragment, useEffect, useState } from "react"

import { Card, CardBody, CardTitle, Container } from "reactstrap"
import { __ } from "../../app"
import { createReseller, useReseller } from "../../services/reseller-service"
import { useHistory } from 'react-router-dom'
import { Button, CircularProgress } from "@mui/material";

import PageHeader from "../../components/page-header"
import ResellerEditor from "../../components/reseller-editor"

export default function AddReseller( {
} ) {
   
    const [ resellerModel, setResellerModel, isResellerModelLoading ] = useReseller( false, { context: 'object' } )
    const [ isLoading, setLoading ] = useState( false );
    const [ errors, setErrors ] = useState( [] );
    const history = useHistory();

    useEffect( () => {
        console.log( 'Model Updated', resellerModel )
    }, [ resellerModel ] )

    
    async function onSaveResellerClick() {
        if( isLoading ) return;
        setLoading( true );
        setErrors( [] )

        var errors = validateReseller();
        if( errors.length ) {
            setErrors( errors )
            setLoading( false )
            return;
        }

        var response = await createReseller( resellerModel );
        setLoading( false )

        if( response?.error && response?.message ) {
            setErrors( [ response.message ] );
            return;
        }

        if( !response?.data || !response?.data?.id ) {
            setErrors( [ 'An unknown error occured, check console for more details.' ] );
            return;
        }

        var resellerId = response.data.id;
        history.push( '/resellers/id/' + resellerId, { created: true });
    }

    function validateReseller() {
        var errors = [];

        if( !resellerModel?.vendor?.id ) {
            errors.push( 'Vendor cannot be empty.' )
        }

        if( !resellerModel?.email ) {
            errors.push( "Email cannot be empty." )
        }

        if( !resellerModel?.contactName ) {
            errors.push( "Contact name cannot be empty." )
        }

        if( !resellerModel?.companyName ) {
            errors.push( "Company name cannot be empty." );
        }

        if( !resellerModel?.phone ) {
            errors.push( "Phone cannot be empty." )
        }

        if( !resellerModel?.address?.city ) {
            errors.push( "Address city cannot be empty." )
        }

        if( !resellerModel?.address?.country ) {
            errors.push( "Address country cannot be empty." )
        }

        if( !resellerModel?.address?.line1 ) {
            errors.push( "Address Line 1 cannot be empty." )
        }

        if( !resellerModel?.address?.postCode ) {
            errors.push( "Address postcode cannot be empty." )
        }

        if( !resellerModel?.address?.state ) {
            errors.push( "Address state cannot be empty." )
        }

        return errors;
    }

    
    function ActionsComponent() {
        return (
            <Fragment>
                <Button 
                    variant="contained"
                    onClick={ () => onSaveResellerClick() }
                >
                    { __( 'Save' ) }
                </Button>
                        
                { isLoading && (
                    <CircularProgress />
                ) }
            </Fragment>
        )
    }


    return (

        
        <div className="views_view views__add-reseller">

            <PageHeader
                header={ __( 'Add Reseller' ) }
                breadcrumbs={ [ 
                    { 
                        to: '/resellers',
                        label: __( 'Resellers' )
                    } ,
                    { 
                        to: '/resellers/add',
                        label: __( 'Add Reseller' ),
                        active: true
                    } 
                ] }
                alerts={ errors?.length > 0 ? [ 
                    {
                        severity: 'error',
                        title: __( 'Error(s) while creating reseller.' ),
                        withList: errors
                    }
                ] : [] }
            />

            <Card className="p-4">
                <CardBody>
            
                    <ResellerEditor 
                        resellerModel={ resellerModel }
                        isResellerModelLoading={ isResellerModelLoading }
                        onResellerModelChanged={ resellerModel => {
                            setResellerModel( resellerModel ) 
                        } }
                        ActionsComponent={ ActionsComponent }
                    />
                </CardBody>
            </Card>
        </div>
    )
}