import React from "react"

import { __ } from "../../app"
import { apiHost,  } from "../../config"
import DataTable from '../datatable'
import { Link, useHistory } from "react-router-dom";
import { urlEncode } from "../../helpers/url-encoding";
import { enumVegetationTypes } from "../../enums";


export default function VendorSiteBalancesDatatable( { vendorId } ) {

    const history = useHistory()
    const columns = getColumns( { history } )

    var url = apiHost + "/api/admin/vendor/" + vendorId + "/site/balances";

    function getColumns( { history } ) {
        return  [
            {
                headerName: __( 'Site Id' ),
                field: 'siteId',
                headerAlign: 'left',
                align: 'left',
                flex: 1,
                renderCell: ( { row, formattedValue } ) => (
                    <Link to={ "/sites/id/" + row.siteId } >{ formattedValue }</Link>
                ),
                hide: true
        
            },
            {
                headerName: __( 'Site' ),
                field: 'siteName',
                flex: 2,
                renderCell: ( { row, formattedValue } ) => (
                    <Link to={ "/sites/id/" + row.siteId } >{ formattedValue }</Link>
                ),
            },
            {
                headerName: __( 'Site Reference' ),
                field: 'reference',
                flex: 2,
                renderCell: ( { row, formattedValue } ) => (
                    <Link to={ "/sites/id/" + row.siteId } >{ formattedValue }</Link>
                ),
                hide: true
            },
            {
                headerName: __( 'Vegetation(s)' ),
                field: 'vegetationTypes',
                flex: 2,
                type: "singleSelect",
                valueOptions: enumVegetationTypes
            },
            {
                headerName: __( 'No. of Zones' ),
                field: 'noOfZones',
                flex: 2,
                renderCell: ( { row, formattedValue } ) => {
                    if( formattedValue < 1 ) {
                        return formattedValue;
                    }
    
                    var url =  urlEncode( {
                        url: '/zones',
                        data: {
                            query: {
                                filters: {
                                    items: [
                                        {
                                            columnField: "siteId",
                                            operatorValue: 'EQUAL',
                                            value: row.siteId
                                        }
                                    ]
                                }
                            }
                        }
                    } );
    
                    return (
                        <Link to={ url } >{ formattedValue }</Link>
                    )
                }
            },
            {
                headerName: __( 'Units Sold' ),
                field: 'unitsSold',
                flex: 1,
            },
            {
                headerName: __( 'R. Units Allocated' ),
                field: 'resellerUnitsAllocated',
                flex: 1,
            },
            {
                headerName: __( 'R. Units Pending' ),
                field: 'resellerUnitsPending',
                flex: 1,
                hide: true
            },
            {
                headerName: __( 'R. Units Retired' ),
                field: 'resellerUnitsRetired',
                flex: 1,
                hide: true
            },
            {
                headerName: __( 'V. Units Allocated' ),
                field: 'vendorUnitsAllocated',
                flex: 1,
            },
            {
                headerName: __( 'V. Units Pending' ),
                field: 'vendorUnitsPending',
                flex: 1,
                hide: true
            },
            {
                headerName: __( 'V. Units Retired' ),
                field: 'vendorUnitsRetired',
                flex: 1,
                hide: true
            },
            {
                headerName: __( 'Total Units' ),
                field: 'totalUnits',
                flex: 1,
            }
        ];
    }

    return (
        <div className="table_list table__vendor-site-balances">
            <div style={{ height: 'calc( 100vh - 190px)', width: '100%' }}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1 }}>
                        <DataTable 
                            url={ url }
                            columns={ columns }
                            getRowId={ row => row.siteId }
                        />
                    </div>
                </div>
            </div>
        </div>
    )

}