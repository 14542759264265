import { Autocomplete, MenuItem, Select, TextField } from '@mui/material';
import React from 'react';
import { defaultFieldVariant, __ } from '../../app';
import { Box } from 'react-feather';
import { Country, State, City }  from 'country-state-city';


function getCountry ( countryCode ) {
    var countries = Country.getAllCountries();
    for( var i in countries ) {
        var country = countries[i];
        if( country.isoCode == countryCode || country.name.toLowerCase() == countryCode?.toLowerCase() ) {
            return country;
        }
    }

    return {
        name: '',
        isoCode: false
    };
}


function getState( stateCode, countryCode ) {
    var states = State.getStatesOfCountry( countryCode );
    for( var i in states ) {
        var state = states[i];
        if( state.isoCode == stateCode || state.name.toLowerCase() == stateCode?.toLowerCase() ) {
            return state;
        }
    }

    return {
        name: '',
        isoCode: false
    };
}
function getCity( cityName, countryCode, stateCode ) {
    var cities = City.getCitiesOfState( countryCode, stateCode );
    for( var i in cities ) {
        var city = cities[i];
        if( city.name.toLowerCase() == cityName?.toLowerCase() ) {
            return city;
        }
    }

    return {
        name: '',
        isoCode: false
    };
}

export default function AddressFields( { address, onChange, isAddressLoading = false, disabled = false } ) {
    var country = getCountry( address?.country );
    var state = {
        name: '',
        isoCode: false
    };
    var city = {
        name: '',
        isoCode: false
    };

    // Load all states
    var states = country?.isoCode ? State.getStatesOfCountry( country.isoCode ) : [];

    // Load selected state
    if( address?.state && country?.isoCode ) {
        state = getState( address.state, country.isoCode )
    }

    // Load all cities
    var cities = country?.isoCode && state?.isoCode ? City.getCitiesOfState( country.isoCode, state.isoCode ) : [];

    // Load selected city
    if( address?.city && country?.isoCode && state?.isoCode ) {
        city = getCity( address.city, country.isoCode, state.isoCode )
    }

    function onCountryChanged( country ) {
        onChange?.( {
            ...address,
            country
        } )
    }

    function onStateChanged( state ) {
        onChange?.( {
            ...address,
            state
        } )
    }

    function onCityChanged( event )  {
        onChange?.( {
            ... address,
            city: event.target.value
        } )
    }

    function onLine1Changed( event ) {
        onChange?.( {
            ...address,
            line1: event.target.value
        } )
    }

    function onLine2Changed( event ) {
        onChange?.( {
            ...address,
            line2: event.target.value
        } )
    }

    function onLine3Changed( event ) {
        onChange?.( {
            ...address,
            line3: event.target.value
        } )
    }

    function onPostcodeChange( event ) {
        onChange?.( {
            ... address,
            postCode: event.target.value
        } )
    }

    return (
        <div className="components__address-fields">
            {/* <Select
                className="w-100 mb-3"
                label={ __( 'Country' ) }
                variant={ defaultFieldVariant }
                disabled={ isAddressLoading }
                value={ address?.country || 'AU' }
                onChange={ e => onCountryChanged( e ) }
            >
                { iso31661.map( country => {
                    return <MenuItem value={ country.alpha2 }>{ country.name }</MenuItem>
                } ) }

            </Select> */}

            <Autocomplete
                value={ country }
                options={ Country.getAllCountries() }
                onChange={ (e, country) => onCountryChanged( country?.isoCode) }
                getOptionLabel={ country => country.name }
                renderInput={ (params) => <TextField 
                    { ...params } 
                    className="w-100 mb-3"
                    label={ __( 'Country' ) }
                    variant={ defaultFieldVariant }
                    disabled={ isAddressLoading || disabled }
                /> }
                disabled={ disabled }
            />

            <TextField
                className="w-100 mb-3" 
                label={ __( "Address Line 1" ) }
                variant={ defaultFieldVariant } 
                disabled={ isAddressLoading || disabled }
                value={ isAddressLoading ? __( 'Loading...' ) : ( address?.line1 || '' ) }
                onChange={ e => onLine1Changed( e ) }
            />

            <TextField
                className="w-100 mb-3" 
                label={ __( "Address Line 2" ) }
                variant={ defaultFieldVariant } 
                disabled={ isAddressLoading || disabled }
                value={ isAddressLoading ? __( 'Loading...' ) : ( address?.line2 || '' ) }
                onChange={ e => onLine2Changed( e ) }
            />

            <TextField
                className="w-100 mb-3" 
                label={ __( "Address Line 3" ) }
                variant={ defaultFieldVariant } 
                disabled={ isAddressLoading || disabled }
                value={ isAddressLoading ? __( 'Loading...' ) : ( address?.line3 || '' ) }
                onChange={ e => onLine3Changed( e ) }
            />

            <Autocomplete
                value={ state }
                options={ states }
                onChange={ (e, state) => onStateChanged( state?.isoCode) }
                getOptionLabel={ state => state.name }
                renderInput={ (params) => <TextField 
                    { ...params } 
                    className="w-100 mb-3"
                    label={ __( 'State' ) }
                    variant={ defaultFieldVariant }
                    disabled={ isAddressLoading || disabled }
                /> }
                disabled={ disabled }
                
            />

            <TextField
                className="w-100 mb-3" 
                label={ __( "City" ) }
                variant={ defaultFieldVariant } 
                disabled={ isAddressLoading || disabled }
                value={ isAddressLoading ? __( 'Loading...' ) : ( address?.city || '' ) }
                onChange={ e => onCityChanged( e ) }
            />


            <TextField
                className="w-100 mb-3" 
                label={ __( "Postcode" ) }
                variant={ defaultFieldVariant } 
                disabled={ isAddressLoading || disabled }
                value={ isAddressLoading ? __( 'Loading...' ) : ( address?.postCode || '' ) }
                onChange={ e => onPostcodeChange( e ) }
            />


        </div>
    )
}
