import { autocomplete } from "../../services/site-service";
import { __ } from "../../app"
import AjaxAutocomplete from './index'

export default function AutocompleteSite( {
    site,
    onSiteChanged,
    isSiteLoading,
    label = __("Site"),
    wrapperProps = {},
    disabled = false
} ) {


    // Assign component classname
    if( !wrapperProps?.className ) {
        wrapperProps.className = 'components__sites-autocomplete';
    } else {
        wrapperProps.className = 'components__sites-autocomplete ' + wrapperProps.className;
    }
 
    // Render
    return (
        <div { ...wrapperProps }>

            {/* Render primary component when loaded */}
           <AjaxAutocomplete
                label={ label }
                value={ site }
                onValueChanged={ site => onSiteChanged?.( { ...site } ) }
                isValueLoading={ isSiteLoading }
                allowEmptyTerm={ true }
                getAutocompleteResults={ 
                    async ( term, abortController ) => await autocomplete( term, { abortController } ) 
                }
                disabled={ disabled }
            />

        </div>
    )
}