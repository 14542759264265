import React, { Fragment, memo, useState } from "react"

import { __ } from "../../app"
import { apiHost } from "../../config"
import { Link, useHistory } from 'react-router-dom'
import { Box, TextField } from "@mui/material"
import { Card, CardBody } from 'reactstrap'
import { DateRangePicker } from "@mui/lab"

import DataTable from '../../components/datatable'
import PageHeader from "../../components/page-header";
import { enumCreditNames } from "../../enums"

const url = apiHost + "/api/admin/site/activity";

function getColumns( { history } ) {
    return [
        {
            headerName: __( 'Vendor Id' ),
            field: 'vendorId',
            flex: 1,
            hide: true,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/vendors/id/" + row.vendorId } >{ formattedValue }</Link>
            )
        },
        {
            headerName: __( 'Vendor Name' ),
            field: 'vendorName',
            flex: 2,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/vendors/id/" + row.vendorId } >{ formattedValue }</Link>
            )
        },
        {
            headerName: __( 'Site Id' ),
            field: 'siteId',
            flex: 1,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/sites/id/" + row.siteId } >{ formattedValue }</Link>
            )
        },
        {
            headerName: __( 'Site Name' ),
            field: 'siteName',
            flex: 2,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/sites/id/" + row.siteId } >{ formattedValue }</Link>
            )
        },
        {
            headerName: __( 'Site Owner Id' ),
            field: 'siteOwnerId',
            flex: 1,
            hide: true,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/site-owners/id/" + row.siteOwnerId } >{ formattedValue }</Link>
            )
        },
        {
            headerName: __( 'Site Owner' ),
            field: 'siteOwner',
            flex: 2,
            hide: true,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/site-owners/id/" + row.siteOwnerId } >{ formattedValue }</Link>
            )
        },
        {
            headerName: __( 'Credit Type Id' ),
            field: 'creditTypeId',
            flex: 1,
            hide: true
        },
        {
            headerName: __( 'Credit Name' ),
            field: 'creditName',
            flex: 2,
            type: "singleSelect",
            valueOptions: enumCreditNames
        },
        {
            headerName: __( 'Units Sold' ),
            field: 'unitsSold',
            flex: 2
        },
        {
            headerName: __( 'Credits Sold' ),
            field: 'creditsSold',
            flex: 2
        },

    ]
}

function getDateString( date ) {
    return date.getFullYear() 
        + '-' + ( '0' + ( date.getMonth() + 1 ) ).slice( -2 )
        + '-' + ( '0' + date.getDate() ).slice( -2 )
}

export default function SiteActivity() {
    const history = useHistory();
    const columns = getColumns( { history } )

    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
    

    const [ value, setValue ] = useState( [ firstDay, lastDay ] );
    const [ isValid, setIsValid ] = useState( true );

    const [ queryParams, setQueryParams ] = useState( {
        startDate: getDateString(firstDay),
        endDate: getDateString(lastDay)
    } )

    function isValueValid( startDate, endDate ) {
        if( startDate == null || endDate == null ) {
            return false;
        }

        if( startDate >= endDate ) {
            return false;
        }

        return true;
    }
    
    function onDateRangeChange( value ) {
        setValue( value )

        var startDate = value[0];
        var endDate = value[1];

        var isValid = isValueValid( startDate, endDate );
        setIsValid( isValid );
        
        if( isValid ) {
            setQueryParams( {
                startDate: getDateString( startDate ),
                endDate: getDateString( endDate )
            } )
        }

    }



    return (
        <div className="table_list table__site-activity" style={ { position: 'relative' } }>

            <PageHeader
                header={ __( 'Site Activity' ) }
                breadcrumbs={ [ { 
                    to: '/site-activity',
                    label: __( 'Site Activity' ),
                    active: true
                } ] }
                alerts={ !isValid ? [ 
                    {
                        severity: 'info',
                        title: __( 'Please enter a valid date range.' )
                    }
                ] : [] }
            />

            <div className="activity-date-picker">
                <div style={{
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'end'
                }}>
                    <DateRangePicker
                        startText={ __( 'Start' ) }
                        endText={ __( 'End' ) }
                        value={ value }
                        mode="controlled"
                        onChange={ value => onDateRangeChange( value ) }
                        renderInput={(startProps, endProps) => (
                            <Fragment>
                                <TextField {...startProps} />
                                <Box sx={{ mx: 2 }}> { __( 'to' ) } </Box>
                                <TextField {...endProps}  />
                            </Fragment>
                        )}
                    />
                </div>
            </div>
            
            <Card>
                <CardBody>

                
                    <div style={{ height: 'calc( 100vh - 190px)', width: '100%' }}>
                        <div style={{ display: 'flex', height: '100%' }}>
                            <div style={{ flexGrow: 1 }}>

                           

                                <DataTable 
                                    url={ url }
                                    columns={ columns }
                                    removeRowsOnLoad={ true }
                                    hideRows={ !isValid }
                                    getRowId={ row => [ row.vendorId, row.creditTypeId, row.siteId ].join( '-' )  }
                                    queryParams={ queryParams }
                                />
                            </div>
                        </div>
                    </div>


                </CardBody>
            </Card>

        </div>
    )
}