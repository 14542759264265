import { Button, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { defaultFieldVariant, __ } from '../../app'
import { useBiome } from '../../services/biome-service'
import { useUnitType } from '../../services/unit-type-service'
import { useSite } from '../../services/site-service'
import AutocompleteBiome from '../autocomplete/autocomplete-biome'
import AutocompleteSite from '../autocomplete/autocomplete-site'
import AutocompleteUnitType from '../autocomplete/autocomplete-unit-type'
import PolygonEditor from '../polygon-editor'
import { useSelector } from 'react-redux'

// export default ZoneEditor( params ) {
//     // Regen model when zone changes
//     return <_ZoneEditor
//         key={ ( params?.zoneModel?.id || 0 ) + zone.response ? "-loaded" : '' } 
//         { ...params }
//     />
// }

export default function ZoneEditor( { zoneModel, isZoneModelLoading, onZoneModelChanged = false, ActionsComponent } ) {

    // Autoload Site / Biome objects
    const [ site, setSite, isSiteLoading ] = useSite( zoneModel?.site?.id, { context: 'autocomplete' }  )
    const [ biome, setBiome, isBiomeLoading ] = useBiome( zoneModel?.biome?.id, { context: 'autocomplete' } )
    const [ unitType, setUnitType, isUnitTypeLoading ] = useUnitType( zoneModel?.unitType?.id, { context: 'autocomplete' } )
    
    const role = useSelector(state => state.auth.user?.profile?.role)
    const isEditsDisabled = role != 'AdminUser';

    function onReferenceChanged( event ) {
        onZoneModelChanged?.( {
            ...zoneModel,
            reference: event.target.value
        } )
    }

    function onCreditMultiplierChanged( event ) {
        onZoneModelChanged?.( {
            ...zoneModel,
            creditMultiplier: event.target.value
        } )
    }

    function onPolygonSourceChanged( source ) {
        onZoneModelChanged?.( {
            ...zoneModel,
            polygon: {
                ...zoneModel.polygon,
                source: {
                    ... source
                }
            }
        } )
    }

    function onSiteChanged( site ) {
        onZoneModelChanged?.( {
            ...zoneModel,
            site: {
                id: site.siteId
            }
        } )
        setSite( site );
    }

    function onBiomeChanged( biome ) {
        onZoneModelChanged?.( {
            ...zoneModel,
            biome: {
                id: biome.biomeId
            }
        } )
        setBiome( biome );
    }

    function onUnitTypeChanged( unitType ) {
        onZoneModelChanged?.( {
            ...zoneModel,
            unitType: {
                id: unitType.unitTypeId
            }
        } )
        setUnitType( unitType );
    }
    
    return (
        <div className="components__zone-editor">
            <Row>
                <Col md="6">
                    <h4 className='mb-3'>{__( "Zone Details" )}</h4>

                    
                    { zoneModel?.id > 0 &&
                        <TextField
                            className="w-100 mb-3" 
                            label={ __( "Zone Id" ) }
                            variant={ defaultFieldVariant } 
                            disabled={ true }
                            value={ zoneModel.id }
                            onChange={ e => false  }
                        />
                    }

                    <TextField
                        className="w-100 mb-3" 
                        label={ __( "Zone Reference" ) }
                        variant={ defaultFieldVariant } 
                        disabled={ isEditsDisabled || isZoneModelLoading || zoneModel?.status == 'ACTIVE' || zoneModel?.status == 'RETIRED' }
                        value={ isZoneModelLoading ? __( 'Loading...' ) : ( zoneModel?.reference || '' ) }
                        onChange={ e => onReferenceChanged( e ) }
                    />

                    <AutocompleteSite 
                        site={ site }
                        label={ __( 'Site' ) }
                        disabled={ isEditsDisabled || isZoneModelLoading || zoneModel?.status == 'ACTIVE' || zoneModel?.status == 'RETIRED' }
                        onSiteChanged={ site => onSiteChanged( site ) }
                        isSiteLoading={ isSiteLoading }
                        wrapperProps={{
                            className: "mb-3"
                        }}
                    />

                    <AutocompleteUnitType 
                        unitType={ unitType }
                        label={ __( 'Unit Type' ) }
                        disabled={ isEditsDisabled || isZoneModelLoading || zoneModel?.status == 'ACTIVE' || zoneModel?.status == 'RETIRED' }
                        onUnitTypeChanged={ unitType => onUnitTypeChanged( unitType ) }
                        isUnitTypeLoading={ isUnitTypeLoading }
                        wrapperProps={{
                            className: "mb-3"
                        }}
                    />

                    <AutocompleteBiome 
                        biome={ biome }
                        disabled={ isEditsDisabled || isZoneModelLoading || zoneModel?.status == 'ACTIVE' || zoneModel?.status == 'RETIRED' }
                        onBiomeChanged={ biome => onBiomeChanged( biome ) }
                        isBiomeLoading={ isBiomeLoading }
                        wrapperProps={{
                            className: "mb-3"
                        }}
                    />

                    <TextField 
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*\.[0-9]*' }} 
                        type="number"
                        className="w-100 mb-3" 
                        label={ __( "Units per Credit" ) }
                        variant={ defaultFieldVariant } 
                        disabled={ isEditsDisabled || isZoneModelLoading || zoneModel?.status == 'ACTIVE' || zoneModel?.status == 'RETIRED' }
                        value={ isZoneModelLoading ? __( 'Loading....' ) : ( zoneModel?.creditMultiplier || '' ) }
                        onChange={ e => onCreditMultiplierChanged( e ) }
                    />

                    { !isEditsDisabled && ActionsComponent && <ActionsComponent /> }
                </Col>

                <Col md="6">    
                    <h4 className='mb-3'>{__( "Zone Polygon" )}</h4>
                    { !isZoneModelLoading && (
                        <PolygonEditor 
                            polygon={ zoneModel?.polygon?.source  }
                            onPolygonChange={ source => onPolygonSourceChanged( source ) }
                            disabled={ isEditsDisabled || isZoneModelLoading || zoneModel?.status == 'ACTIVE' || zoneModel?.status == 'RETIRED' }
                        />
                    ) }
                    
                </Col>
            </Row>
        </div>
    )
}