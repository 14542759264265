import React from "react"
import { Container, Card, CardBody, CardTitle } from "reactstrap"
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

import { __ } from "../../app"
import { apiHost, authHost } from "../../config"
import DataTable from '../../components/datatable'
import { Link, useHistory } from "react-router-dom";
import { Breadcrumbs } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import { urlEncode } from "../../helpers/url-encoding";
import { enumOrderStatus, enumVegetationTypes, enumZoneStatus } from "../../enums";


export default function VendorBalancesDatatable( { vendorId } ) {

    const history = useHistory()
    const columns = getColumns( { history } )

    var url = apiHost + "/api/admin/vendor/" + vendorId + "/balances";

    function getColumns( { history } ) {
        return  [
            {
                headerName: __( 'Zone Id' ),
                field: 'zoneId',
                headerAlign: 'left',
                align: 'left',
                flex: 1,
                renderCell: ( { row, formattedValue } ) => (
                    <Link to={ "/zones/id/" + row.zoneId } >{ formattedValue }</Link>
                ),
                hide: true
        
            },
            {
                headerName: __( 'Zone' ),
                field: 'reference',
                flex: 2,
                renderCell: ( { row, formattedValue } ) => (
                    <Link to={ "/zones/id/" + row.zoneId } >{ formattedValue }</Link>
                ),
            },
            {
                headerName: __( 'Vegetation' ),
                field: 'vegetationType',
                flex: 2,
                type: "singleSelect",
                valueOptions: enumVegetationTypes
            },
            {
                headerName: __( 'Units Sold' ),
                field: 'unitsSold',
                flex: 1,
            },
            {
                headerName: __( 'R. Units Allocated' ),
                field: 'resellerUnitsAllocated',
                flex: 1,
            },
            {
                headerName: __( 'R. Units Pending' ),
                field: 'resellerUnitsPending',
                flex: 1,
                hide: true
            },
            {
                headerName: __( 'V. Units Allocated' ),
                field: 'vendorUnitsAllocated',
                flex: 1,
            },
            {
                headerName: __( 'V. Units Pending' ),
                field: 'vendorUnitsPending',
                flex: 1,
                hide: true
            },
            {
                headerName: __( 'Total Units' ),
                field: 'totalUnits',
                flex: 1,
            },
            {
                headerName: __( 'Status' ),
                field: 'status',
                flex: 2,
                type: "singleSelect",
                valueOptions: enumZoneStatus
            }
        ];
    }

    return (
        <div className="table_list table__vendors-balances">
            <div style={{ height: 'calc( 100vh - 190px)', width: '100%' }}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1 }}>
                        <DataTable 
                            url={ url }
                            columns={ columns }
                            getRowId={ row => row.zoneId }
                        />
                    </div>
                </div>
            </div>
        </div>
    )

}