import axios from 'axios';
import { __ } from "../app"
import { apiHost, authHost } from "../config"
import {  fetchResultsByTerm, useFetchResultByUrl } from './helpers/autocomplete-service';

export async function autocomplete( term, { abortController = AbortController } ) {
    var url = apiHost + '/api/admin/order/autocomplete';
    return await fetchResultsByTerm( url, term, { abortController } )
}

export function useOrder( orderId = false, { context = 'autocomplete' } = {} ) {

    var fetchParams = {
        getId: r => r?.id,
        getUrl: r => apiHost + '/api/admin/order/' + parseInt( r?.id ),
        initialState: {
            id: orderId
        }
    } 

    if( context == 'autocomplete' ) {
        fetchParams = {
            getId: r => r?.orderId,
            getUrl: r => apiHost + '/api/admin/order/autocomplete/' + parseInt( r?.ownerId ),
            initialState: {
                ownerId: orderId
            }
        }
    }

    return useFetchResultByUrl( fetchParams );
}

export async function createOrder( orderModel, autoProcess = false ) {

    // 
    orderModel.unitTransfers = orderModel?.unitTransfers.map( transfer => {
        return {
            ...transfer,
            senderId: transfer.senderId || 0
        }
    } )
    
    try {
        const response = await axios.post( apiHost + '/api/admin/order', orderModel );
        return response;
    }
    catch( e ) {
        return {
            error: true,
            message: e?.response?.data
        }
    }
}


export async function processOrder( orderModel ) {

    try {
        const response = await axios.put( apiHost + '/api/admin/order/process/' + orderModel?.id )
        return response;
    }
    catch( e ) {
        return {
            error: true,
            message: e?.response?.data
        }
    }

}

export async function refundOrder( orderModel ) {

    try {
        const response = await axios.put( apiHost + '/api/admin/order/refund/' + orderModel?.id )
        return response;
    }
    catch( e ) {
        return {
            error: true,
            message: e?.response?.data
        }
    }

}

export async function cancelOrder( orderModel ) {

    try {
        const response = await axios.put( apiHost + '/api/admin/order/cancel/' + orderModel?.id )
        return response;
    }
    catch( e ) {
        return {
            error: true,
            message: e?.response?.data
        }
    }

}