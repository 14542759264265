import React from "react"
import { Card, CardBody } from "reactstrap"
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

import { __ } from "../../app"
import { apiHost } from "../../config"
import DataTable from '../../components/datatable'
import { Link, useHistory } from "react-router-dom";
import PageHeader from "../../components/page-header";


var url = apiHost + "/api/admin/Reseller/datatables";
function getColumns( { history } ) {
    return [
        {
            headerName: __( 'Reseller Id' ),
            field: 'resellerId',
            headerAlign: 'left',
            align: 'left',
            flex: 1,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/resellers/id/" + row.resellerId } >{ formattedValue }</Link>
            ),
            hide: true
    
        },
        {
            headerName: __( 'Account Id' ),
            field: 'accountId',
            flex: 1,
            hide: true
        },
        {
            headerName: __( 'Reseller' ),
            field: 'companyName',
            flex: 2,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/resellers/id/" + row.resellerId } >{ formattedValue }</Link>
            ),
        },
        {
            headerName: __( 'Contact' ),
            field: 'contactName',
            flex: 2,
            hide: true
        },
        {
            headerName: __( 'Email' ),
            field: 'email',
            flex: 2,
            renderCell: ( { row, formattedValue } ) => (
                <a href={ "mailto:" + row.email } >{ formattedValue }</a>
            ),
        },
        {
            headerName: __( 'Phone' ),
            field: 'phone',
            flex: 1,
            renderCell: ( { row, formattedValue } ) => (
                <a href={ "tel:" + row.phone } >{ formattedValue }</a>
            ),
            hide: true
        },
        {
            headerName: __( 'Street' ),
            field: 'street',
            flex: 1,
            hide: true
        },
        {
            headerName: __( 'City' ),
            field: 'city',
            flex: 1,
            hide: true
        },
        {
            headerName: __( 'State' ),
            field: 'state',
            flex: 1,
            hide: true
        },
        {
            headerName: __( 'Postcode' ),
            field: 'postCode',
            flex: 1,
            hide: true
        },
        {
            headerName: __( 'Country' ),
            field: 'country',
            flex: 1,
            hide: true
        },
        {
            headerName: __( 'Vendor' ),
            field: 'vendor',
            flex: 2,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/vendors/id/" + row.vendorId } >{ formattedValue }</Link>
            )
        },
        {
            headerName: __( 'Units Sold' ),
            field: 'unitsSold',
            type: "number",
            align: 'left',
            headerAlign: 'left',
            flex: 1,
        },
        {
            headerName: __( 'Units Retired' ),
            field: 'unitsRetired',
            align: 'left',
            headerAlign: 'left',
            flex: 1,
            hide: true
        },
        {
            headerName: __( 'Units Allocated' ),
            field: 'unitsAllocated',
            type: "number",
            align: 'left',
            headerAlign: 'left',
            flex: 1
        },
        {
            headerName: __( 'Total Units' ),
            field: 'totalUnits',
            type: "number",
            align: 'left',
            headerAlign: 'left',
            flex: 1
        },
        {
            field: 'actions',
            headerName: __( 'Actions' ),
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem 
                    onClick={ () => history.push( '/resellers/id/' + params.id )  } 
                    icon={<EditIcon />} 
                    label={ __("View / Edit Reseller") } 
                />,
            ]
        }
    ];
}

export default function ListResellers() {
    const history = useHistory();
    const columns = getColumns( { history } )
    
    return (
        <div className="table_list table__resellers">

        
            <PageHeader
                header={ __( 'Resellers' ) }
                breadcrumbs={ [ 
                    { 
                        to: '/resellers',
                        label: __( 'Resellers' ),
                        active: true
                    }
                ] }
            />
            
            
            <Card>
                <CardBody>
                    <div style={{ height: 'calc( 100vh - 190px)', width: '100%' }}>
                        <div style={{ display: 'flex', height: '100%' }}>
                            <div style={{ flexGrow: 1 }}>
                                <DataTable 
                                    url={ url }
                                    columns={ columns }
                                    getRowId={ row => row.resellerId }
                                />
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>

        </div>
    )
}