// React stuff
import React, { Component, Fragment, useEffect } from 'react'
import { Slot, Fill, Provider as FillProvider } from 'react-slot-fill'
import { BrowserRouter as Router, Route, Switch, useHistory, useLocation } from 'react-router-dom'

import './app.scss'

// Dashboard Layout
import { Dashboard as TheDashboard,  SidebarItems, SidebarNav, SidebarItem } from './components/dashboard'

// Icons
import * as Icon from 'react-feather'

// Data
import store from './store'
import { Provider as ReduxProvider, useSelector } from 'react-redux';
import userManager, { loadUserFromStorage, signoutRedirect } from './services/user-service'

import AuthProvider from './utils/authProvider'
import PrivateRoute, { ProtectedRedirect } from './utils/protectedRoute'

// User AUTH Pages
import Login from './auth/login'
import SigninOidc from './auth/signin-oidc'
import SignoutOidc  from './auth/signout-oidc'

// Already Authorized Pages
import Dashboard        from './pages/dashboard/index'
import AddZone          from './pages/zones/page-add-zone'

import ListCustomers    from './pages/customers/page-list-customers'
import ListResellers    from './pages/resellers/page-list-resellers'
import ListSiteOwners   from './pages/site-owners/page-list-site-owners'
import ListSites        from './pages/sites/page-list-sites'
import ListTransfers    from './pages/orders/page-list-transfers'
import ListUsers        from './pages/users/page-list-users'
import ListVendors      from './pages/vendors/page-list-vendors'
import ListZones        from './pages/zones/page-list-zones'
import AddOrder         from './pages/orders/page-add-order'

// MUI Lab stuff
import { enAU } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers";

import { LicenseInfo } from '@mui/x-data-grid-pro';
import EditZone from './pages/zones/page-edit-zone'
import EditSite from './pages/sites/page-edit-site'
import AddSite from './pages/sites/page-add-site'
import EditSiteOwner from './pages/site-owners/page-edit-site-owner'
import EditVendor from './pages/vendors/page-edit-vendor'
import AddVendor from './pages/vendors/page-add-vendor'
import AddSiteOwner from './pages/site-owners/page-add-site-owner'
import AddReseller from './pages/resellers/page-add-reseller'
import AddUser from './pages/users/page-add-user'
import EditReseller from './pages/resellers/page-edit-reseller'
import EditOrder from './pages/orders/page-edit-order'
import EditUser from './pages/users/page-edit-user'
import SiteActivity from './pages/sites/page-list-site-activity'

LicenseInfo.setLicenseKey(
  '9954ec2cd61221c228d3aba522e528ffT1JERVI6MzMzNzcsRVhQSVJZPTE2Njk1OTg3MDgwMDAsS0VZVkVSU0lPTj0x',
);

export const defaultFieldVariant = 'outlined';

// I18n
export function __( text ) {
    return text; // todo
}

// Store app entry location before it's modified by react router,
// we'll redirect here after authentication to make sure the user
// ends up where they wanna be.
export const appEntryLocation = { ...window.location };

// For demonstration purposes, called by every service component to add an artificial load if enabled
const simulateLoading = false;
export function maybeSimulateLoading( timeout = 2000) {
    if( !simulateLoading ) return;
    return new Promise(resolve => setTimeout(resolve, timeout));
}

export const RolesReadonlyAccess = [ 'AdminUser', 'ReadOnlyUser' ]
export const RolesAdminAccess = [ 'AdminUser' ]

export default class App extends Component {

    constructor() {
        super()
    }

    /**
     * Auto-Builds Sidebar & Routes
     */
    sidebar = [
        {
            header: __( 'Zones' ),
            items: [
                {
                    name: 'zones',
                    url: '/zones',
                    label: __( 'Zones' ),
                    Icon: Icon.List,
                    Component: ListZones,
                    roles: RolesReadonlyAccess
                },
                {
                    name: 'add-zone',
                    url: '/zones/add',
                    label: __( 'Add Zone' ),
                    Icon: Icon.Plus,
                    Component: AddZone,
                    roles: RolesAdminAccess
                }
            ]
        },
        {
            header: __( 'Sites' ),
            items: [
                {
                    name: 'sites',
                    url: '/sites',
                    label: __( 'Sites' ),
                    Icon: Icon.List,
                    Component: ListSites,
                    roles: RolesReadonlyAccess
                },
                {
                    name: 'site-activity',
                    url: '/site-activity',
                    label: __( 'Site Activity' ),
                    Icon: Icon.List,
                    Component: SiteActivity,
                    roles: RolesReadonlyAccess
                },
                {
                    name: 'add-site',
                    url: '/sites/add',
                    label: __( 'Add Site' ),
                    Icon: Icon.Plus,
                    Component: AddSite,
                    roles: RolesAdminAccess
                }
            ]
        },
        {
            header: __( 'Site Owners' ),
            items: [
                {
                    name: 'site-owners',
                    url: '/site-owners',
                    label: __( 'Site Owners' ),
                    Icon: Icon.List,
                    Component: ListSiteOwners,
                    roles: RolesReadonlyAccess
                },
                {
                    name: 'add-owner',
                    url: '/site-owners/add',
                    label: __( 'Add Owner' ),
                    Icon: Icon.Plus,
                    Component: AddSiteOwner,
                    roles: RolesAdminAccess
                }
            ]
        } ,
        {
            header: __( 'Vendors' ),
            items: [
                {
                    name: 'vendors',
                    url: '/vendors',
                    label: __( 'Vendors' ),
                    Icon: Icon.List,
                    Component: ListVendors,
                    roles: RolesReadonlyAccess
                },
                {
                    name: 'add-vendor',
                    url: '/vendors/add',
                    label: __( 'Add Vendor' ),
                    Icon: Icon.Plus,
                    Component: AddVendor,
                    roles: RolesAdminAccess
                }
            ]
        },
        {
            header: __( 'Resellers' ),
            items: [
                {
                    name: 'resellers',
                    url: '/resellers',
                    label: __( 'Resellers' ),
                    Icon: Icon.List,
                    Component: ListResellers,
                    roles: RolesReadonlyAccess
                },
                {
                    name: 'add-reseller',
                    url: '/resellers/add',
                    label: __( 'Add Reseller' ),
                    Icon: Icon.Plus,
                    Component: AddReseller,
                    roles: RolesAdminAccess
                }
            ]
        },
        {
            header: __( 'Customers' ),
            items: [
                {
                    name: 'customers',
                    url: '/customers',
                    label: __( 'Customers' ),
                    Icon: Icon.List,
                    Component: ListCustomers,
                    roles: RolesReadonlyAccess
                }
            ]
        },
        {
            header: __( 'Orders' ),
            items: [
                // {
                //     name: 'orders',
                //     url: '/orders',
                //     label: __( 'Orders' ),
                //     Icon: Icon.List,
                //     Component: ListOrders
                // },
                {
                    name: 'order-transfers',
                    url: '/order-transfers',
                    label: __( 'Transfers' ),
                    Icon: Icon.List,
                    Component: ListTransfers,
                    roles: RolesReadonlyAccess
                },
                {
                    name: 'add-order',
                    url: '/orders/add',
                    label: __( 'Add Order' ),
                    Icon: Icon.Plus,
                    Component: AddOrder,
                    roles: RolesAdminAccess
                }
            ]
        },
        {
            header: __( 'Administration' ),
            roles: RolesAdminAccess,
            items: [
                {
                    name: 'user-management',
                    url: '/user-management',
                    label: __( 'User Management' ),
                    Icon: Icon.Users,
                    Component: ListUsers,
                    roles: RolesAdminAccess
                },
                {
                    name: 'add-order',
                    url: '/user-management/add-user',
                    label: __( 'Add User' ),
                    Icon: Icon.Plus,
                    Component: AddUser,
                    roles: RolesAdminAccess
                }
            ]
        }
    ];



    Dashboardify = ( { children } ) => {
        var Component = ( { children} ) => {
            // If user isn't signed in, display component directly
            const user = useSelector(state => state.auth.user);
            if( !user ) {
                return <Fragment>{ children }</Fragment>
            }

            // User is signed in, build the dashboard
            return (
                <TheDashboard
                    userData={ store.getState() }
                >
                    {/* Render the now, "dashboard wrapped" component */}
                    { children }
                </TheDashboard>
            );
        }
        return <Component>{children}</Component>
    }

    TheSidebar( { sidebar } ) {
        var Component = () => {
            const location = useLocation()
            const history = useHistory()
            const role = useSelector(state => state.auth.user?.profile?.role)

            
            /* This is a slot-fill, sidebar items will be rendered in the sidebar of <TheDashboard /> */
            return (
                <SidebarItems key="sidebar-items">
                    {/* Construct sidebar from sidebar config */}
                    { sidebar.map( nav => { 

                        if( nav?.roles?.length > 0 && !nav.roles.includes( role ) ) {
                            return false;
                        }

                        return (
                            <SidebarNav header={ nav.header } key={ "nav-" + nav.header } >
                                { nav.items.map( item => (
                                    <SidebarItem
                                        key={ item.name }
                                        selected={ item.path ?? item.url == location.pathname }
                                        icon={ item.Icon }
                                        label={ item.label }
                                        href={ item.url }
                                        requiredRoles={ item.roles }
                                        onClick={ () => history.push( item.url ) }
                                    />
                                ) ) }
                            </SidebarNav>
                        ) 
                    } ) } 
                </SidebarItems>
            )
        }
        return <Component />
    }

    TheSidebarRoutes() {

        return (
            <Fragment>

                <PrivateRoute
                    key={ 'edit-zone' }
                    path={ '/zones/id/:id' }
                    Component={ EditZone }
                    requiredRoles={ RolesReadonlyAccess }
                />

                <PrivateRoute
                    key={ 'edit-site' }
                    path={ '/sites/id/:id' }
                    Component={ EditSite }
                    requiredRoles={ RolesReadonlyAccess }
                />

                <PrivateRoute
                    key={ 'edit-order' }
                    path={ '/orders/id/:id' }
                    Component={ EditOrder }
                    requiredRoles={ RolesReadonlyAccess }
                />

                <PrivateRoute
                    key={ 'edit-site-owner' }
                    path={ '/site-owners/id/:id' }
                    Component={ EditSiteOwner }
                    requiredRoles={ RolesReadonlyAccess }
                />

                <PrivateRoute
                    key={ 'edit-vendor' }
                    path={ '/vendors/id/:id' }
                    Component={ EditVendor }
                    requiredRoles={ RolesReadonlyAccess }
                />

                <PrivateRoute
                    key={ 'edit-reseller' }
                    path={ '/resellers/id/:id' }
                    Component={ EditReseller }
                    requiredRoles={ RolesReadonlyAccess }
                />

                <PrivateRoute
                    key={ 'edit-user' }
                    path={ '/user-management/edit-user/:id' }
                    Component={ EditUser }
                    requiredRoles={ RolesReadonlyAccess }
                />

                {/* Auto-Generate routes from Dashboard config */}
                { this.sidebar.map( nav => (
                    <Fragment key={ nav.header }>

                        { nav.items.map( item => (
                            <PrivateRoute exact
                                key={ item.name }
                                path={ item.path ?? item.url }
                                Component={ item.Component }
                                requiredRoles={ item.roles || RolesReadonlyAccess }
                            />
                        ) ) }

                    </Fragment>
                ) ) }

                <Route exact path="/" render={() => (<ProtectedRedirect to="/zones" />)} />  
                <Route exact path="/index.html" render={() => (<ProtectedRedirect to="/zones" />)} />  

            </Fragment>
        )
    }

    componentDidMount() {
        // fetch current user from cookies
        loadUserFromStorage(store)
    }

    render() {
        var { Dashboardify, TheSidebar, TheSidebarRoutes, appEntryLocation } = this
        TheSidebarRoutes = TheSidebarRoutes.bind( this );

        return (
            <FillProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ enAU }>
                    <ReduxProvider store={store}>

                        <AuthProvider userManager={userManager} store={store}>
                            <Router>

                                <Dashboardify>
                                    <Slot name="dashboard.content" />
                                </Dashboardify>

                                {/* Sidebar needs to be inside <Router/> because it uses routing information to build the <SidebarItem>'s */}
                                <TheSidebar sidebar={ this.sidebar } />

                                <Switch>

                                    {/* Authentication routes */}
                                    <Route path="/login">
                                        <Login />
                                    </Route>
                                    <Route path="/signout-oidc" component={SignoutOidc} />
                                    <Route path="/signin-oidc" component={SigninOidc} />

                                    {/*  */}
                                    <TheSidebarRoutes />

                                </Switch>
                            </Router>
                        </AuthProvider>
                    </ReduxProvider>
                </LocalizationProvider>
            </FillProvider>
        )
    }


}