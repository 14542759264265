import { autocomplete } from "../../services/role-service";
import { __ } from "../../app"
import AjaxAutocomplete from './index'

export default function AutocompleteRole( {
    role,
    onRoleChanged,
    isRoleLoading,
    label = __("Role"),
    wrapperProps = {}
} ) {


    // Assign component classname
    if( !wrapperProps?.className ) {
        wrapperProps.className = 'components__role-autocomplete';
    } else {
        wrapperProps.className = 'components__role-autocomplete ' + wrapperProps.className;
    }
 
    // Render
    return (
        <div { ...wrapperProps }>

            {/* Render primary component when loaded */}
           <AjaxAutocomplete
                label={ label }
                value={ role }
                onValueChanged={ role => onRoleChanged?.( { ...role } ) }
                isValueLoading={ isRoleLoading }
                allowEmptyTerm={ true }
                getAutocompleteResults={ 
                    async ( term, abortController ) => await autocomplete( term, { abortController } ) 
                }
            />

        </div>
    )
}