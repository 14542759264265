import React from 'react'
import { signinRedirect } from '../services/user-service'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

function Login() {
    const user = useSelector(state => state.auth.user)
    if (user) {
        return <Redirect to='/' />
    }

    // Redirect to Identity Server
    signinRedirect();
    return false;

}

export default Login
