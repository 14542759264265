import React, { Fragment, useEffect, useState } from "react"

import { Card, CardBody } from "reactstrap"
import { __ } from "../../app"
import { createSite, useSite } from "../../services/site-service"
import { useHistory } from 'react-router-dom'
import { Button, CircularProgress } from "@mui/material";

import SiteEditor from "../../components/site-editor"
import PageHeader from "../../components/page-header"

export default function AddSite( {
    
} ) {

    const [ siteModel, setSiteModel, isSiteModelLoading ] = useSite( false, { context: 'object' } )
    const [ isLoading, setLoading ] = useState( false );
    const [ errors, setErrors ] = useState( [] );
    const history = useHistory();


    useEffect( () => {
        console.log( 'Model Updated', siteModel )
    }, [ siteModel ] )

    async function onSaveSiteClicked() {
        if( isLoading ) return;
        setLoading( true );
        setErrors( [] )

        var errors = validateSite();
        if( errors.length ) {
            setErrors( errors )
            setLoading( false )
            return;
        }

        var response = await createSite( siteModel );
        setLoading( false )

        if( response?.error && response?.message ) {
            setErrors( [ response.message ] );
            return;
        }

        if( !response?.data || !response?.data?.id ) {
            setErrors( [ 'An unknown error occured, check console for more details.' ] );
            return;
        }

        var siteId = response.data.id;
        history.push( '/sites/id/' + siteId, { created: true });
    }

    function validateSite() {
        var errors = [];

        if( !siteModel?.reference ) {
            errors.push( 'Site reference cannot be empty.' )
        }

        if( !siteModel?.siteName ) {
            errors.push( 'Site name cannot be empty.' )
        }

        if( !siteModel?.siteOwner?.id ) {
            errors.push( 'Site owner cannot be empty.' );
        }
        
        if( !siteModel?.address?.city ) {
            errors.push( "City cannot be empty." )
        }

        if( !siteModel?.address?.country ) {
            errors.push( "Country cannot be empty." )
        }

        if( !siteModel?.address?.line1 ) {
            errors.push( "Address line 1 cannot be empty." )
        }

        if( !siteModel?.address?.postCode ) {
            errors.push( "Postcode cannot be empty." )
        }

        if( !siteModel?.address?.state ) {
            errors.push( "State cannot be empty." )
        }
        
        return errors;
    }
    

    function ActionsComponent() {
        return (
            <Fragment>
                <Button 
                    variant="contained"
                    onClick={ () => onSaveSiteClicked() }
                >
                    { __( 'Save' ) }
                </Button>
                        
                { isLoading && (
                    <CircularProgress />
                ) }
            </Fragment>
        )
    }

    return (
        <div className="views_view views__add-site">

            <PageHeader
                header={ __( 'Add Site' ) }
                breadcrumbs={ [ 
                    { 
                        to: '/sites',
                        label: __( 'Sites' )
                    } ,
                    { 
                        to: '/sites/add',
                        label: __( 'Add Site' ),
                        active: true
                    } 
                ] }
                alerts={ errors?.length > 0 ? [ 
                    {
                        severity: 'error',
                        title: __( 'Error(s) while creating site.' ),
                        withList: errors
                    }
                ] : [] }
            />

            <Card>
                <CardBody>
            
                    <SiteEditor 
                        siteModel={ siteModel }
                        isSiteModelLoading={ isSiteModelLoading }
                        onSiteModelChanged={ siteModel => {
                            setSiteModel( siteModel ) 
                        } }
                        ActionsComponent={ ActionsComponent }
                    />
                </CardBody>
            </Card>
        </div>
    )
}