
import Autocomplete from './index'
import { defaultFieldVariant, __ } from "../../app"
import { autocompleteAccount, useAccount } from "../../services/account-service"
import { Select, MenuItem, CircularProgress, FormControl, InputLabel } from '@mui/material'
import { Row, Col } from 'reactstrap';
import newId from '../../utils/newId';
import { useState, Fragment } from 'react';

export function AutocompleteAccount( {
    account,
    onAccountChanged,
    isAccountLoading,
    includedTypes = [ 'CUSTOMER', 'RESELLER', 'VENDOR' ],
    childrenOfParentId = null,
    label = __("Account"),
    wrapperProps = {},
    disabled,
    readonly = false
} ) {


    // Assign component classname
    if( !wrapperProps?.className ) {
        wrapperProps.className = 'components__autocomplete-account';
    } else {
        wrapperProps.className = 'components__autocomplete-account ' + wrapperProps.className;
    }
 
    // Render
    return (
        <div { ...wrapperProps }>

            {/* Render primary component when loaded */}
           <Autocomplete
                label={ label }
                value={ account }
                onValueChanged={ account => onAccountChanged?.( { ...account } ) }
                isValueLoading={ isAccountLoading }
                allowEmptyTerm={ true }
                getAutocompleteResults={ 
                    async ( term, abortController ) => await autocompleteAccount( term, { 
                        abortController,
                        includedTypes,
                        parentAccountId: childrenOfParentId
                    } ) 
                }
                disabled={disabled}
                readonly={readonly}
            />

        </div>
    )
}

export function AutocompleteAccountWithTypeDropdown( {
    account,
    onAccountChanged,
    isAccountLoading,
    accountTypes,
    AutocompleteComponent = AutocompleteAccount,
    label = __( 'Select account' ),
    wrapperProps = {},
    readonly = false
} ) {

    const [ labelId ] = useState( newId( 'select-account-label-' ) );
    const [ inputId ] = useState( newId( 'select-account-input-' ) );

    // Assign component classname
    if( !wrapperProps?.className ) {
        wrapperProps.className = 'components__autocomplete-account-with-type d-flex py-2';
    } else {
        wrapperProps.className = 'components__autocomplete-account-with-type d-flex py-2' + wrapperProps.className;
    }

    function onAccountTypeChanged( accountType ) {
        onAccountChanged({
            accountType: accountType,
            accountId: accountType == 'REGISTRY' ? 0 : false
        })
    } 

    // TODO: Fix label / form control structure
    return (
        <div { ...wrapperProps } >
            <FormControl 
                style={{
                    width: '50%',
                    marginRight: 10
                }}
                fullWidth
            >
                <InputLabel id={ labelId }>{ label }</InputLabel>
                { isAccountLoading || !accountTypes?.length ? (
                    <Select 
                        id={ inputId }
                        labelId={ labelId }
                        label={ label }
                        key="account-type-loading" 
                        value="loading"
                        variant={ defaultFieldVariant } 
                        disabled
                    >
                        <MenuItem value="loading" >
                            <div className="d-flex">
                                <CircularProgress size={20} /> 
                                <div style={{ marginLeft: 15, color: 'rgba(0, 0, 0, 0.6)' }}>{ __('Loading') }</div>
                            </div>
                        </MenuItem>
                    </Select>
                ) : (
                    <Select
                        id={ inputId }
                        labelId={ labelId }
                        label={ label }
                        key="account-type"
                        value={ account?.accountType || false }
                        variant={ defaultFieldVariant } 
                        onChange={ event => onAccountTypeChanged( event.target.value ) }
                        readOnly={ readonly }
                    >
                        { accountTypes.map( opt => <MenuItem value={opt} key={opt}>{opt}</MenuItem> )}
                    </Select>
                ) }
            </FormControl>

            <div 
                style={{
                    width: '50%'
                }}
            >
                <Fragment>
                    { account?.accountType && account.accountType != 'REGISTRY' && (
                        <AutocompleteComponent label={ __( 'Account' ) } />
                    ) }
                </Fragment>
            </div>
        </div>
    )

}


export function AutocompleteReceiverAccount( {
    account,
    onAccountChanged,
    isAccountLoading,
    label = __( 'To account' ),
    accountTypes = [ 'CUSTOMER', 'RESELLER', 'VENDOR' ],
    readonly = false,
} ) {

    const AutocompleteComponent = () => {
        return (
            <AutocompleteAccount
                account={ account }
                onAccountChanged={ account => onAccountChanged( { ... account } )  }
                isAccountLoading={ isAccountLoading }
                includedTypes={ [ account?.accountType ] }
                readonly={ readonly }
            />
        )
    }


    return (
        <AutocompleteAccountWithTypeDropdown
            account={ account }
            onAccountChanged={ account => onAccountChanged( { ... account } )  }
            isAccountLoading={ isAccountLoading }
            accountTypes={ accountTypes }
            label={ label }
            AutocompleteComponent={ AutocompleteComponent }
            readonly={ readonly }
        />
    )
}

export function AutocompleteSenderAccount( {
    receiverAccount,
    isReceiverAccountLoading,
    account,
    onAccountChanged,
    isAccountLoading,
    label = __( 'From account' ),
    readonly= false
} ) {

    // Get parent account
    var [ parentAccount, setParentAccount, isParentAccountLoading ] = useAccount( 
        receiverAccount?.parentAccountId || false
    );

    console.log( 'Parent Account Id:', receiverAccount?.parentAccountId, isReceiverAccountLoading ? 'loading' : 'not loading' );

    if( isReceiverAccountLoading ) {
        return false;
    }

    function onSenderAccountChanged( account ) {
        onAccountChanged( account?.accountType == 'VENDOR' ? { ...parentAccount } : { ... account } )
    }

    const AutocompleteComponent = () => {
        return (
            <AutocompleteAccount
                account={ account }
                onAccountChanged={ account => onAccountChanged( { ... account } )  }
                isAccountLoading={ isAccountLoading }
                includedTypes={ [ account?.accountType ] }
                childrenOfParentId={ receiverAccount?.parentAccountId }
                disabled={ account?.accountType == 'VENDOR' }
                readonly={ readonly }
            />
        )
    }
    
    return (
        <AutocompleteAccountWithTypeDropdown
            account={ account }
            onAccountChanged={ account => onSenderAccountChanged( account )  }
            isAccountLoading={ isAccountLoading }
            accountTypes={ GetSenderTypeOptsFromReceiver( receiverAccount?.accountType ) }
            label={ label }
            AutocompleteComponent={ AutocompleteComponent }
            readonly={ readonly }
        />
    )
} 


function GetSenderTypeOptsFromReceiver( receiverType )  {
    if( receiverType == 'VENDOR' ) {
        return [ 'REGISTRY' ];
    }

    if( receiverType == 'RESELLER' ) {
        return [ 'VENDOR' ];
    } 

    if( receiverType == 'CUSTOMER' )  {
        return [ 'RESELLER', 'VENDOR' ];
    }

    return false;
}