
export const isEmailValid = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

// Must contain lower case, upper case and be at least 8 characters
export const isPasswordStrong = ( password ) => {
    return String(password)
        .match(
            /(?=(?:\s|.)*[a-z](?:\s|.)*)(?=(?:\s|.)*[A-Z](?:\s|.)*)(?:\s|.){8,}/
        )
}