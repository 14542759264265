import React, { Fragment, useEffect, useState } from "react"
import { Card, CardBody } from "reactstrap"
import { __ } from "../../app"
import { updateSiteOwner, useSiteOwner } from '../../services/site-owner-service';
import { useHistory } from 'react-router-dom'
import { Button, CircularProgress } from "@mui/material";

import PageHeader from "../../components/page-header";
import SiteOwnerEditor from "../../components/site-owner-editor";

export default function EditSiteOwner( {
    match
} ) {
    const id = match?.params?.id;
    const [ ownerModel, setOwnerModel, isOwnerLoaded ] = useSiteOwner( id, { context: 'object' } )
    
    const history = useHistory();
    var historyState = history.location.state;
    
    const [ isLoading, setLoading ] = useState( false )
    const [ errors, setErrors ] = useState( [] )
    const [ alerts, setAlerts ] = useState( 
        historyState?.created ? [
            {
                severity: 'success',
                title: __( 'Site owner created successfully.' )
            }
        ] : 
        []
    )

    useEffect( () => {
        console.log( 'Model Updated', ownerModel )
    }, [ ownerModel ] )

    async function onSaveSiteOwnerClicked() {

        if( isLoading ) return;
        setLoading( true );
        setErrors( [] );
        setAlerts( [] )

        var errors = validateSiteOwner();
        if( errors.length ) {
            setErrors( errors );
            setAlerts( [] );
            setLoading( false );
            return;
        }

        var response = await updateSiteOwner( ownerModel )
        setLoading( false )

        if( response?.error && response?.message ) {
            setErrors( [ response.message ] );
            setAlerts( [] );
            return;
        }

        if( !response?.data || !response?.data?.id ) {
            setErrors( [ 'An unknown error occured, check console for more details.' ] );
            setAlerts( [] );
            return;
        }

        setOwnerModel( response.data );
        setErrors( [] );
        setAlerts( [
            {
                severity: 'success',
                title: __( 'Site owner saved successfully.' )
            }
        ] )
    }

    function validateSiteOwner() {

        if( !ownerModel?.companyName ) {
            errors.push( "Company name cannot be empty." );
        }

        if( !ownerModel?.contactName ) {
            errors.push( "Contact name cannot be empty." )
        }

        if( !ownerModel?.email ) {
            errors.push( "Email cannot be empty." )
        }

        if( !ownerModel?.phone ) {
            errors.push( "Phone cannot be empty." )
        }

        if( !ownerModel?.address?.city ) {
            errors.push( "City cannot be empty." )
        }

        if( !ownerModel?.address?.country ) {
            errors.push( "Country cannot be empty." )
        }

        if( !ownerModel?.address?.line1 ) {
            errors.push( "Address line 1 cannot be empty." )
        }

        if( !ownerModel?.address?.postCode ) {
            errors.push( "Postcode cannot be empty." )
        }

        if( !ownerModel?.address?.state ) {
            errors.push( "State cannot be empty." )
        }

        return errors;
    }


    function ActionsComponent() {

        return (
            <Fragment>
                <Button 
                    variant="contained"
                    onClick={ () => onSaveSiteOwnerClicked() }
                    disabled={ isOwnerLoaded }
                >
                    { __( 'Save' ) }
                </Button>
                        
                { isLoading && (
                    <CircularProgress />
                ) }
            </Fragment>
        )
    }

    return (
        <div className="views_view views__add-order">

            <PageHeader
                header={ 'Site Owner: ' + ( ownerModel?.companyName || id ) }
                breadcrumbs={ [ 
                    { 
                        to: '/site-owners',
                        label: __( 'Site Owners' )
                    },
                    { 
                        to: '/site-owners/id/' + id,
                        label: ownerModel?.companyName || id,
                        active: true
                    } 
                ] }
                alerts={ errors?.length > 0 ? [ 
                    ... alerts,
                    {
                        severity: 'error',
                        title: __( 'Error(s) while updating site owner.' ),
                        withList: errors
                    }
                ] : alerts }
            />  

            <Card>
                <CardBody>
            
                    <SiteOwnerEditor 
                        ownerModel={ ownerModel }
                        onOwnerModelChanged={ siteOwnerModel => {
                            setOwnerModel( siteOwnerModel ) 
                        } }
                        ActionsComponent={ ActionsComponent }
                    />
                </CardBody>
            </Card>
        </div>
    )
}