
import React, { Fragment, useEffect, useState } from "react"
import { Card, CardBody} from "reactstrap"
import { __ } from "../../app"
import ResellerEditor from "../../components/reseller-editor"
import { updateReseller, useReseller } from "../../services/reseller-service"

import {  useHistory } from 'react-router-dom'
import { Box, Button, CircularProgress, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ResellerBalancesDatatable from "../../components/reseller-balances-datatable"

import PageHeader from "../../components/page-header"
import ResellerSiteBalancesDatatable from "../../components/reseller-site-balances-datatable"


export default function EditReseller( {
    match
} ) {
    const id = match?.params?.id;
    const [ resellerModel, setResellerModel, isResellerModelLoading ] = useReseller( id, { context: 'object' } )
    
    const history = useHistory();
    var historyState = history.location.state;
    
    const [ isLoading, setLoading ] = useState( false )
    const [ errors, setErrors ] = useState( [] )
    const [ alerts, setAlerts ] = useState( 
        historyState?.created ? [
            {
                severity: 'success',
                title: __( 'Reseller created successfully.' )
            }
        ] : 
        []
    )

    const [ currentTab, setCurrentTab ] = useState( "details" );

    useEffect( () => {
        console.log( 'Model Updated', resellerModel )
    }, [ resellerModel ] )

    async function onSaveResellerClicked() {

        if( isLoading ) return;
        setLoading( true );
        setErrors( [] );
        setAlerts( [] )

        var errors = validateReseller();
        if( errors.length ) {
            setErrors( errors );
            setAlerts( [] );
            setLoading( false );
            return;
        }

        var response = await updateReseller( resellerModel )
        setLoading( false )

        if( response?.error && response?.message ) {
            setErrors( [ response.message ] );
            setAlerts( [] );
            return;
        }

        if( !response?.data || !response?.data?.id ) {
            setErrors( [ 'An unknown error occured, check console for more details.' ] );
            setAlerts( [] );
            return;
        }

        setResellerModel( response.data );
        setErrors( [] );
        setAlerts( [
            {
                severity: 'success',
                title: __( 'Reseller saved successfully.' )
            }
        ] )
    }
    
    function validateReseller() {
        var errors = [];

        if( !resellerModel?.vendor?.id ) {
            errors.push( 'Vendor cannot be empty.' )
        }

        if( !resellerModel?.email ) {
            errors.push( "Email cannot be empty." )
        }

        if( !resellerModel?.contactName ) {
            errors.push( "Contact name cannot be empty." )
        }

        if( !resellerModel?.companyName ) {
            errors.push( "Company name cannot be empty." );
        }

        if( !resellerModel?.phone ) {
            errors.push( "Phone cannot be empty." )
        }

        if( !resellerModel?.address?.city ) {
            errors.push( "Address city cannot be empty." )
        }

        if( !resellerModel?.address?.country ) {
            errors.push( "Address country cannot be empty." )
        }

        if( !resellerModel?.address?.line1 ) {
            errors.push( "Address Line 1 cannot be empty." )
        }

        if( !resellerModel?.address?.postCode ) {
            errors.push( "Address postcode cannot be empty." )
        }

        if( !resellerModel?.address?.state ) {
            errors.push( "Address state cannot be empty." )
        }

        return errors;
    }


    function ActionsComponent() {

        return (
            <Fragment>
                <Button 
                    variant="contained"
                    onClick={ () => onSaveResellerClicked() }
                    disabled={ isResellerModelLoading }
                >
                    { __( 'Save' ) }
                </Button>
                        
                { isLoading && (
                    <CircularProgress />
                ) }
            </Fragment>
        )
    }

    return (

        
        <div className="views_view views__edit-reseller">

            <PageHeader
                header={ 'Reseller: ' + ( resellerModel?.companyName || id ) }
                breadcrumbs={ [ 
                    { 
                        to: '/resellers',
                        label: __( 'Resellers' )
                    },
                    { 
                        to: '/resellers/id/' + id,
                        label: resellerModel?.companyName || id,
                        active: true
                    } 
                ] }
                alerts={ errors?.length > 0 ? [ 
                    ... alerts,
                    {
                        severity: 'error',
                        title: __( 'Error(s) while updating reseller.' ),
                        withList: errors
                    }
                ] : alerts }
            />  

            <TabContext value={ currentTab }>
                <Box>
                    <TabList onChange={ ( e, currentTab ) => setCurrentTab( currentTab ) }>
                        <Tab label={ __( 'Details' ) } value='details' />
                        <Tab label={ __( 'Site Balances' ) } value='site-balances' />
                        <Tab label={ __( 'Zone Balances' ) } value='balances' />
                    </TabList>
                </Box>

                <TabPanel value="details">
                        
                    <Card>
                        <CardBody>
                            <ResellerEditor 
                                resellerModel={ resellerModel }
                                isResellerModelLoading={ isResellerModelLoading }
                                onResellerModelChanged={ resellerModel => {
                                    setResellerModel( resellerModel ) 
                                } }
                                ActionsComponent={ ActionsComponent }
                            />
                        </CardBody>
                    </Card>
                </TabPanel>
                                

                <TabPanel value="balances">
                    { resellerModel?.id > 0 && <ResellerBalancesDatatable resellerId={ resellerModel.id } /> }
                </TabPanel>
                                

                <TabPanel value="site-balances">
                    { resellerModel?.id > 0 && <ResellerSiteBalancesDatatable resellerId={ resellerModel.id } /> }
                </TabPanel>

            </TabContext>

        </div>
    )
}