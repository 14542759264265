import React from "react"
import { Card, CardBody } from "reactstrap"
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

import { __ } from "../../app"
import { apiHost } from "../../config"
import DataTable from '../../components/datatable'
import { Link, useHistory } from 'react-router-dom'
import { urlEncode } from "../../helpers/url-encoding";
import PageHeader from "../../components/page-header";
import { enumVegetationTypes } from "../../enums";

var url = apiHost + "/api/admin/Site/datatables";

const zoneLinkRenderer = ( { row, formattedValue } ) => {
    if( formattedValue < 1 ) {
        return formattedValue;
    }

    var url =  urlEncode( {
        url: '/zones',
        data: {
            query: {
                filters: {
                    items: [
                        {
                            columnField: "siteId",
                            operatorValue: 'EQUAL',
                            value: row.siteId
                        }
                    ]
                }
            }
        }
    } );

    return (
        <Link to={ url } >{ formattedValue }</Link>
    )
}

function getColumns( { history } ) {
    return [
        {
            headerName: __( 'Site Id' ),
            field: 'siteId',
            headerAlign: 'left',
            align: 'left',
            flex: 1,
            renderCell: zoneLinkRenderer,
            hide: true
        },
        {
            headerName: __( 'Site' ),
            field: 'siteName',
            flex: 2,
            renderCell: zoneLinkRenderer
        },
        {
            headerName: __( 'Reference' ),
            field: 'reference',
            flex: 2,
            renderCell: zoneLinkRenderer,
            hide: true
        },
        {
            headerName: __( 'Owner Id' ),
            field: 'siteOwnerId',
            flex: 1,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/site-owners/id/" + row.siteOwnerId } >{ formattedValue }</Link>
            ),
            hide: true
        },
        {
            headerName: __( 'Site Owner' ),
            field: 'siteOwner',
            flex: 2,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/site-owners/id/" + row.siteOwnerId } >{ formattedValue }</Link>
            )
        },
        {
            headerName: __( 'Contact' ),
            field: 'contactName',
            flex: 2,
            hide: true
        },
        {
            headerName: __( 'Email' ),
            field: 'email',
            flex: 2,
            renderCell: ( { row, formattedValue } ) => (
                <a href={ "mailto:" + row.email } >{ formattedValue }</a>
            ),
            hide: true
        },
        {
            headerName: __( 'Phone' ),
            field: 'phone',
            flex: 1,
            renderCell: ( { row, formattedValue } ) => (
                <a href={ "tel:" + row.phone } >{ formattedValue }</a>
            ),
            hide: true
        },
        {
            headerName: __( 'No. of Zones' ),
            field: 'totalZones',
            flex: 1,
            renderCell: zoneLinkRenderer
        },
        {
            headerName: __( 'Units Sold' ),
            field: 'unitsSold',
            type: "number",
            align: 'left',
            headerAlign: 'left',
            flex: 1,
        },
        {
            headerName: __( 'Units Retired' ),
            field: 'unitsRetired',
            align: 'left',
            headerAlign: 'left',
            flex: 1,
            hide: true
        },
        {
            headerName: __( 'Units Allocated' ),
            field: 'unitsAllocated',
            type: "number",
            align: 'left',
            headerAlign: 'left',
            flex: 1
        },
        {
            headerName: __( 'Units Unallocated' ),
            field: 'unitsUnallocated',
            align: 'left',
            headerAlign: 'left',
            type: "number",
            flex: 1,
            hide: true
        },
        {
            headerName: __( 'Total Units' ),
            field: 'totalUnits',
            type: "number",
            align: 'left',
            headerAlign: 'left',
            flex: 1
        },
        {
            headerName: __( 'Vegetation(s)' ),
            field: 'biomes',
            flex: 2,
            type: "singleSelect",
            valueOptions: enumVegetationTypes
        },
        {
            field: 'actions',
            headerName: __( 'Actions' ),
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem 
                    onClick={ () => history.push( '/sites/id/' + params.id )  } 
                    icon={<EditIcon />} 
                    label={ __("View / Edit Site") } 
                />,
            ]
        }
    ]
}

export default function ListSites() {
    const history = useHistory();
    const columns = getColumns( { history } )
    
    
    return (
        <div className="table_list table__sites">
            
            <PageHeader
                header={ __( 'Sites' ) }
                breadcrumbs={ [ 
                    { 
                        to: '/sites',
                        label: __( 'Sites' ),
                        active: true
                    }
                ] }
            />
            
            <Card>
                <CardBody>
                    <div style={{ height: 'calc( 100vh - 190px)', width: '100%' }}>
                        <div style={{ display: 'flex', height: '100%' }}>
                            <div style={{ flexGrow: 1 }}>
                                <DataTable 
                                    url={ url }
                                    columns={ columns }
                                    getRowId={ row => row.siteId }
                                />
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>

        </div>
    )
}