import { Button, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { defaultFieldVariant, __ } from '../../app'
import MuiPhoneNumber from 'material-ui-phone-number'
import AddressFields from '../address-fields/address-fields'
import { useSelector } from 'react-redux'


export default function SiteOwnerEditor( { ownerModel, onOwnerModelChanged = false, isOwnerModelLoading = false, ActionsComponent = false } ) {
    const role = useSelector(state => state.auth.user?.profile?.role)
    const isEditsDisabled = role != 'AdminUser';
    
    function onEmailChanged( event ) {
        onOwnerModelChanged?.( {
            ...ownerModel,
            email: event.target.value
        } )
    }

    function onContactNameChanged( event ) {
        onOwnerModelChanged?.( {
            ...ownerModel,
            contactName: event.target.value
        } )
    }

    function onCompanyNameChanged( event ) {
        onOwnerModelChanged?.( {
            ...ownerModel,
            companyName: event.target.value
        } )
    }

    function onPhoneChanged( value ) {
        onOwnerModelChanged?.( {
            ...ownerModel,
            phone: value
        } )
    }

    function onAddressChanged( address ) {
        onOwnerModelChanged?.( {
            ...ownerModel,
            address: {
                ... address
            }
        } )
    }

    return (
        <div className="components__site-owner-editor">
            <Row>
                <Col>
                    <h4 className='mb-3'>{__( "Site Owner Details" )}</h4>

                    { ownerModel?.Id > 0 &&
                        <TextField
                            className="w-100 mb-3" 
                            label={ __( "Site Id" ) }
                            variant={ defaultFieldVariant } 
                            disabled={ true }
                            value={ isOwnerModelLoading ? __( 'Loading...' ) : ownerModel.id }
                            onChange={ e => false  }

                        />
                    }    

                    <TextField
                        className="w-100 mb-3" 
                        label={ __( "Email" ) }
                        variant={ defaultFieldVariant } 
                        disabled={ isOwnerModelLoading || isEditsDisabled }
                        value={ isOwnerModelLoading ? __( 'Loading...' ) : ( ownerModel?.email || '' ) }
                        onChange={ e => onEmailChanged( e ) }
                    />

                    <TextField
                        className="w-100 mb-3" 
                        label={ __( "Contact Name" ) }
                        variant={ defaultFieldVariant } 
                        disabled={ isOwnerModelLoading || isEditsDisabled }
                        value={ isOwnerModelLoading ? __( 'Loading...' ) : ( ownerModel?.contactName || '' ) }
                        onChange={ e => onContactNameChanged( e ) }
                    />

                    <TextField
                        className="w-100 mb-3" 
                        label={ __( "Company Name" ) }
                        variant={ defaultFieldVariant } 
                        disabled={ isOwnerModelLoading || isEditsDisabled}
                        value={ isOwnerModelLoading ? __( 'Loading...' ) : ( ownerModel?.companyName || '' ) }
                        onChange={ e => onCompanyNameChanged( e ) }
                    />

                    <MuiPhoneNumber
                        defaultCountry='au'
                        label={ __( "Phone" ) }
                        className="w-100 mb-3" 
                        variant={ defaultFieldVariant } 
                        disabled={ isOwnerModelLoading || isEditsDisabled }
                        value={ ownerModel?.phone }
                        onChange={ value => onPhoneChanged( value ) }
                    />

                    <AddressFields
                        address={ ownerModel?.address || {} }
                        onChange={ address => onAddressChanged( address ) }
                        isAddressLoading={ isOwnerModelLoading }
                        disabled={ isOwnerModelLoading || isEditsDisabled }
                    />

                    { !isEditsDisabled && ActionsComponent && <ActionsComponent /> }
                </Col>
            </Row>
        </div>
    )
}