import axios from 'axios';
import { __ } from "../app"
import { apiHost, authHost } from "../config"
import {  fetchResultsByTerm, useFetchResultByUrl } from './helpers/autocomplete-service';

export async function autocomplete( term, { abortController = AbortController } ) {
    var url = apiHost + '/api/admin/siteOwner/autocomplete';
    return await fetchResultsByTerm( url, term, { abortController } )
}

export function useSiteOwner( ownerId = false, { context = 'autocomplete' } = {} ) {

    var fetchParams = {
        getId: r => r?.id,
        getUrl: r => apiHost + '/api/admin/siteowner/' + parseInt( r?.id ),
        initialState: {
            id: ownerId
        }
    } 

    if( context == 'autocomplete' ) {
        fetchParams = {
            getId: r => r?.ownerId,
            getUrl: r => apiHost + '/api/admin/siteowner/autocomplete/' + parseInt( r?.ownerId ),
            initialState: {
                ownerId
            }
        }
    }

    return useFetchResultByUrl( fetchParams );
}


export async function createSiteOwner( ownerModel ) {
    
    try {
        const response = await axios.post( apiHost + '/api/admin/siteowner',  { 
            ...ownerModel,
            id: 0
        } );

        return response;
    }
    catch( e ) {
        return {
            error: true,
            message: e?.response?.data
        }
    }
}



export async function updateSiteOwner( ownerModel ) {

    try {
        const response = await axios.put( apiHost + '/api/admin/siteowner/' + ownerModel?.id, ownerModel )
        return response;
    }
    catch( e ) {
        return {
            error: true,
            message: e?.response?.data
        }
    }
}