import { Button, CircularProgress, TextField } from '@mui/material'
import React, { useEffect, Fragment, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { defaultFieldVariant, __ } from '../../app'
import { useAccount, useZoneWithBalance, useSiteWithBalance } from '../../services/account-service'
import newId from '../../utils/newId'
import { AutocompleteReceiverAccount, AutocompleteSenderAccount } from '../autocomplete/autocomplete-account'
import AutocompleteSiteForSender from '../autocomplete/autocomplete-site-for-sender'
import AutocompleteZoneForSender from '../autocomplete/autocomplete-zone'


export default function OrderEditor( { orderModel, onOrderModelChanged = false, isOrderModelLoading = false, readonly = false, ActionsComponent = false } ) {

    var [ receiverAccount, setReceiverAccount, isReceiverAccountLoading ] = useAccount( orderModel?.receiverId || false );
    
    // Prepare transfers by providing them keys and adding default
    useEffect( () => {
        var transfersChanged = false;
        var transfers = orderModel.transfers;

        if( isOrderModelLoading ) {
            return;
        }

        if( !transfers?.length ) {
            transfers = [ {} ];
            transfersChanged = true;
        }

        for( var i in transfers ) {
            if( !transfers[i]?.key ) {
                transfers[i].key = newId( 'transfer-' )
                transfersChanged = true;
            }
        }

        if( transfersChanged ) {
            onOrderModelChanged( {
                ...orderModel,
                transfers
            } )
        }
        
    }, [ orderModel.transfers, isOrderModelLoading ] );

    function onReceiverAccountChanged( receiverAccount ) {
        onOrderModelChanged?.( {
            ...orderModel,
            receiverId: receiverAccount?.accountId || false,
            transfers: []
        } )
        setReceiverAccount( receiverAccount );
    }

    function onTransferChanged( index, transfer ) {
        var transfers = [ ...orderModel.transfers ]
        transfers[index] = { ...transfer }
        onOrderModelChanged?.( {
            ...orderModel,
            transfers
        } )
    }

    // Group transfers by sender Id and preserve index for updates
    var transferGroups = (orderModel.transfers || []).reduce( ( transferGroups, transfer, index ) => {
        var groupBy = ( transfer?.senderId === 0 || transfer?.senderId ) > 0 ? transfer?.senderId : -1;

        if( !transferGroups[ groupBy ] ) {
            transferGroups[ groupBy ] = {};
        }

        transferGroups[groupBy][index] = transfer;
        return transferGroups;
    }, {} );

    function onAddSenderClicked() {
        if( transferGroups[ -1 ] ) {
            return; // empty sender already exists
        }

        var transfers = [ ...orderModel.transfers ];
        transfers.push( {
            senderId: -1
        } );

        onOrderModelChanged?.( {
            ...orderModel,
            transfers
        } )
    }

    function onTransferAdded( senderId ) {
        var transfers = [ ...orderModel.transfers ];
        transfers.push( {
            senderId
        } );

        onOrderModelChanged?.( {
            ...orderModel,
            transfers
        } )
    }

    function onTransferRemoved( index ) {
        var transfers = [ ...orderModel.transfers ];
        transfers.splice( index, 1 );
        onOrderModelChanged?.( {
            ...orderModel,
            transfers
        } ) 
    }
     
    return (
        <div className="components__order-editor">
            <div 
                style={{
                    marginBottom: 20
                }}
            >
                <div
                    className='d-flex justify-content-end'
                >


                    <div className='components__order-editor-actions d-flex'>
                        { ActionsComponent && <ActionsComponent /> }
                    </div>
                </div>

                <div 
                    style={{ 
                        width: '50%',
                        marginTop: 20,
                        marginBottom: 40,
                    }}>

                    <h3 className='pb-3 mb-0 text-uppercase'>
                        { __( 'Recipient' ) }
                    </h3>

                    
                    { isOrderModelLoading ? (
                        <CircularProgress />
                    ) : (
                        <AutocompleteReceiverAccount 
                            label={ __( 'Recipient type' ) }
                            account={ receiverAccount }
                            onAccountChanged={ account => onReceiverAccountChanged( account ) }
                            isAccountLoading={ isReceiverAccountLoading }
                            readonly={ readonly }
                        />
                    ) }
                </div>
            </div>

            { !isOrderModelLoading && receiverAccount?.accountId && (
                <div className="mb-4">
                    <h3 className='pb-3 mb-0 text-uppercase'>
                        { __( 'Sender' ) }
                    </h3>

                    { Object.keys( transferGroups ).map( senderKey => (
                        <OrderTransferGroupEditor
                            key={ 'transfers-' + senderKey }
                            senderId={ senderKey == '-1' ? false : senderKey }
                            transfers={ transferGroups[ senderKey ] }
                            receiverAccount={ receiverAccount }
                            onTransferChanged={( index, transfer ) => onTransferChanged( index, transfer ) }
                            onTransferAdded={ () => onTransferAdded( senderKey > 0 ? senderKey : false ) }
                            onTransferRemoved={ index => onTransferRemoved( index ) }
                            readonly={ readonly }
                        />
                    ) ) }

                    { !transferGroups[-1] && !readonly && receiverAccount?.accountType == 'CUSTOMER' && (
                        <Button className="w-100" variant={ defaultFieldVariant } onClick={ () => onAddSenderClicked() }>{ __( "Add Sender" ) }</Button>
                    ) }
                </div>
            ) }
                        
        </div>
    )
}

function OrderTransferGroupEditor( {
    transfers,
    senderId,
    receiverAccount,
    isReceiverAccountLoading,
    onTransferChanged,
    onTransferAdded,
    onTransferRemoved,
    readonly = false
} ) {

    var [ senderAccount, setSenderAccount, isSenderAccountLoading ] = useAccount( senderId )

    function onSenderAccountChanged( senderAccount ) {
        Object.keys( transfers ).forEach( index => {
            onTransferChanged?.( index, {
                ...transfers[index],
                senderId: senderAccount?.accountId
            } )
        } );

        setSenderAccount( senderAccount );
    }

    return (
        <div 
            className="components__order-transfers-editor d-flex mb-3"
            style={{ 
                paddingBottom: 20, 
                borderBottom: '1px solid #f1f1f1' 
            }}
        >
            <div style={{ width:"50%", marginRight: 10 }}>
                <AutocompleteSenderAccount
                    label={ __( "Sender type" ) }
                    account={ senderAccount }
                    onAccountChanged={ account => onSenderAccountChanged( account ) }
                    isAccountLoading={ isSenderAccountLoading }
                    receiverAccount={ receiverAccount }
                    isReceiverAccountLoading={ isReceiverAccountLoading }
                    readonly={ readonly } 
                />
            </div>


            <div style={{ width:"50%" }}>
                <Fragment>
                    { Object.keys( transfers ).filter( i => transfers[i]?.key ).map( index => {
                        var transfer = transfers[index];
                        return (
                            <OrderTransferEditor 
                                key={ transfer.key }
                                transfer={ transfers[index] }
                                onTransferChanged={ transfer => onTransferChanged?.( index, transfer ) }
                                onTransferRemoved={ () => onTransferRemoved?.( index ) }
                                senderAccount={ senderAccount }
                                readonly={ readonly }
                            />
                        )
                    } ) }
                    
                    { !readonly && (
                        <div style={{ float: 'right' }} >
                            <Button
                                style={{ width: 100 }}
                                variant={ defaultFieldVariant } 
                                onClick={ () => onTransferAdded?.() }
                            >
                                { __( "Add" ) }
                            </Button>
                        </div>
                    ) }
                    
                </Fragment>
            </div>
        </div>
    )

}

function OrderTransferEditor({
    transfer,
    onTransferChanged,
    onTransferRemoved,
    senderAccount,
    readonly = false
}) {

    

    var [ zone, setZone, isZoneLoading ] = useZoneWithBalance( senderAccount.accountId, transfer.zoneId || false );
    var [ siteId, setSiteId ] = useState( false );
    var [ site, setSite, isSiteLoading ] = useSiteWithBalance( senderAccount.accountId, siteId || zone?.siteId || false )
    
    var creditMultiplier = zone.creditMultiplier > 0 ? zone.creditMultiplier : 1;

    var [ creditsTransfered, setCreditsTransfered ] = useState( Math.floor( ( transfer?.unitsTransfered || 0 ) / creditMultiplier ) )
    var availableCredits = Math.floor( zone.unitsAvailable * zone.creditMultiplier )

    // Update creditsTransfered when zone changes
    useEffect( () => {
        setCreditsTransfered( Math.floor( ( transfer?.unitsTransfered || 0 ) / creditMultiplier ) )
    }, [ zone, transfer ] )

    function onZoneChanged( zone ) {
        onTransferChanged( { 
            ...transfer,
            zoneId: zone?.zoneId
        } )
        setZone( zone )
    }

    function onSiteChanged( site ) {
        setSiteId( site?.siteId || false );
        setSite( site );

        onTransferChanged( {
            ... transfer,
            zoneId: false
        } )
        setZone( false );
    }

    function onCreditsTransferedChange( creditsTransfered ) {
        setCreditsTransfered( creditsTransfered );

        onTransferChanged( {
            ...transfer,
            unitsTransfered: Math.ceil( creditsTransfered * creditMultiplier )
        } )
    }

    return (
        <div className="d-flex justify-content-end py-2">
            { ( senderAccount?.accountId || senderAccount?.accountType == 'REGISTRY' ) && (
                <Fragment>
                    <div style={{ width: '40%', marginRight: 10 }}>
                        <AutocompleteSiteForSender
                            site={ site }
                            onSiteChanged={ onSiteChanged }
                            senderAccountId={ senderAccount.accountType == 'REGISTRY' ? 0 : senderAccount.accountId }
                            readonly={ readonly }
                        />

                        <div style={{ marginTop: 18 }}>
                            { ( site?.siteId || zone?.id) && 
                            
                                <AutocompleteZoneForSender 
                                    zone={ zone }
                                    onZoneChanged={ onZoneChanged }
                                    senderAccountId={ senderAccount.accountType == 'REGISTRY' ? 0 : senderAccount.accountId }
                                    siteId={ site?.siteId }
                                    readonly={ readonly }
                                />
                                
                            }
                        </div>
                    </div>

                    <div style={{ width: '15%', marginRight: 10 }}>
                        { zone.zoneId && !isZoneLoading && (
                            <Fragment>
                                { availableCredits > 0 ? (
                                    <TextField 
                                        label={ __( 'No. of Credits' ) }
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} 
                                        value={ creditsTransfered }
                                        onChange={ e => onCreditsTransferedChange( e.target.value ) }
                                        disabled={ readonly }
                                    />
                                ) : (
                                    <span className="my-3">{ __( "Insufficient Credits" ) }</span>
                                ) }
                            </Fragment>
                        ) }
                    </div>
                    
                    <div style={{ flexGrow: 1, marginRight: 10 }}>
                        { zone.zoneId && !isZoneLoading && (
                            <ul className="list-unstyled">
                                <li className='fw-bold'>{ __( 'Total Units: ' ) + ( transfer?.unitsTransfered || 0 )}</li>
                                <li>{ __( 'Available Units: ' ) + zone.unitsAvailable }</li>
                            </ul>
                        ) }
                    </div>
                </Fragment>
            ) }
            
            <div style={{ float: 'right' }}>
                { !readonly && (
                    <Button 
                        style={{ width: 100 }}
                        variant={ defaultFieldVariant } 
                        onClick={ () => onTransferRemoved?.() }
                    >
                        { __( "remove" ) }
                    </Button>
                ) }
            </div>

        </div>
    );

}
