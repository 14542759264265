import React, { Fragment, useState } from "react"
import { Card, CardBody } from "reactstrap"
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

import { __ } from "../../app"
import { apiHost } from "../../config"
import DataTable, { timeCellRenderer } from '../../components/datatable'
import { Link, useHistory } from 'react-router-dom'
import { Button, Dialog, DialogTitle, TextField, Box, DialogContent, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, DialogActions, Alert, AlertTitle } from "@mui/material";

import { urlEncode } from "../../helpers/url-encoding";
import { DateRangePicker } from '@mui/lab';
import newId from '../../utils/newId';
import PageHeader from "../../components/page-header";
import { enumOrderStatus, enumVegetationTypes } from "../../enums";

var url = apiHost + "/api/admin/Transfer/datatables";
function getColumns( { history } ) {
    return [
        {
            headerName: __( 'Transfer Id' ),
            field: 'transferId',
            headerAlign: 'left',
            align: 'left',
            flex: 1,
            hide: true,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/orders/id/" + row.orderId } >{ formattedValue }</Link>
            )
        },
        {
            headerName: __( 'Order Id' ),
            field: 'orderId',
            flex: 1,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/orders/id/" + row.orderId } >{ formattedValue }</Link>
            )
        },
        {
            headerName: __( 'No. of Units' ),
            field: 'unitsTransfered',
            flex: 1
        },
        {
            headerName: __( 'Sender Type' ),
            field: 'senderType',
            flex: 1,
            hide: true,
            type: "singleSelect",
            valueOptions: [ 'REGISTRY', 'RESELLER', 'VENDOR' ]
        },
        {
            headerName: __( 'Sender' ),
            field: 'sender',
            flex: 2,
            renderCell: ( { row, formattedValue } ) => {
                if( row?.senderType == 'VENDOR' ) {
                    return <Link to={ "/vendors/id/" + row.senderId } >{ formattedValue }</Link>
                }
                if( row?.senderType == 'RESELLER' ) {
                    return <Link to={ "/resellers/id/" + row.senderId } >{ formattedValue }</Link>
                }
                if( row?.senderType == 'CUSTOMER' ) {
                    var url =  urlEncode( {
                        url: '/customers',
                        data: {
                            query: {
                                filters: {
                                    items: [
                                        {
                                            columnField: "customerId",
                                            operatorValue: 'EQUAL',
                                            value: row.senderId
                                        }
                                    ]
                                }
                            }
                        }
                    } );
    
                    return (
                        <Link to={ url } >{ formattedValue }</Link>
                    )
                }

                return formattedValue;
            },
        },
        {
            headerName: __( 'Sender Account' ),
            field: 'senderAccount',
            flex: 1,
            hide: true
        },
        {
            headerName: __( 'Receiver Type' ),
            field: 'receiverType',
            flex: 1,
            hide: true,
            type: "singleSelect",
            valueOptions: [ 'VENDOR', 'RESELLER', 'CUSTOMER' ]
        },
        {
            headerName: __( 'Receiver' ),
            field: 'receiver',
            flex: 1,
            renderCell: ( { row, formattedValue } ) => {
                if( row?.receiverType == 'VENDOR' ) {
                    return <Link to={ "/vendors/id/" + row.receiverId } >{ formattedValue }</Link>
                }
                if( row?.receiverType == 'RESELLER' ) {
                    return <Link to={ "/resellers/id/" + row.receiverId } >{ formattedValue }</Link>
                }
                if( row?.receiverType == 'CUSTOMER' ) {
                    var url =  urlEncode( {
                        url: '/customers',
                        data: {
                            query: {
                                filters: {
                                    items: [
                                        {
                                            columnField: "customerId",
                                            operatorValue: 'EQUAL',
                                            value: row.receiverId
                                        }
                                    ]
                                }
                            }
                        }
                    } );
    
                    return (
                        <Link to={ url } >{ formattedValue }</Link>
                    )
                }

                return formattedValue;
            },
        },
        {
            headerName: __( 'Receiver Account' ),
            field: 'receiverAccount',
            flex: 1,
            hide: true
        },
        {
            headerName: __( 'Zone Id' ),
            field: 'zoneId',
            flex: 1,
            hide: true,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/zones/id/" + row.zoneId } >{ formattedValue }</Link>
            )
        },
        {
            headerName: __( 'Zone' ),
            field: 'zoneReference',
            flex: 1,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/zones/id/" + row.zoneId } >{ formattedValue }</Link>
            )
        },
        {
            headerName: __( 'Status' ),
            field: 'status',
            flex: 1,
            type: "singleSelect",
            valueOptions: enumOrderStatus
        },
        {
            headerName: __( 'Vegetation(s)' ),
            field: 'vegetationType',
            flex: 2,
            hide: true,
            type: "singleSelect",
            valueOptions: enumVegetationTypes
        },
        {
            headerName: __( 'Created Date' ),
            field: 'createdDate',
            type: 'date',
            flex: 2,
            hide: true
        },
        {
            headerName: __( 'Created Time' ),
            field: 'createdTime',
            type: 'time',
            flex: 2,
            renderCell: timeCellRenderer,
            hide: true
        },
        {
            headerName: __( 'Completed Date' ),
            field: 'completedDate',
            type: 'date',
            flex: 2,
            hide: true
        },
        {
            headerName: __( 'Completed Time' ),
            field: 'completedTime',
            type: 'time',
            flex: 2,
            renderCell: timeCellRenderer,
            hide: true
        },
        {
            headerName: __( 'Updated Date' ),
            field: 'modifiedDate',
            type: 'date',
            flex: 2
        },
        {
            headerName: __( 'Updated Time' ),
            field: 'modifiedTime',
            type: 'time',
            flex: 2,
            renderCell: timeCellRenderer
        },
        {
            field: 'actions',
            headerName: __( 'Actions' ),
            type: 'actions',
            getActions: ( { row }) => [
                <GridActionsCellItem 
                    onClick={ () => history.push( '/orders/id/' + row.orderId )  } 
                    icon={<EditIcon />} 
                    label={ __("View / Edit Transfer") } 
                />,
            ]
        }
    ]
}

function DateFilterDialog( {
    open = false,
    onClose = false,
    onFilter = false
} = {} ) {

    const [ value, setValue ] = useState( [ null, null ] );
    const [ filterBy, setFilterBy ] = useState( 'createdDate' );
    const [ error, setError ] = useState( false );

    function onApplyClicked() {
        setError( false );

        if( !value[0] || !value[1] ) {
            setError( __( 'Please select a start/end date.' ) );
            return;
        }

        onFilter?.( {
            value,
            filterBy
        } )
    }

 
    return (
        <Dialog
            open={ open }
        >
            <DialogTitle>{ __( 'Date Filter' ) }</DialogTitle>


            <DialogContent style={{ overflow: 'visible' }} >

                { error != false && (
                    <Alert severity="error">
                        <AlertTitle>{ error }</AlertTitle>
                    </Alert>
                ) }

                <FormControl>
                    <FormLabel id="radio-filter-date-by-label">{ __( 'Filter date by' ) }</FormLabel>
                    <RadioGroup
                        aria-labelledby="radio-filter-date-by-label"
                        row={true}
                        className="mb-3"
                        value={ filterBy }
                        onChange={ event => setFilterBy( event.target.value ) }
                    >
                        <FormControlLabel value="createdDate" control={<Radio />} label={ __( 'Created' ) } />
                        <FormControlLabel value="modifiedDate" control={<Radio />} label={ __( 'Modified' ) } />
                        <FormControlLabel value="completedDate" control={<Radio />} label={ __( 'Completed' ) } />
                    </RadioGroup>
                </FormControl>

                <DateRangePicker
                    startText={ __( 'Start' ) }
                    endText={ __( 'End' ) }
                    value={ value }
                    onChange={ value => setValue( value ) }
                    renderInput={(startProps, endProps) => (
                        <Fragment>
                            <TextField {...startProps} />
                            <Box sx={{ mx: 2 }}> to </Box>
                            <TextField {...endProps} />
                        </Fragment>
                    )}
                />

            </DialogContent>

            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={ () => onClose?.() }
                >
                    { __('Cancel') }
                </Button>
                <Button
                    variant="contained"
                    onClick={ () => onApplyClicked() }
                >
                    { __('Apply Filter') }
                </Button>
                
            </DialogActions>

        </Dialog>
    )
}

export default function ListTransfers() {
    const history = useHistory();
    const columns = getColumns( { history } )
    const [filterDateDialogOpen, setFilterDateDialogOpen ] = useState( false );
    
    return (
        <div className="table_list table__transfers">

            

            <PageHeader
                header={ __( 'Transfers' ) }
                breadcrumbs={ [ 
                    { 
                        to: '/order-transfers',
                        label: __( 'Transfers' ),
                        active: true
                    }
                ] }
                HeaderComponent={ ( { header } ) => (
                    <div className="d-flex justify-content-between">
                        <h1 className="my-3">{ header }</h1>
                        <div className="pt-3">
                            <Button 
                                onClick={ () => setFilterDateDialogOpen( true ) } 
                                variant={ 'contained' }
                            >
                                { __( 'Filter dates' ) }
                            </Button>
                        </div>
                    </div>
                ) }
            />

            
            <Card>
                <CardBody>
                    <div style={{ height: 'calc( 100vh - 190px)', width: '100%' }}>
                        <div style={{ display: 'flex', height: '100%' }}>
                            <div style={{ flexGrow: 1 }}>
                                <DataTable 
                                    url={ url }
                                    columns={ columns }
                                    getRowId={ row => row.transferId }
                                    DialogsComponent={ ( { state, setState }) => {


                                        function getDateString( date ) {
                                            return date.getFullYear() 
                                                + '-' + ( '0' + ( date.getMonth() + 1 ) ).slice( -2 )
                                                + '-' + ( '0' + date.getDate() ).slice( -2 )
                                        }

                                        function onFilterClicked( filters ) {
                                            setState( {
                                                ... state,
                                                filters: {
                                                    ... state.filters,
                                                    items: [
                                                        {
                                                            id: newId( false ),
                                                            columnField: filters?.filterBy,
                                                            operatorValue: 'AFTER',
                                                            value: getDateString( filters.value[0] ),
                                                        },
                                                        {
                                                            id: newId( false ),
                                                            columnField: filters?.filterBy,
                                                            operatorValue: 'BEFORE',
                                                            value: getDateString( filters.value[1] )
                                                        }
                                                    ]
                                                }
                                            } )

                                            setFilterDateDialogOpen( false );
                                        }

                                        return (
                                            <DateFilterDialog 
                                                open={ filterDateDialogOpen }
                                                onClose={ () => setFilterDateDialogOpen( false ) }
                                                onFilter={ filter => onFilterClicked( filter ) }
                                            />
                                        )

                                    } }
                                />
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>


        </div>
    )
}