import React, { Fragment, useEffect, useState } from "react"
import { Card, CardBody } from "reactstrap"
import { __ } from "../../app"
import ZoneEditor from "../../components/zone-editor"
import { updateZone, useZone, publishZone, registerUnits, retireZone } from '../../services/zone-service';
import { useHistory } from 'react-router-dom'
import { Alert, AlertTitle, Breadcrumbs, Button, CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { ContinueCancelDialog } from "../../components/continue-cancel-dialog";
import PageHeader from "../../components/page-header";



function RegisterUnitsDialog( { isOpen, onClose, onRegisterUnits } ) {

    // const [ file, setFile ] = useState( false );
    const [ unitsJson, setUnitsJson ] = useState( false );
    const [ isFileLoading, setFileLoading ] = useState( false );
    const [ isSubmitting, setIsSubmitting ] = useState( false );
    const [ error, setError ] = useState( false );

    function onFileUploaded( event ) {
        if( !event.target.files[0] ) {
            setUnitsJson( false );
            setFileLoading( false );
            return;
        }

        // setFile( event.target.files[0] );
        setFileLoading( true );
        
        const fileReader = new FileReader();
        fileReader.readAsText( event.target.files[0], "UTF-8" );
        fileReader.onload = e => {
            if( !setFileLoading ) {
                return;
            }

            setUnitsJson( e.target.result );
            setFileLoading( false );
        }
    }

    function onRegisterUnitsButtonClicked() {
        if( isSubmitting ) return;

        var featureCollection;
        try {
            featureCollection = JSON.parse( unitsJson );
        }
        catch( e ) {
            setError( 'Could not parse FeatureCollection json' );
            return;
        }

        if( !featureCollection?.features ) {
            setError( 'Could not parse FeatureCollection json. Missing or empty features.' );
            return;
        }

        var polygons = featureCollection.features.map( feature => feature.geometry );

        setIsSubmitting( true );
        onRegisterUnits( polygons );
        onClose();
        setIsSubmitting( false );
    } 

    return (
        <Dialog
            open={ isOpen }
            onClose={ () => onClose( false ) }
        >
            <DialogTitle>{ __("Register Units from GeoJSON (FeatureCollection)") }</DialogTitle>
            <DialogContent>

                { error && (
                    <Alert severity="error">
                        <AlertTitle>{ error }</AlertTitle>
                    </Alert>
                ) }

                <input 
                    type="file"
                    onChange={ event => onFileUploaded( event ) } 
                />

                { isFileLoading && <CircularProgress /> }

                {/* <Editor
                    autoFocus
                    value={ unitsJson }
                    onValueChange={ unitsJson => setUnitsJson( unitsJson ) }
                    highlight={ code => highlight( code, languages.js ) }
                    padding={20}
                    placeholder="{}"
                    style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        border: '1px solid lightgray',
                        fontSize: 12,
                        minHeight: 300,
                        width: 500
                    }}
                />     */}

            </DialogContent>

            <Button
                variant="contained"
                disabled={ !unitsJson || isFileLoading || isSubmitting }
                onClick={ () => onRegisterUnitsButtonClicked() }
            >
                { __( 'Register Units' ) }
                { isSubmitting && <CircularProgress /> }
            </Button>

        </Dialog>

    )

}



export default function EditZone( {
    match
} ) {
    const id = match?.params?.id;
    const [ zoneModel, setZoneModel, isZoneModelLoading ] = useZone( match?.params?.id, { context: 'object' } )

    const history = useHistory();
    var historyState = history.location.state;
    
    const [ unitsModalOpen, setUnitsModalOpen ] = useState( false )
    const [ isLoading, setLoading ] = useState( false )
    const [ errors, setErrors ] = useState( [] )
    const [ alerts, setAlerts ] = useState( 
        historyState?.created ? [
            {
                severity: 'success',
                title: __( 'Zone created successfully.' )
            }
        ] : 
        []
    )

    useEffect( () => {
        console.log( 'Model Updated', zoneModel )
    }, [ zoneModel ] )

    async function onSaveZoneClicked() {

        if( isLoading ) return;
        setLoading( true );
        setErrors( [] );
        setAlerts( [] )

        var errors = validateZone();
        if( errors.length ) {
            setErrors( errors );
            setAlerts( [] );
            setLoading( false );
            return;
        }

        var response = await updateZone( zoneModel )
        setLoading( false )

        if( response?.error && response?.message ) {
            setErrors( [ response.message ] );
            setAlerts( [] );
            return;
        }

        if( !response?.data || !response?.data?.id ) {
            setErrors( [ 'An unknown error occured, check console for more details.' ] );
            setAlerts( [] );
            return;
        }

        setZoneModel( response.data );
        setErrors( [] );
        setAlerts( [
            {
                severity: 'success',
                title: __( 'Zone saved successfully.' )
            }
        ] )

    }

    async function onPublishZoneClicked() {

        if( isLoading ) return;
        setLoading( true );
        setErrors( [] );
        setAlerts( [] )

        var errors = validateZone();
        if( errors.length ) {
            setErrors( errors );
            setAlerts( [] );
            setLoading( false );
            return;
        }

        var response = await publishZone( zoneModel )
        setLoading( false )

        if( response?.error && response?.message ) {
            setErrors( [ response.message ] );
            setAlerts( [] );
            return;
        }

        if( !response?.data || !response?.data?.id ) {
            setErrors( [ 'An unknown error occured, check console for more details.' ] );
            setAlerts( [] );
            return;
        }

        setZoneModel( response.data );
        setErrors( [] );
        setAlerts( [
            {
                severity: 'success',
                title: __( 'Zone published successfully.' )
            }
        ] )

    }

    async function onRetireZoneClicked() {

        if( isLoading ) return;
        setLoading( true );
        setErrors( [] );
        setAlerts( [] )

        var errors = validateZone();
        if( errors.length ) {
            setErrors( errors );
            setAlerts( [] );
            setLoading( false );
            return;
        }

        var response = await retireZone( zoneModel )
        setLoading( false )

        if( response?.error && response?.message ) {
            setErrors( [ response.message ] );
            setAlerts( [] );
            return;
        }

        if( !response?.data || !response?.data?.id ) {
            setErrors( [ 'An unknown error occured, check console for more details.' ] );
            setAlerts( [] );
            return;
        }

        setZoneModel( response.data );
        setErrors( [] );
        setAlerts( [
            {
                severity: 'success',
                title: __( 'Zone has been retired.' )
            }
        ] )

    }

    async function onRegisterUnits( polygons ) {

        if( isLoading ) return;
        setLoading( true );
        setErrors( [] );
        setAlerts( [
            {
                severity: 'info',
                title: __( 'Unit registration in progress. Please be patient, this may take some time.' )
            }
        ] )

        var response = await registerUnits( zoneModel, polygons );
        setLoading( false )

        if( response?.error && response?.message ) {
            setErrors( [ response.message ] );
            setAlerts( [] );
            return;
        }

        if( !response?.data || !response?.data?.id ) {
            setErrors( [ 'An unknown error occured, check console for more details.' ] );
            setAlerts( [] );
            return;
        }

        setZoneModel( response.data );
        setErrors( [] );
        setAlerts( [
            {
                severity: 'success',
                title: __( 'Units registered for zone successfully.' )
            }
        ] )
    }

    function validateZone() {
        var errors = [];
        if( !zoneModel.reference ) {
            errors.push( 'Zone reference cannot be empty.' );
        }

        if( !zoneModel.creditMultiplier ) {
            errors.push( 'Credit muliplier cannot be empty.' );
        }

        else if( zoneModel.creditMultiplier <= 0 ) {
            errors.push( 'Credit multiplier must be a positive number.' );
        }

        if( !zoneModel.site?.id ) {
            errors.push( 'Site cannot be empty.' );
        }

        if( !zoneModel.unitType?.id ) {
            errors.push( 'Unit type cannot be empty.' );
        }

        if( !zoneModel.biome?.id ) {
            errors.push( 'Biome cannot be empty.' );
        }

        if( !zoneModel.polygon?.source || JSON.stringify( zoneModel.polygon.source ) == JSON.stringify( {} ) ) {
            errors.push( 'Polygon source cannot  be empty.' );
        }

        return errors;
    }

    function ActionsComponent() {
        const [ isPublishDialogOpen, setPublishDialogOpen ] = useState( false )
        const [ isRetireDialogOpen, setRetireDialogOpen ] = useState( false )
        
        if( isZoneModelLoading ) {
            return <CircularProgress />
        }

        return (
            <Fragment>

                <ContinueCancelDialog 
                    isOpen={ isPublishDialogOpen }
                    setOpen={ open => setPublishDialogOpen( open ) }
                    title={ __( 'Are you sure you want to publish this zone?' ) }
                    content={ __( 'This operation cannot be undone.' ) }
                    onContinueClicked={ () => onPublishZoneClicked() }
                />

                <ContinueCancelDialog 
                    isOpen={ isRetireDialogOpen }
                    setOpen={ open => setRetireDialogOpen( open ) }
                    title={ __( 'Are you sure you want to retire this zone?' ) }
                    content={ __( 'This operation cannot be undone.' ) }
                    onContinueClicked={ () => onRetireZoneClicked() }
                />


                { zoneModel?.staatus == 'ACTIVE' && (
                    {/* <Button 
                        variant="contained"
                        onClick={ () => onSaveZoneClicked() }
                    >
                        { __( 'Retire' ) }
                    </Button> */}
                ) }

                { zoneModel?.status == 'ACTIVE' && (
                    <Fragment>
                        <Button 
                            variant="contained"
                            onClick={ () => setRetireDialogOpen( true ) }
                        >
                            { __( 'Retire Zone' ) }
                        </Button>
                    </Fragment>
                )}

                { zoneModel?.status == 'DRAFT' && (
                    <Fragment>
                        <Button 
                            variant="contained"
                            onClick={ () => onSaveZoneClicked() }
                        >
                            { __( 'Save Zone as Draft' ) }
                        </Button>

                        <Button 
                            variant="contained"
                            onClick={ () => setPublishDialogOpen( true ) }
                        >
                            { __( 'Finalise Zone & Publish' ) }
                        </Button>
                    </Fragment>
                )}
                

                { zoneModel?.status == 'DRAFT' && zoneModel.zoneSize == 0 && (
                    <Fragment>

                        <RegisterUnitsDialog 
                            isOpen={ unitsModalOpen }
                            onClose={ () => setUnitsModalOpen( false ) }
                            onRegisterUnits={ unitsJson => onRegisterUnits( unitsJson ) }
                        />

                        <Button 
                            variant="contained"
                            onClick={ () => setUnitsModalOpen( true ) }
                        >
                            { __( 'Add units via GeoJSON' ) }
                        </Button>

                    </Fragment>
                )}
                

                { isLoading && (
                    <CircularProgress />
                ) }
            </Fragment>
        )
    }

    return (
        <div className="views_view views__edit-zone">

        
            <PageHeader
                header={ 'Zone: ' + ( zoneModel?.reference || id ) }
                breadcrumbs={ [ 
                    { 
                        to: '/zones',
                        label: __( 'Zones' )
                    },
                    { 
                        to: '/zones/id/' + id,
                        label: zoneModel?.reference || id,
                        active: true
                    } 
                ] }
                alerts={ errors?.length > 0 ? [ 
                    ... alerts,
                    {
                        severity: 'error',
                        title: __( 'Error(s) while updating zone.' ),
                        withList: errors
                    }
                ] : alerts }

                SubHeaderComponent={ () => <i>{ __( "Status: " ) + ( zoneModel?.status || __( 'Loading' ) ) }</i> }
            />  

            <Card>
                <CardBody>
            
                    <ZoneEditor 
                        zoneModel={ zoneModel }
                        isZoneModelLoading={ isZoneModelLoading }
                        onZoneModelChanged={ zoneModel => {
                            setZoneModel( zoneModel ) 
                        } }
                        ActionsComponent={ ActionsComponent }
                    />
                </CardBody>
            </Card>
        </div>
    )
}