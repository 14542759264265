import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { __ } from '../app'
import { Fill } from 'react-slot-fill';
 
function ProtectedRoute({ 
  children,
  render, 
  requiredRoles = [], 
  fillName = 'dashboard.content',
  Component = false, 
  ...rest 
}) {

  const user = useSelector(state => state.auth.user)
  const role = useSelector(state => state.auth.user?.profile?.role)

  function RenderComponent( Component ) {
    return ( props ) => {
      
      if( fillName == false ) {
        return <Component { ...props } />
      }

      return (
        <Fill name={ fillName }>
            <Component { ...props } />
        </Fill>
      )
    }

  }

  if( !user ) {
    return <Redirect to={'/login'} />;
  }

  if( requiredRoles?.length > 0 && !requiredRoles.includes( role ) ) {
    return <Route {...rest} component={  RenderComponent( () => <h1>{ __( "You are not authorised to view this page." ) }</h1> ) } />
  }

  return <Route {...rest} component={ RenderComponent( Component ) } render={render}/>
}

export function ProtectedRedirect({ children, ... rest }) {
  const user = useSelector(state => state.auth.user)

  return user
    ? (<Redirect { ...rest } />)
    : (<Redirect to={'/login'} />)

}


export default ProtectedRoute