import { __ } from "../app";
import { Link } from 'react-router-dom'
import { Alert, AlertTitle, Breadcrumbs } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import { Fragment } from "react";

export default function PageHeader( {
    header = __( "Page" ),
    breadcrumbs = [],
    withHomeBase = true,
    alerts = [],
    HeaderComponent = ({ header, SubHeaderComponent }) => (
        <Fragment>
            <h1 className="my-3">{ header }</h1>
            { SubHeaderComponent && <SubHeaderComponent /> }
        </Fragment>
    ),
    SubHeaderComponent = false
} ) {

    // Surely there's a better way to do this...
    var props = {
        ...arguments[0],
        header,
        breadcrumbs,
        withHomeBase,
        alerts,
        HeaderComponent,
        SubHeaderComponent
    };

    if( withHomeBase ) {
        breadcrumbs = [
            {
                to: '/',
                label: __( 'Home' ),
                LabelComponent: () => <HomeIcon />
            },

            ...breadcrumbs
        ]
    }
    
    return (
        <div className="components__page-header">
            
            <Breadcrumbs aria-label="breadcrumb" className="mb-2" key="bedcrumbs">
                { breadcrumbs.map( props => {

                    // Load defaults
                    props = {
                        to: '/',
                        label: '',
                        LabelComponent: ({ label }) => label,
                        active: false,

                        ...props
                    }

                    const LabelComponent = props.LabelComponent
                    return (
                        <Link 
                            to={{ pathname: props.to }} 
                            key={props.to} 
                            underline="hover" 
                            className={ props.active ? 'active': '' } 
                        >
                            <LabelComponent { ...props }/>
                        </Link>
                    )
                } ) }
            </Breadcrumbs>
            
            <HeaderComponent { ...props } />

            { alerts?.map( props => {
                
                // Load defaults
                props = {
                    severity: 'info',
                    title: __( 'Alert' ),

                    // Optional List Component
                    withList: [],
                    ListComponent: ( { withList } ) => (
                        withList?.length > 0 ? (
                            <ul>{ withList.map( item => <li key={item}>{ item }</li> ) }</ul>
                        ) : false
                    ),

                    AlertComponent: ( { severity, title, ListComponent, ...props } ) => (
                        <Alert severity={ severity }>
                            <AlertTitle>{ title }</AlertTitle>
                            <ListComponent { ...props } />
                        </Alert>
                    ),
                    
                    ...props
                }

                const AlertComponent = props.AlertComponent;
                return <AlertComponent key={props.title} { ...props } />

            } ) }
            
        </div>
    )
}