
import { __ } from "../app"
import { apiHost, authHost } from "../config"
import { fetchResultsByTerm, useFetchResultByUrl } from './helpers/autocomplete-service';

export async function autocompleteAccount( term, { 
    parentAccountId = null,
    includedTypes = [ 'CUSTOMER', 'RESELLER', 'VENDOR' ],
    abortController = new AbortController 
} ) {
    var url = apiHost + '/api/admin/Account/autocomplete';
    return await fetchResultsByTerm( url, term, { 
        abortController,
        params: {
            includedTypes,
            childrenOfParentId: parentAccountId
        } 
    } )
}

export function useAccount( accountId, { context = 'autocomplete' } = {} ) {
    if( context == 'autocomplete' ) {
        return useFetchResultByUrl( {
            getId: r => r?.accountId,
            getUrl: r => apiHost + '/api/admin/Account/autocomplete/' + parseInt( r?.accountId ),
            initialState: {
                accountId,
                accountType: accountId == 0 ? 'REGISTRY' : false
            }
        } )
    }

    throw 'Unsupported site context "' + context + '".';
}

export async function autocompleteZonesWithBalance( accountId, term, { abortController = new AbortController, siteId = null  } ) {
    var url = apiHost + '/api/admin/Account/' + parseInt( accountId ) + '/balances/autocomplete';
    return await fetchResultsByTerm( url, term, { abortController, params: { siteId } } )
}

export function useZoneWithBalance( accountId, zoneId,  { context = 'autocomplete' } = {} ) {
    if( context == 'autocomplete' ) {
        return useFetchResultByUrl( {
            getId: r => r?.zoneId,
            getUrl: r => apiHost + '/api/admin/Account/' + parseInt( accountId ) + '/balances/autocomplete/' + parseInt( r?.zoneId ),
            initialState: {
                zoneId
            }
        } )
    }

    throw 'Unsupported site context "' + context + '".';
}

export async function autocompleteSitesWithBalance( accountId, term, { abortController = new AbortController } ) {
    var url = apiHost + '/api/admin/Account/' + parseInt( accountId ) + '/site-balances/autocomplete';
    return await fetchResultsByTerm( url, term, { abortController } )
}

export function useSiteWithBalance( accountId, siteId, { context = 'autocomplete' } = {} ) {
    if( context == 'autocomplete' ) {
        return useFetchResultByUrl( {
            getId: r => r?.siteId,
            getUrl: r => apiHost + '/api/admin/Account/' + parseInt( accountId ) + '/site-balances/autocomplete/' + parseInt( r?.siteId ),
            initialState: {
                siteId
            }
        } )
    }

    throw 'Unsupported site context "' + context + '".';
}