import React, { Component, Fragment } from 'react';
import * as Icon from 'react-feather';
import gravatar from 'gravatar'

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import { Slot, Fill, Provider } from 'react-slot-fill';

import { __ } from '../../app';

import '@adminkit/core/dist/css/app.css';
import '@adminkit/core/dist/js/app';
import './index.scss';
import { signoutRedirect } from '../../services/user-service';
import { useSelector } from 'react-redux';


export const Sidebar = ( props ) => (
    <div>
        <Slot name="Dashboard.Sidebar" />
    </div>
);


Sidebar.Item = ( { children } ) => (
    <Fill name="Dashboard.Sidebar">
        { children }
    </Fill>
)

export const SidebarItems = ( { children } ) => (
    <Sidebar.Item key="sidebar-items">
        { children }
    </Sidebar.Item>
)

export function SidebarItem( {
    id, 
    children, 
    icon = false, 
    label = "", 
    collapsed = false, 
    selected = false, 
    onClick = false,
    requiredRoles = false
} ) {
    const role = useSelector(state => state.auth.user?.profile?.role)

    var Icon = icon;
    
    if( requiredRoles?.length > 0 && !requiredRoles.includes( role ) ) {
        return false;
    }

    if( !children ) {
        return (
            <li className={ "sidebar-item" + ( selected ? " active" : "" ) }>
                <a className="sidebar-link" onClick={ () => onClick?.()} >
                    
                    <Icon />
                    <span className="align-middle">{ label }</span>
                </a>
            </li>
        );
    }


    return (
        <li className={ "sidebar-item has-collapse" + ( selected ? " active" : "" ) }>
            <a  data-bs-target={ "#" + id } data-bs-toggle="collapse" className={ "sidebar-link" + ( collapsed ? " collapsed" : "" ) }>
                
                <Icon />
                <span className="align-middle">{ label }</span>
            </a>

            <ul id={ id } className="sidebar-dropdown list-unstyled collapse " data-bs-parent="#sidebar">
                { children }
            </ul>
        </li>
    );
}


export class SidebarNav extends Component {

    render() {
        var { header, children, key } = this.props;

        return (
            <Fragment>
                <li key={ key } className="sidebar-header" key={ 'header.' + header }>{ header }</li>
                {children}
            </Fragment>
        );
    }

}


export class Dashboard extends Component {


    constructor() {
        super();
        
        this.state = {
            sidebarCollapsed: false
        }
    }


    render() {

        const { children, userData } = this.props;
        var email = userData?.auth?.user?.profile?.email;

        return (
            <div className="wrapper component-dashboard">
                <nav id="sidebar" className={ "sidebar js-sidebar " + ( this.state.sidebarCollapsed ? "collapsed" : "" ) }>
                    <SimpleBar>
                        <div className="sidebar-content js-simplebar" >
                            <a className="sidebar-brand" href="index.html">
                                <span className="align-middle">{ __( 'VegetationLink' ) }</span>
                            </a>
                            <ul className="sidebar-nav">
                                <Sidebar />
                            </ul>
                        </div>
                    </SimpleBar>
                </nav>
            
                <div className="main">
                    <nav className="navbar navbar-expand navbar-light navbar-bg">
                        <a className="sidebar-toggle js-sidebar-toggle" onClick={ () => this.setState( { sidebarCollapsed: !this.state.sidebarCollapsed } ) }>
                            <i className="hamburger align-self-center"></i>
                        </a>

                        <div className="navbar-collapse">
                            <ul className="navbar-nav navbar-align">

                                <li className="nav-item dropdown">
                                    { email && 
                                        <a className="nav-icon pe-md-0 dropdown-toggle" href="#" data-bs-toggle="dropdown">
                                            <img src={ gravatar.url( email, { size:150 } ) } className="avatar img-fluid rounded" />
                                        </a>
                                    }

                                    <div className="dropdown-menu dropdown-menu-end">
                                        <a className="dropdown-item" href="#" onClick={ () => signoutRedirect() }>{ __( "Log out" ) }</a>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </nav>
                    <div className="content px-2 py-3">
                        <div className="container-fluid">
                            { children }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}