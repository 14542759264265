import { Button, TextField, FormControl, FormLabel, FormGroup, Checkbox, FormControlLabel } from '@mui/material'
import React, { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { defaultFieldVariant, __ } from '../../app'
import MuiPhoneNumber from 'material-ui-phone-number'
import AutocompleteRole from '../autocomplete/autocomplete-role'
import { useRole } from '../../services/role-service'


export default function UserEditor( { userModel, onUserModelChanged = false, isUserModelLoading = false, ActionsComponent = false, mode = 'edit' } ) {

    const [ role, setRole, isRoleLoading ] = useRole( userModel?.roleId, { context: 'autocomplete' } );
    
    function onEmailChanged( event ) {
        onUserModelChanged?.( {
            ...userModel,
            email: event.target.value
        } )
    }

    function onPasswordChanged( event ) {
        onUserModelChanged?.( {
            ...userModel,
            password: event.target.value
        } )
    }

    function onRoleChanged( role ) {
        onUserModelChanged?.( {
            ...userModel,
            roleId: role.roleId
        } )
        setRole( role );
    }

    function onPortalAccessChanged( event, clientId ) {
        var clients = [ ...(userModel?.clients || [] ) ];

        if( event.target.checked && !clients.includes( clientId ) ) {
            clients.push( clientId );
        } 

        if( !event.target.checked && clients.includes( clientId ) ) {
            const index = clients.indexOf( clientId );
            if (index > -1) {
                clients.splice(index, 1);
            }
        } 

        onUserModelChanged?.( {
            ...userModel,
            clients
        } );
    }
    
    return (
        <div className="components__user-editor">
            <Row>
                <Col>
                    <h4 className='mb-3'>{__( "User Details" )}</h4>

                    { userModel?.Id > 0 &&
                        <TextField
                            className="w-100 mb-3" 
                            label={ __( "User Id" ) }
                            variant={ defaultFieldVariant } 
                            disabled={ true }
                            value={ isUserModelLoading ? __( 'Loading...' ) : userModel.id }
                            onChange={ e => false  }

                        />
                    }    

                    <TextField
                        className="w-100 mb-3" 
                        label={ __( "Email" ) }
                        variant={ defaultFieldVariant } 
                        disabled={ isUserModelLoading }
                        value={ isUserModelLoading ? __( 'Loading...' ) : ( userModel?.email || '' ) }
                        onChange={ e => onEmailChanged( e ) }
                    />

                    <TextField
                        className="w-100 mb-3" 
                        label={ mode == 'edit' ? __( "Change Password" ) : __( "Password" ) }
                        variant={ defaultFieldVariant } 
                        disabled={ isUserModelLoading }
                        value={ isUserModelLoading ? __( 'Loading...' ) : ( userModel?.password || '' ) }
                        onChange={ e => onPasswordChanged( e ) }
                        type="password"
                    />

                    <AutocompleteRole
                        role={ role }
                        onRoleChanged={ role => onRoleChanged( role ) }
                        isOwnerLoading={ isRoleLoading }
                        wrapperProps={{
                            className: "mb-3"
                        }}
                    />
                                
                    <FormControl sx={{ my: 3 }} component="fieldset" variant="standard">
                        <FormLabel component="legend">{ __( 'App access' ) }</FormLabel>
                        <FormGroup>
                            <FormControlLabel 
                                label={ __( 'Portal' ) } 
                                control={
                                    <Checkbox 
                                        checked={ userModel?.clients?.includes?.( 'portal-spa' ) || false } 
                                        onChange={ event => onPortalAccessChanged( event, 'portal-spa' ) }
                                    />
                                } 
                            />
                            <FormControlLabel 
                                label={ __( 'QGIS Connector' ) } 
                                control={
                                    <Checkbox 
                                        checked={ userModel?.clients?.includes?.( 'qgis-vlconnector' ) || false } 
                                        onChange={ event => onPortalAccessChanged( event, 'qgis-vlconnector' ) }
                                    />
                                } 
                            />
                        </FormGroup>
                    </FormControl>
                    <br/>
                    { ActionsComponent && <ActionsComponent /> }
                </Col>
            </Row>
        </div>
    )
}