import React, { Fragment, useEffect, useState } from "react"
import { Card, CardBody } from "reactstrap"
import { __ } from "../../app"
import { deleteUser, updateUser, useUser } from "../../services/user-service";
import { useHistory } from 'react-router-dom'
import { Button, CircularProgress } from "@mui/material";
import { isEmailValid, isPasswordStrong } from "../../utils/validate";
import { ContinueCancelDialog } from "../../components/continue-cancel-dialog";

import PageHeader from "../../components/page-header";
import UserEditor from "../../components/user-editor";

export default function EditUser( {
    match
} ) {

    const id = match?.params?.id;
    const [ userModel, setUserModel, isUserLoaded ] = useUser( id, { context: 'object' } );

    const history = useHistory();
    var historyState = history.location.state;

    const [ isLoading, setLoading ] = useState( false )
    const [ errors, setErrors ] = useState( [] )
    const [ alerts, setAlerts ] = useState( 
        historyState?.created ? [
            {
                severity: 'success',
                title: __( 'User created successfully.' )
            }
        ] : 
        []
    )

    useEffect( () => {
        console.log( 'Model Updated', userModel )
    }, [ userModel ] )

    
    async function onDeleteUserClicked() {
        if( isLoading ) return;
        setLoading( true );
        setErrors( [] );
        setAlerts( [] )

        var response = await deleteUser( userModel )
        setLoading( false )

        if( response?.error && response?.message ) {
            setErrors( [ response.message ] );
            setAlerts( [] );
            return;
        }

        history.push( '/user-management/', { userDeleted: true });

    }

    async function onSaveUserClicked() {
        
        if( isLoading ) return;
        setLoading( true );
        setErrors( [] );
        setAlerts( [] )

        var errors = validateUser();
        if( errors.length ) {
            setErrors( errors );
            setAlerts( [] );
            setLoading( false );
            return;
        }

        var response = await updateUser( userModel )
        setLoading( false )

        if( response?.error && response?.message ) {
            setErrors( [ response.message ] );
            setAlerts( [] );
            return;
        }

        if( !response?.data || !response?.data?.id ) {
            setErrors( [ 'An unknown error occured, check console for more details.' ] );
            setAlerts( [] );
            return;
        }

        setUserModel( response.data );
        setErrors( [] );
        setAlerts( [
            {
                severity: 'success',
                title: __( 'User saved successfully.' )
            }
        ] )
    }

    function validateUser() {
        var errors = [];

        if( !userModel?.email ) {
            errors.push( __( "Email cannot be empty." ) );
        }

        else if( !isEmailValid( userModel?.email ) ) {
            errors.push( __( "Email must be a valid email address." ) );
        }

        if( userModel?.password && !isPasswordStrong( userModel?.password ) ) {
            errors.push( __( "Password must contain at least 8 characters and a mixture of lower and upper case characters." ) );
        }

        if( !userModel?.roleId ) {
            errors.push( __( "Please select a user role." ) );
        }


        return errors;
    }
    

    function ActionsComponent() {
        const [ isDeleteDialogOpen, setDeleteDialogOpen ] = useState( false )

        return (
            <Fragment>


                <ContinueCancelDialog 
                    isOpen={ isDeleteDialogOpen }
                    setOpen={ open => setDeleteDialogOpen( open ) }
                    title={ __( 'Are you sure you want to delete this user?' ) }
                    content={ __( 'This operation cannot be undone.' ) }
                    onContinueClicked={ () => onDeleteUserClicked() }
                />


                <Button 
                    variant="contained"
                    onClick={ () => onSaveUserClicked() }
                >
                    { __( 'Save' ) }
                </Button>

                <Button 
                    variant="contained"
                    onClick={ () => setDeleteDialogOpen( true ) }
                >
                    { __( 'Delete' ) }
                </Button>
                        
                { isLoading && (
                    <CircularProgress />
                ) }
            </Fragment>
        )
    }


    return (
        <div className="views_view views__add-order">

        
            <PageHeader
                header={ 'User: ' + ( userModel?.email || id )  }
                breadcrumbs={ [ 
                    { 
                        to: '/user-management',
                        label: __( 'Users' )
                    },
                    { 
                        to: '/user-management/edit-user/' + id,
                        label: userModel.email || id,
                        active: true
                    } 
                ] }
                alerts={ errors?.length > 0 ? [ 
                    ... alerts,
                    {
                        severity: 'error',
                        title: __( 'Error(s) while updating user.' ),
                        withList: errors
                    }
                ] : alerts }
            />  

            <Card>
                <CardBody>
            
                    <UserEditor
                        mode='edit'
                        userModel={ userModel }
                        onUserModelChanged={ userModel => {
                            setUserModel( userModel ) 
                        } }
                        ActionsComponent={ ActionsComponent }
                    />
                </CardBody>
            </Card>
        </div>
    )
}