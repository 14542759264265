import React, { Fragment, useEffect, useState } from "react"
import { Card, CardBody } from "reactstrap"
import { __ } from "../../app"
import { updateSite, useSite } from "../../services/site-service"
import { useHistory } from 'react-router-dom'
import { Button, CircularProgress } from "@mui/material";

import PageHeader from "../../components/page-header"
import SiteEditor from "../../components/site-editor"


export default function EditSite( {
    match
} ) {
    const id = match?.params?.id;
    const [ siteModel, setSiteModel, isSiteModelLoading ] = useSite( id, { context: 'object' } )

    const history = useHistory();
    var historyState = history.location.state;

    const [ isLoading, setLoading ] = useState( false )
    const [ errors, setErrors ] = useState( [] )
    const [ alerts, setAlerts ] = useState( 
        historyState?.created ? [
            {
                severity: 'success',
                title: __( 'Site created successfully.' )
            }
        ] : 
        []
    )


    useEffect( () => {
        console.log( 'Model Updated', siteModel )
    }, [ siteModel ] )

    async function onSaveSiteClicked() {

        if( isLoading ) return;
        setLoading( true );
        setErrors( [] );
        setAlerts( [] )

        var errors = validateSite();
        if( errors.length ) {
            setErrors( errors );
            setAlerts( [] );
            setLoading( false );
            return;
        }

        var response = await updateSite( siteModel )
        setLoading( false )

        if( response?.error && response?.message ) {
            setErrors( [ response.message ] );
            setAlerts( [] );
            return;
        }

        if( !response?.data || !response?.data?.id ) {
            setErrors( [ 'An unknown error occured, check console for more details.' ] );
            setAlerts( [] );
            return;
        }

        setSiteModel( response.data );
        setErrors( [] );
        setAlerts( [
            {
                severity: 'success',
                title: __( 'Site saved successfully.' )
            }
        ] )
    }

    function validateSite() {
        var errors = [];

        if( !siteModel?.reference ) {
            errors.push( 'Site reference cannot be empty.' )
        }

        if( !siteModel?.siteName ) {
            errors.push( 'Site name cannot be empty.' )
        }

        if( !siteModel?.siteOwner?.id ) {
            errors.push( 'Site owner cannot be empty.' );
        }
        
        if( !siteModel?.address?.city ) {
            errors.push( "City cannot be empty." )
        }

        if( !siteModel?.address?.country ) {
            errors.push( "Country cannot be empty." )
        }

        if( !siteModel?.address?.line1 ) {
            errors.push( "Address line 1 cannot be empty." )
        }

        if( !siteModel?.address?.postCode ) {
            errors.push( "Postcode cannot be empty." )
        }

        if( !siteModel?.address?.state ) {
            errors.push( "State cannot be empty." )
        }
        
        
        return errors;
    }


    function ActionsComponent() {

        return (
            <Fragment>
                <Button 
                    variant="contained"
                    onClick={ () => onSaveSiteClicked() }
                    disabled={ isSiteModelLoading }
                >
                    { __( 'Save' ) }
                </Button>
                        
                { isLoading && (
                    <CircularProgress />
                ) }
            </Fragment>
        )
    }

    return (
        <div className="views_view views__edit-site">
        
            <PageHeader
                header={ 'Site: ' + ( siteModel?.reference || id ) }
                breadcrumbs={ [ 
                    { 
                        to: '/sites',
                        label: __( 'Sites' )
                    },
                    { 
                        to: '/sites/id/' + id,
                        label: siteModel?.reference || id ,
                        active: true
                    } 
                ] }
                alerts={ errors?.length > 0 ? [ 
                    ... alerts,
                    {
                        severity: 'error',
                        title: __( 'Error(s) while updating site.' ),
                        withList: errors
                    }
                ] : alerts }
            />  

            <Card>
                <CardBody>
            
                    <SiteEditor 
                        siteModel={ siteModel }
                        isSiteModelLoading={ isSiteModelLoading }
                        onSiteModelChanged={ siteModel => {
                            setSiteModel( siteModel ) 
                        } }
                        ActionsComponent={ ActionsComponent }
                    />
                </CardBody>
            </Card>
        </div>
    )
}