import React, { Fragment, useEffect, useState } from "react"

import { Card, CardBody } from "reactstrap"
import { __ } from "../../app"
import { createUser, useUser } from "../../services/user-service";
import { useHistory } from 'react-router-dom'
import { Button, CircularProgress } from "@mui/material";
import { isEmailValid, isPasswordStrong } from "../../utils/validate";

import PageHeader from "../../components/page-header";
import UserEditor from "../../components/user-editor";

export default function AddUser() {

    const [ userModel, setUserModel, isUserLoaded ] = useUser( false, { context: 'object' } );
    const [ isLoading, setLoading ] = useState( false );
    const [ errors, setErrors ] = useState( [] );
    const history = useHistory();

    useEffect( () => {
        console.log( 'Model Updated', userModel )
    }, [ userModel ] )

    async function onSaveUserClicked() {
        if( isLoading ) return;
        setLoading( true );
        setErrors( [] )

        var errors = validateUser();
        if( errors.length ) {
            setErrors( errors )
            setLoading( false )
            return;
        }

        var response = await createUser( userModel );
        setLoading( false )

        if( response?.error && response?.message ) {
            setErrors( [ response.message ] );
            return;
        }

        if( !response?.data || !response?.data?.id ) {
            setErrors( [ 'An unknown error occured, check console for more details.' ] );
            return;
        }

        var userId = response.data.id;
        history.push( '/user-management/edit-user/' + userId, { created: true });
    }

    function validateUser() {
        var errors = [];

        if( !userModel?.email ) {
            errors.push( __( "Email cannot be empty." ) );
        }

        else if( !isEmailValid( userModel?.email ) ) {
            errors.push( __( "Email must be a valid email address." ) );
        }

        if( !userModel?.password ) {
            errors.push( __( "Password cannot be empty." ) );
        }
        else if( !isPasswordStrong( userModel?.password ) ) {
            errors.push( __( "Password must contain at least 8 characters and a mixture of lower and upper case characters." ) );
        }

        if( !userModel?.roleId ) {
            errors.push( __( "Please select a user role." ) );
        }

        return errors;
    }

    

    function ActionsComponent() {
        return (
            <Fragment>
                <Button 
                    variant="contained"
                    onClick={ () => onSaveUserClicked() }
                >
                    { __( 'Save' ) }
                </Button>
                        
                { isLoading && (
                    <CircularProgress />
                ) }
            </Fragment>
        )
    }


    return (
        <div className="views_view views__add-user">

            <PageHeader
                header={ __( 'Add User' ) }
                breadcrumbs={ [ 
                    { 
                        to: '/user-management',
                        label: __( 'User Management' ),
                    } ,
                    { 
                        to: '/user-management/add-user',
                        label: __( 'Add User' ),
                        active: true
                    } 
                ] }
                alerts={ errors?.length > 0 ? [ 
                    {
                        severity: 'error',
                        title: __( 'Error(s) while creating user.' ),
                        withList: errors
                    }
                ] : [] }
            />

            <Card>
                <CardBody>
            
                    <UserEditor 
                        mode="add"
                        userModel={ userModel }
                        onUserModelChanged={ userModel => {
                            setUserModel( userModel ) 
                        } }
                        ActionsComponent={ ActionsComponent }
                    />
                </CardBody>
            </Card>
        </div>
    )
}