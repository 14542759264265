

// function urlParseVal( val ) {
    
//     if( val === null ) {
//         return 'null-';
//     }

//     if( typeof val === 'object' || Array.isArray( val ) ) {
//         // stringify arrays & objects
//         return "o-" + JSON.stringify(  val );
//     }

//     // return value as is since it's not an object or array
//     return "v-" + val;
// }

// function urlDecodeVal( val ) {
//     if( val?.startsWith('null-') ) {
//         return null;
//     }

//     // is object type, json-decode
//     if( val?.startsWith( 'o-' ) ) {
//         return JSON.parse( val.substr(2) );
//     }

//     if( val?.startsWith( 'v-' ) ) {
//         return val.substr(2);
//     }

//     // Unknown... 
//     return val;
// }

/**
 * Encodes a state object into a url with tidy-ish url structure. 
 * o- Object type (requires json decoding)
 * v- Value type (does not)
 * null- null
 * 
 * @param {*} 
 * @returns encoded data
 */
export const urlEncode = ( { data, url = null, ignoreEmpty = true } ) => {

    if( url === null ) {
        url = window.location.pathname;
    }

    Object.keys(data).map(function( key ) {
        data[key] = JSON.stringify( data[key] )

        if( ignoreEmpty && !data[key] ) {
            delete !data[key];
        }
    } )

    if( Object.keys(data).length == 0 ) {
        return url || '';
    }

    var params = new URLSearchParams( data ).toString();
    return url ? url + "?" + params : params;
};

/**
 * Decodes a state object from url params
 * o- Object type (requires json decoding)
 * v- Value type (does not)
 * null- null
 * 
 * @param {*} data 
 * @returns decoded data
 */
export const urlDecode = data => {
    if( !data ) {
        return;
    }

    data = Object.fromEntries( new URLSearchParams( data ) );

    if( !data ) {
        return;
    }

    Object.keys(data).map(function( key ) {
        data[key] = JSON.parse(  data[key] );
    } )

    return data;
}

export default {
    urlEncode,
    urlDecode
}
