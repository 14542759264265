import axios from 'axios';
import { __ } from "../app"
import { apiHost, authHost } from "../config"
import { fetchResultsByTerm, useFetchResultByUrl } from './helpers/autocomplete-service';

export async function autocomplete( term, { abortController = AbortController } ) {
    var url = apiHost + '/api/admin/user/role/autocomplete';
    return await fetchResultsByTerm( url, term, { abortController } )
}

export function useRole( roleId = false, { context = 'autocomplete' } = {} ) {


    if( context != 'autocomplete' ) {
        throw 'context not implemented';
    }
    
    var fetchParams = {
        getId: r => r?.roleId,
        getUrl: r => apiHost + '/api/admin/user/role/autocomplete/' + parseInt( r?.roleId ),
        initialState: {
            roleId
        }
    }

    return useFetchResultByUrl( fetchParams );
}


export async function createRole( roleModel ) {
    
    try {
        const response = await axios.post( apiHost + '/api/admin/user/role',  { 
            ...roleModel,
            id: 0
        } );

        return response;
    }
    catch( e ) {
        return {
            error: true,
            message: e?.response?.data
        }
    }
}



export async function updateRole( roleModel ) {

    try {
        const response = await axios.put( apiHost + '/api/admin/user/role/' + roleModel?.id, roleModel )
        return response;
    }
    catch( e ) {
        return {
            error: true,
            message: e?.response?.data
        }
    }
}