import { Button, formControlClasses, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { defaultFieldVariant, __ } from '../../app'
import MuiPhoneNumber from 'material-ui-phone-number'
import AddressFields from '../address-fields/address-fields'
import { useVendor } from '../../services/vendor-service'
import AutocompleteVendor from '../autocomplete/autocomplete-vendor'
import { useSelector } from 'react-redux'



export default function ResellerEditor( { resellerModel, isResellerModelLoading, onResellerModelChanged, ActionsComponent = false } ) {

    const [ vendor, setVendor, isVendorLoading ] = useVendor( resellerModel?.vendor?.id || false, { context: 'autocomplete' }  )
    const role = useSelector(state => state.auth.user?.profile?.role)
    const isEditsDisabled = role != 'AdminUser';
    
    function onEmailChanged( event ) {
        onResellerModelChanged?.( {
            ...resellerModel,
            email: event.target.value
        } )
    }

    function onContactNameChanged( event ) {
        onResellerModelChanged?.( {
            ...resellerModel,
            contactName: event.target.value
        } )
    }

    function onCompanyNameChanged( event ) {
        onResellerModelChanged?.( {
            ...resellerModel,
            companyName: event.target.value
        } )
    }

    function onPhoneChanged( value ) {
        onResellerModelChanged?.( {
            ...resellerModel,
            phone: value
        } )
    }

    function onAddressChanged( address ) {
        onResellerModelChanged?.( {
            ...resellerModel,
            address: {
                ... address
            }
        } )
    }

    function onVendorChanged( vendor ) {
        onResellerModelChanged?.( {
            ...resellerModel,
            vendor: {
                id: vendor.vendorId
            }
        } )
        setVendor( vendor );
    }
    

    return (
        <div className="components__reseller-editor">
            <Row>
                <Col className='mb-5'>
                    <h4 className='mb-3'>{__( "Reseller Details" )}</h4>

                    { resellerModel?.id > 0 &&
                        <TextField
                            className="w-100 mb-3" 
                            label={ __( "Reseller Id" ) }
                            disabled={ true }
                            variant={ defaultFieldVariant } 
                            value={ isResellerModelLoading ? __( 'Loading...' ) : resellerModel.id }
                            onChange={ e => false }
                        />
                    }    

                    { resellerModel?.accountId > 0 &&
                        <TextField
                            className="w-100 mb-3" 
                            label={ __( "Account Id" ) }
                            disabled={ true }
                            variant={ defaultFieldVariant } 
                            value={ isResellerModelLoading ? __( 'Loading...' ) : resellerModel.accountid }
                            onChange={ e => false }
                        />
                    } 

                    <AutocompleteVendor
                        vendor={ vendor }
                        onVendorChanged={ vendor => onVendorChanged( vendor ) }
                        isVendorLoading={ isVendorLoading }
                        disabled={ resellerModel?.id > 0 || isEditsDisabled }
                        wrapperProps={{
                            className: "mb-3"
                        }}
                    />

                    <TextField
                        className="w-100 mb-3" 
                        label={ __( "Email" ) }
                        variant={ defaultFieldVariant } 
                        disabled={ isResellerModelLoading || isEditsDisabled }
                        value={ isResellerModelLoading ? __( 'Loading...' ) : ( resellerModel?.email || '' ) }
                        onChange={ e => onEmailChanged( e ) }
                    />

                    <TextField
                        className="w-100 mb-3" 
                        label={ __( "Contact Name" ) }
                        variant={ defaultFieldVariant } 
                        disabled={ isResellerModelLoading || isEditsDisabled }
                        value={ isResellerModelLoading ? __( 'Loading...' ) : ( resellerModel?.contactName || '' ) }
                        onChange={ e => onContactNameChanged( e ) }
                    />

                    <TextField
                        className="w-100 mb-3" 
                        label={ __( "Company Name" ) }
                        variant={ defaultFieldVariant } 
                        disabled={ isResellerModelLoading || isEditsDisabled }
                        value={ isResellerModelLoading ? __( 'Loading...' ) : ( resellerModel?.companyName || '' ) }
                        onChange={ e => onCompanyNameChanged( e ) }
                    />

                    <MuiPhoneNumber
                        defaultCountry='au'
                        label={ __( "Phone" ) }
                        className="w-100 mb-3" 
                        variant={ defaultFieldVariant } 
                        disabled={ isResellerModelLoading || isEditsDisabled }
                        value={ resellerModel?.phone }
                        onChange={ value => onPhoneChanged( value ) }
                    />

                    <AddressFields
                        address={ resellerModel?.address || {} }
                        onChange={ address => onAddressChanged( address ) }
                        isAddressLoading={ isResellerModelLoading }
                        disabled={ isResellerModelLoading || isEditsDisabled  }
                    />

                    { !isEditsDisabled && ActionsComponent && <ActionsComponent /> }
                </Col>
            </Row>
        </div>
    );
}