import React, { Component, useState, useRef, useEffect, createRef } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Label, Row } from "reactstrap";
import { __ } from '../../app'

import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import geojsonExtent from '@mapbox/geojson-extent'

mapboxgl.accessToken = 'pk.eyJ1Ijoibm9rbm9rc3R1ZGlvcyIsImEiOiJja3VtMjVraHoweWJnMnBtYnl2ODRkeG00In0.L2LUES_2So72GUGWtSDYog';

export default function PolygonEditor( { polygon= {}, onPolygonChange = false, disabled = false } ) {

    var [ valueText, setValueText ] = useState( JSON.stringify( polygon, null, 2 ) )
    var [ valid, setValid ] = useState( true );

    // Setup the map
    var map = useRef( null );
    var mapContainer = createRef();
    
    // update value when value text changes
    useEffect( () => {

        // Try parse
        var newValue = false;
        try {
            newValue = JSON.parse( valueText );
        }
        catch( e ) {}

        // Update base valuue
        if( newValue && JSON.stringify( newValue ) != JSON.stringify( polygon ) ) {
            onPolygonChange?.( newValue  );
        }

        // Validation
        setValid( newValue != false );

    }, [ valueText, valid, setValid, polygon, onPolygonChange ] )


    // Setup the Map
    useEffect( () => {


        const feature = polygon;

        var extent = false;
        try {
            extent = geojsonExtent( feature )
        } catch( e ) {}

        if( !extent ) {
            return; // invalid polygon
        }

        const center = [
            ( extent[0] + extent[2] ) / 2,
            ( extent[1] + extent[3] ) / 2
        ];
    
        const update = ( updateSource = true ) => {
            var theMap = map.current;

            if( updateSource ) {
                const source = theMap.getSource( 'zones' );
                source.setData( feature );
            }


            theMap.flyTo( {
                center: center,
                essential: true,
                zoom: 14,
                curve: 1,
                // pitch: 60,
                // bearing: 0,
                speed: 1
            } );
        }

        // Get the map
        if( map.current  ) {
            update( true );
            return;
        }

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/satellite-v9',
            zoom: 10,
            center
        })
        
        var theMap = map.current;
        theMap.on( 'load', () => {

            theMap.addSource('mapbox-dem', {
                'type': 'raster-dem',
                'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
                'tileSize': 512,
                'maxzoom': 14
            })

            theMap.setTerrain( { 'source': 'mapbox-dem', 'exaggeration': 1.5 } )

            theMap.addSource( 'zones', {
                type: 'geojson',
                data: feature
            } )

            theMap.addLayer({
                id: 'zones-fill',
                type: 'fill',
                source: 'zones',
                layout: {},
                paint: {
                    'fill-color': '#49641b', // green color fill
                    'fill-opacity': 0.7
                }
            })

            // Add a black outline around the polygon.
            theMap.addLayer({
                'id': 'zones-outline',
                'type': 'line',
                'source': 'zones',
                'layout': {},
                'paint': {
                    'line-color': '#49641b',
                    'line-width': 3
                }
            })

            update( false )
        } )

    }, [ polygon ] )

    return (
        <FormGroup>
            <div style={{ minHeight: '600px' }} ref={ mapContainer } className="map-container" />
            <h4 htmlFor="inputGeoJson" className="mt-4 mb-2">{ __( 'Polygon Source (GeoJson)' ) }</h4>

            <Editor
                value={ valueText }
                onValueChange={ valueText => setValueText( valueText )}
                highlight={ code => highlight( code, languages.js ) }
                padding={10}
                className="border mb-3"
                style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 12
                }}
                disabled={ disabled }
            />
        </FormGroup>
    )
} 
