import React, { useEffect } from 'react'
import { signinRedirectCallback } from '../services/user-service'
import { useHistory } from 'react-router-dom'

function SigninOidc() {
  const history = useHistory()
  const requestUrl = history.location;

  useEffect(() => {
    async function signinAsync() {
      var user = await signinRedirectCallback()

      history.push( user?.state?.signInRedirect || '/' );
    }
    signinAsync()
  }, [history])

  return (
    <div>
      Redirecting...
    </div>
  )
}

export default SigninOidc
