import axios from 'axios';
import { __ } from "../app"
import { apiHost, authHost } from "../config"
import {  fetchResultsByTerm, useFetchResultByUrl } from './helpers/autocomplete-service';

export async function autocomplete( term, { abortController = AbortController } ) {
    var url = apiHost + '/api/admin/zone/autocomplete';
    return await fetchResultsByTerm( url, term, { abortController } )
}

export function useZone( zoneId = false, { context = 'autocomplete' } = {} ) {

    var fetchParams = {
        getId: r => r?.id,
        getUrl: r => apiHost + '/api/admin/zone/' + parseInt( r?.id ),
        initialState: {
            id: zoneId
        }
    } 

    if( context == 'autocomplete' ) {
        fetchParams = {
            getId: r => r?.zoneId,
            getUrl: r => apiHost + '/api/admin/zone/autocomplete/' + parseInt( r?.zoneId ),
            initialState: {
                zoneId: zoneId
            }
        }
    }

    return useFetchResultByUrl( fetchParams );
}


export async function createZone( zoneModel ) {
    
    try {
        const response = await axios.post( apiHost + '/api/admin/zone', zoneModel );
        return response;
    }
    catch( e ) {
        return {
            error: true,
            message: e?.response?.data
        }
    }
}

export async function updateZone( zoneModel ) {

    try {
        const response = await axios.put( apiHost + '/api/admin/zone/' + zoneModel?.id, zoneModel )
        return response;
    }
    catch( e ) {
        return {
            error: true,
            message: e?.response?.data
        }
    }
}

export async function publishZone( zoneModel ) {

    try {
        const response = await axios.put( apiHost + '/api/admin/zone/publish/' + zoneModel?.id, zoneModel )
        return response;
    }
    catch( e ) {
        return {
            error: true,
            message: e?.response?.data
        }
    }

}

export async function retireZone( zoneModel ) {

    try {
        const response = await axios.put( apiHost + '/api/admin/zone/retire/' + zoneModel?.id, zoneModel )
        return response;
    }
    catch( e ) {
        return {
            error: true,
            message: e?.response?.data
        }
    }

}

export async function registerUnits( zoneModel, polygons ) {
    try {
        const response = await axios.post( apiHost + '/api/admin/zone/addunits/' + zoneModel?.id, polygons )
        return response;
    }
    catch( e ) {
        return {
            error: true,
            message: e?.response?.data
        }
    }
}