import React, { Fragment, useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Container } from "reactstrap"
import { __ } from "../../app"
import { createSiteOwner, useSiteOwner } from "../../services/site-owner-service";
import SiteOwnerEditor from "../../components/site-owner-editor";
import { useHistory } from 'react-router-dom'
import { Button, CircularProgress } from "@mui/material";
import PageHeader from "../../components/page-header";

export default function AddSiteOwner() {

    const [ ownerModel, setOwnerModel, isOwnerLoaded ] = useSiteOwner();
    const [ isLoading, setLoading ] = useState( false );
    const [ errors, setErrors ] = useState( [] );
    const history = useHistory();

    useEffect( () => {
        console.log( 'Model Updated', ownerModel )
    }, [ ownerModel ] )

    async function onSaveOwnerClicked() {
        if( isLoading ) return;
        setLoading( true );
        setErrors( [] )

        var errors = validateSiteOwner();
        if( errors.length ) {
            setErrors( errors )
            setLoading( false )
            return;
        }

        var response = await createSiteOwner( ownerModel );
        setLoading( false )

        if( response?.error && response?.message ) {
            setErrors( [ response.message ] );
            return;
        }

        if( !response?.data || !response?.data?.id ) {
            setErrors( [ 'An unknown error occured, check console for more details.' ] );
            return;
        }

        var ownerId = response.data.id;
        history.push( '/site-owners/id/' + ownerId, { created: true });
    }

    function validateSiteOwner() {
        var errors = [];

        if( !ownerModel?.companyName ) {
            errors.push( "Company name cannot be empty." );
        }

        if( !ownerModel?.contactName ) {
            errors.push( "Contact name cannot be empty." )
        }

        if( !ownerModel?.email ) {
            errors.push( "Email cannot be empty." )
        }

        if( !ownerModel?.phone ) {
            errors.push( "Phone cannot be empty." )
        }

        if( !ownerModel?.address?.city ) {
            errors.push( "City cannot be empty." )
        }

        if( !ownerModel?.address?.country ) {
            errors.push( "Country cannot be empty." )
        }

        if( !ownerModel?.address?.line1 ) {
            errors.push( "Address line 1 cannot be empty." )
        }

        if( !ownerModel?.address?.postCode ) {
            errors.push( "Postcode cannot be empty." )
        }

        if( !ownerModel?.address?.state ) {
            errors.push( "State cannot be empty." )
        }

        return errors;
    }
    

    function ActionsComponent() {
        return (
            <Fragment>
                <Button 
                    variant="contained"
                    onClick={ () => onSaveOwnerClicked() }
                >
                    { __( 'Save' ) }
                </Button>
                        
                { isLoading && (
                    <CircularProgress />
                ) }
            </Fragment>
        )
    }

    return (
        <div className="views_view views__add-order">

            <PageHeader
                header={ __( 'Add Site Owner' ) }
                breadcrumbs={ [ 
                    { 
                        to: '/site-owners',
                        label: __( 'Site Owners' )
                    } ,
                    { 
                        to: '/site-owners/add',
                        label: __( 'Add Site Owner' ),
                        active: true
                    } 
                ] }
                alerts={ errors?.length > 0 ? [ 
                    {
                        severity: 'error',
                        title: __( 'Error(s) while creating site-owner.' ),
                        withList: errors
                    }
                ] : [] }
            />

            <Card>
                <CardBody>
            
                    <SiteOwnerEditor 
                        ownerModel={ ownerModel }
                        onOwnerModelChanged={ siteOwnerModel => {
                            setOwnerModel( siteOwnerModel ) 
                        } }
                        ActionsComponent={ ActionsComponent }
                    />
                </CardBody>
            </Card>
        </div>
    )
}