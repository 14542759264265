import React, { Fragment, useEffect, useState } from "react"
import { Card, CardBody } from "reactstrap"

import { __ } from "../../app"
import { createVendor, useVendor } from "../../services/vendor-service"
import { useHistory } from 'react-router-dom'
import { Button, CircularProgress } from "@mui/material";

import PageHeader from "../../components/page-header"
import VendorEditor from "../../components/vendor-editor"

export default function AddVendor( {
} ) {
   
    const [ vendorModel, setVendorModel, isVendorModelLoading ] = useVendor( false, { context: 'object' } )
    const [ isLoading, setLoading ] = useState( false );
    const [ errors, setErrors ] = useState( [] );

    const history = useHistory();

    useEffect( () => {
        console.log( 'Model Updated', vendorModel )
    }, [ vendorModel ] )

    
    async function onSaveVendorClicked() {
        if( isLoading ) return;
        setLoading( true );
        setErrors( [] )

        var errors = validateVendor();
        if( errors.length ) {
            setErrors( errors );
            setLoading( false );
            return;
        }

        var response = await createVendor( vendorModel )
        setLoading( false )

        if( response?.error && response?.message ) {
            setErrors( [ response.message ] );
            return;
        }

        if( !response?.data || !response?.data?.id ) {
            setErrors( [ 'An unknown error occured, check console for more details.' ] );
            return;
        }

        // if( !response.data.successful ) {
        //     setErrors( [ response.data.errorMessage ] );
        //     return;
        // }

        var vendorId = response.data.id;
        history.push( '/vendors/id/' + vendorId, { created: true });
    }
    
    function validateVendor() {
        var errors = [];

        if( !vendorModel?.name ) {
            errors.push( "Name cannot be empty." )
        }

        if( !vendorModel?.email ) {
            errors.push( "Email cannot be empty." )
        }

        if( !vendorModel?.contactName ) {
            errors.push( "Contact name cannot be empty." )
        }

        if( !vendorModel?.companyName ) {
            errors.push( "Company name cannot be empty." );
        }

        if( !vendorModel?.phone ) {
            errors.push( "Phone cannot be empty." )
        }

        if( !vendorModel?.address?.city ) {
            errors.push( "Address city cannot be empty." )
        }

        if( !vendorModel?.address?.country ) {
            errors.push( "Address country cannot be empty." )
        }

        if( !vendorModel?.address?.line1 ) {
            errors.push( "Address Line 1 cannot be empty." )
        }

        if( !vendorModel?.address?.postCode ) {
            errors.push( "Address postcode cannot be empty." )
        }

        if( !vendorModel?.address?.state ) {
            errors.push( "Address state cannot be empty." )
        }

        return errors;
    }
    function ActionsComponent() {
        return (
            <Fragment>
                <Button 
                    variant="contained"
                    onClick={ () => onSaveVendorClicked() }
                >
                    { __( 'Save' ) }
                </Button>
                        
                { isLoading && (
                    <CircularProgress />
                ) }
            </Fragment>
        )
    }



    return (
        
        <div className="views_view views__add-vendor">


            <PageHeader
                header={ __( 'Add Vendor' ) }
                breadcrumbs={ [ 
                    { 
                        to: '/vendors',
                        label: __( 'Vendors' )
                    } ,
                    { 
                        to: '/vendors/add',
                        label: __( 'Add Vendor' ),
                        active: true
                    } 
                ] }
                alerts={ errors?.length > 0 ? [ 
                    {
                        severity: 'error',
                        title: __( 'Error(s) while creating vendor.' ),
                        withList: errors
                    }
                ] : [] }
            />

            <Card>
                <CardBody>
            
                    <VendorEditor 
                        vendorModel={ vendorModel }
                        isVendorModelLoading={ isVendorModelLoading }
                        onVendorModelChanged={ vendorModel => {
                            setVendorModel( vendorModel ) 
                        } }
                        ActionsComponent={ ActionsComponent }
                    />
                </CardBody>
            </Card>
        </div>
    )
}