import axios from 'axios';
import { UserManager } from 'oidc-client';
import { storeUserError, storeUser } from '../actions/auth-actions'
import { __,  appEntryLocation } from "../app"
import { appHost, apiHost, authHost } from "../config"
import {  fetchResultsByTerm, useFetchResultByUrl } from './helpers/autocomplete-service';



export function useUser( userId = false, { context = 'object' } = {} ) {
    var fetchParams = {
        getId: r => r?.id,
        getUrl: r => apiHost + '/api/admin/user/' + parseInt( r?.id ),
        initialState: {
            id: userId
        }
    } 

    return useFetchResultByUrl( fetchParams );
}


export async function createUser( userModel ) {
    
    try {
        const response = await axios.post( apiHost + '/api/admin/user',  { 
            ...userModel,
            id: 0
        } );

        return response;
    }
    catch( e ) {
        return {
            error: true,
            message: e?.response?.data
        }
    }
}

export async function updateUser( userModel ) {

    try {
        const response = await axios.put( apiHost + '/api/admin/user', userModel )
        return response;
    }
    catch( e ) {
        return {
            error: true,
            message: e?.response?.data
        }
    }
}

export async function deleteUser( userModel ) {
    try {
        const response = await axios.delete( apiHost + '/api/admin/user/' + userModel.id );
        return response;
    }
    catch( e ) {
        return {
            error: true,
            message: e?.response?.data
        }
    }
}




const config = {
    authority: authHost,
    client_id: "portal-spa",
    redirect_uri: appHost + "/signin-oidc",
    response_type: "id_token token",
    scope: "openid profile admin.api",
    post_logout_redirect_uri: appHost + "/signout-oidc",
    automaticSilentRenew: true,
    silent_redirect_uri: appHost + '/silent-refresh.html'
};

const userManager = new UserManager(config)
console.log( 'Using config', config )

export async function loadUserFromStorage(store) {
    try {
        let user = await userManager.getUser()
        if (!user) { 
            return store.dispatch(storeUserError()) 
        }

        store.dispatch(storeUser(user))
    } catch (e) {
        console.error(`User not found: ${e}`)
        store.dispatch(storeUserError())
    }
}
export function signinRedirect( ) {
    return userManager.signinRedirect({ state: { 
        signInRedirect: appEntryLocation.pathname + appEntryLocation.search 
    } })
}

export function signinRedirectCallback() {
    return userManager.signinRedirectCallback()
}

export function signoutRedirect() {
    userManager.clearStaleState()
    userManager.removeUser()
    return userManager.signoutRedirect()
}

export function signoutRedirectCallback() {
    userManager.clearStaleState()
    userManager.removeUser()
    return userManager.signoutRedirectCallback()
}

export default userManager