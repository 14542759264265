
import AjaxAutocomplete from './index'
import { __ } from "../../app"
import { autocompleteZonesWithBalance } from "../../services/account-service";
import { Box } from '@mui/material';

export default function AutocompleteZoneForSender( {
    zone,
    onZoneChanged,
    isZoneLoading,
    senderAccountId,
    siteId,
    label = __("From Zone"),
    wrapperProps = {},
    readonly = false
} ) {


    // Assign component classname
    if( !wrapperProps?.className ) {
        wrapperProps.className = 'components__autocomplete-zone-for-sender';
    } else {
        wrapperProps.className = 'components__autocomplete-zone-for-sender ' + wrapperProps.className;
    }
 
    // Render
    return (
        <div { ...wrapperProps }>

            {/* Render primary component when loaded */}
           <AjaxAutocomplete
                label={ label }
                value={ zone }
                onValueChanged={ zone => onZoneChanged?.( { ...zone } ) }
                isValueLoading={ isZoneLoading }
                allowEmptyTerm={ true }
                readonly={ readonly }
                getAutocompleteResults={ 
                    async ( term, abortController ) => await autocompleteZonesWithBalance( senderAccountId, term, { 
                        siteId,
                        abortController 
                    } ) 
                }
                
                autocompleteProps={{
                    renderOption: (props, option) => {
                        return (
                            <li {...props}>
                                <Box
                                    sx={{
                                        flexGrow: 1
                                    }}
                                >
                                    <b>{option.label}</b>
                                    <br />
                                    <span>{ __('Vegetation: ') + option.biome}</span>
                                    <br />
                                    <span>{ __('Units Available: ') + option.unitsAvailable}</span>
                                </Box>
                            </li>
                        )
                    }
                }}
            />

        </div>
    )
}