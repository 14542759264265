import axios from 'axios'

async function datatables( 
    { 
        url, 
        pageNumber = 1, 
        pageSize = 50, 
        sortBy = null, 
        sortingDirection = null, 
        filters = [], 
        queryParams = {}
    }, 
    abortController = undefined 

) {
  
    const response = await axios.post( url, {
        pageNumber,
        pageSize,
        sortBy,
        sortingDirection,
        filters
    }, {
        params: queryParams,
        signal: abortController?.signal
    });

    return response;
}

export { datatables }