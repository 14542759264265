import React, { Fragment, useEffect, useState } from "react"

import { __ } from "../../app"
import { Button, CircularProgress } from "@mui/material"
import { useHistory } from "react-router-dom"
import { Card, CardBody } from "reactstrap"
import { createZone } from "../../services/zone-service"

import PageHeader from "../../components/page-header"
import ZoneEditor from "../../components/zone-editor"

export default function AddZone( {
    
} ) {

    const [ zoneModel, setZoneModel ] = useState( {} );
    const [ isLoading, setLoading ] = useState( false );
    const [ errors, setErrors ] = useState( [] );

    const history = useHistory();

    async function onSaveZoneClicked() {
        if( isLoading ) return;
        setLoading( true );
        setErrors( [] )

        var errors = validateZone();
        if( errors.length ) {
            setErrors( errors );
            setLoading( false );
            return;
        }

        var response = await createZone( zoneModel )
        setLoading( false )

        if( response?.error && response?.message ) {
            setErrors( [ response.message ] );
            return;
        }

        if( !response?.data || !response?.data?.id ) {
            setErrors( [ 'An unknown error occured, check console for more details.' ] );
            return;
        }

        // if( !response.data.successful ) {
        //     setErrors( [ response.data.errorMessage ] );
        //     return;
        // }

        var zoneId = response.data.id;
        history.push( '/zones/id/' + zoneId, { created: true });
    }

    function validateZone() {
        var errors = [];
        if( !zoneModel.reference ) {
            errors.push( 'Zone reference cannot be empty.' );
        }

        if( !zoneModel.creditMultiplier ) {
            errors.push( 'Credit muliplier cannot be empty.' );
        }

        else if( zoneModel.creditMultiplier <= 0 ) {
            errors.push( 'Credit multiplier must be a positive number.' );
        }

        if( !zoneModel.site?.id ) {
            errors.push( 'Site cannot be empty.' );
        }

        if( !zoneModel.biome?.id ) {
            errors.push( 'Vegetation cannot be empty.' );
        }

        if( !zoneModel.polygon?.source || JSON.stringify( zoneModel.polygon.source ) == JSON.stringify( {} ) ) {
            errors.push( 'Polygon source cannot  be empty.' );
        }

        return errors;
    }

    useEffect( () => {
        console.log( 'Model Updated', zoneModel )
    }, [ zoneModel ] )

    function ActionsComponent() {
        return (
            <Fragment>
                <Button 
                    variant="contained"
                    onClick={ () => onSaveZoneClicked() }
                >
                    { __( 'Save Zone Polygon' ) }
                </Button>
                        
                { isLoading && (
                    <CircularProgress />
                ) }
            </Fragment>
        )
    }

    return (

        <div className="views_view views__add-zone">

            <PageHeader
                header={ __( 'Add Zone' ) }
                breadcrumbs={ [ 
                    { 
                        to: '/zones',
                        label: __( 'Zones' )
                    } ,
                    { 
                        to: '/zones/add',
                        label: __( 'Add Zone' ),
                        active: true
                    } 
                ] }
                alerts={ errors?.length > 0 ? [ 
                    {
                        severity: 'error',
                        title: __( 'Error(s) while creating zone.' ),
                        withList: errors
                    }
                ] : [] }
            />

            <Card>
                <CardBody>
            
                    <ZoneEditor 
                        zoneModel={ zoneModel }
                        onZoneModelChanged={ zoneModel => {
                            setZoneModel( zoneModel ) 
                        } }
                        ActionsComponent={ ActionsComponent }
                    />
                </CardBody>
            </Card>
        </div>
    )
}
