let lastId = 0;

export default function newId(prefix='id') {
    lastId++;

    if( !prefix ) {
        return lastId;
    }

    return `${prefix}${lastId}`;
}