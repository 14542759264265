import axios from 'axios';
import { __ } from "../app"
import { apiHost, authHost } from "../config"
import {  fetchResultsByTerm, useFetchResultByUrl } from './helpers/autocomplete-service';


export async function autocomplete( term, { abortController = AbortController } ) {
    var url = apiHost + '/api/admin/unittype/autocomplete';
    return await fetchResultsByTerm( url, term, { abortController } )
}

export function useUnitType( unitTypeId = false, { context = 'autocomplete' } = {} ) {

    if( context != 'autocomplete' ) {
        throw 'context not implemented';
    }
    
    var fetchParams = {
        getId: r => r?.unitTypeId,
        getUrl: r => apiHost + '/api/admin/unittype/autocomplete/' + parseInt( r?.unitTypeId ),
        initialState: {
            unitTypeId: unitTypeId
        }
    }

    return useFetchResultByUrl( fetchParams );
}