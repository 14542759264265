import React, { useState } from "react"
import {  Card, CardBody } from "reactstrap"
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

import { __ } from "../../app"
import { apiHost } from "../../config"
import DataTable from '../../components/datatable'
import { Link, useHistory } from 'react-router-dom'

import PageHeader from "../../components/page-header";

var url = apiHost + "/api/admin/User/datatables";
function getColumns( { history } ) {
    return [
        {
            headerName: __( 'User Id' ),
            field: 'userId',
            headerAlign: 'left',
            align: 'left',
            flex: 1,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/user-management/edit-user/" + row.userId } >{ formattedValue }</Link>
            ),
        },
        {
            headerName: __( 'Email' ),
            field: 'email',
            flex: 3,
        },
        {
            headerName: __( 'Role' ),
            field: 'roles',
            flex: 4,
        },
        {
            field: 'actions',
            headerName: __( 'Actions' ),
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem 
                    onClick={ () => history.push( '/user-management/edit-user/' + params.id )  } 
                    icon={<EditIcon />} 
                    label={ __("View / Edit User") } 
                />,
            ]
        }
    ]
}
export default function ListUsers() {
    const history = useHistory();
    var historyState = history.location.state;


    const columns = getColumns( { history } )

    const [ alerts, setAlerts ] = useState( 
        historyState?.userDeleted ? [
            {
                severity: 'info',
                title: __( 'User has been deleted.' )
            }
        ] : 
        []
    )
    
    return (
        <div className="table_list table__users">

            <PageHeader
                header={ __( 'Users' ) }
                breadcrumbs={ [ 
                    { 
                        to: '/user-management',
                        label: __( 'Users' ),
                        active: true
                    }
                ] }
                alerts={ alerts }
            />
            
            <Card>
                <CardBody>
                    <div style={{ height: 'calc( 100vh - 190px)', width: '100%' }}>
                        <div style={{ display: 'flex', height: '100%' }}>
                            <div style={{ flexGrow: 1 }}>
                                <DataTable 
                                    url={ url }
                                    columns={ columns }
                                    getRowId={ row => row.userId }
                                />
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>

        </div>
    )
}