import React, { Fragment, useEffect, useState } from "react"
import { Card, CardBody } from "reactstrap"
import { __ } from "../../app"
import { cancelOrder, processOrder, refundOrder, useOrder } from '../../services/order-service';
import { useHistory } from 'react-router-dom'
import { Button, CircularProgress } from "@mui/material";
import OrderEditor from "../../components/order-editor";
import PageHeader from "../../components/page-header";
import { ContinueCancelDialog } from "../../components/continue-cancel-dialog";

export default function EditOrder( {
    match
} ) {
    const id = match?.params?.id;
    const [ orderModel, setOrderModel, isOrderModelLoading ] = useOrder( id, { context: 'object' } )

    const history = useHistory();
    var historyState = history.location.state;
    
    const [ isLoading, setLoading ] = useState( false )
    const [ errors, setErrors ] = useState( [] )
    const [ alerts, setAlerts ] = useState( 
        historyState?.created ? [
            {
                severity: 'success',
                title: __( 'Order created successfully.' )
            }
        ] : 
        []
    )

    useEffect( () => {
        console.log( 'Model Updated', orderModel )
    }, [ orderModel ] )

    async function onProcessOrderClicked( ) {

        if( isLoading ) return;
        setLoading( true );
        setErrors( [] );
        setAlerts( [] )

        var response = await processOrder( orderModel );
        setLoading( false );

        if( response?.error && response?.message ) {
            setErrors( [ response.message ] );
            setAlerts( [] );
            return;
        }

        if( !response?.data || !response?.data?.id ) {
            setErrors( [ 'An unknown error occured, check console for more details.' ] );
            setAlerts( [] );
            return;
        }

        setOrderModel( response.data );
        setErrors( [] );
        setAlerts( [
            {
                severity: 'success',
                title: __( 'Order has been processed and is now \'COMPLETED\'.' )
            }
        ] )
    }

    async function onCancelOrderClicked() {

        if( isLoading ) return;
        setLoading( true );
        setErrors( [] );
        setAlerts( [] )

        var response = await cancelOrder( orderModel );
        setLoading( false );

        if( response?.error && response?.message ) {
            setErrors( [ response.message ] );
            setAlerts( [] );
            return;
        }

        if( !response?.data || !response?.data?.id ) {
            setErrors( [ 'An unknown error occured, check console for more details.' ] );
            setAlerts( [] );
            return;
        }

        setOrderModel( response.data );
        setErrors( [] );
        setAlerts( [
            {
                severity: 'info',
                title: __( 'Order has been cancelled. All units have been returned to their original sellers.' )
            }
        ] )
    }

    async function onRefundOrderClicked() {


        if( isLoading ) return;
        setLoading( true );
        setErrors( [] );
        setAlerts( [] )

        var response = await refundOrder( orderModel );
        setLoading( false );

        if( response?.error && response?.message ) {
            setErrors( [ response.message ] );
            setAlerts( [] );
            return;
        }

        if( !response?.data || !response?.data?.id ) {
            setErrors( [ 'An unknown error occured, check console for more details.' ] );
            setAlerts( [] );
            return;
        }

        setOrderModel( response.data );
        setErrors( [] );
        setAlerts( [
            {
                severity: 'info',
                title: __( 'Order has been refunded. All units have been returned to their original sellers.' )
            }
        ] )
    }

    function ActionsComponent() {

        const [ isConfirmRefundOpen, setConfirmRefundOpen ] = useState( false );
        const [ isConfirmCancelOpen, setConfirmCancelOpen ] = useState( false );
        
        if( isOrderModelLoading ) {
            return <CircularProgress />
        }

        return (
            <Fragment>
                { orderModel.status == 'PENDING' && (
                   <Fragment>
                        
                        <Button 
                            variant="outlined"
                            onClick={ () => onProcessOrderClicked() }
                            disabled={ isLoading }
                        >
                            { __( 'Process Order' ) }
                        </Button>
                        &nbsp;

                        <ContinueCancelDialog 
                            isOpen={ isConfirmCancelOpen }
                            setOpen={ open => setConfirmCancelOpen( open ) }
                            title={ __( 'Are you sure you want to cancel this order?' ) }
                            content={ __( 'This operation cannot be undone.' ) }
                            onContinueClicked={ () => onCancelOrderClicked() }
                        />
                        
                        <Button 
                            variant="outlined"
                            onClick={ () => setConfirmCancelOpen(true) }
                            disabled={ isLoading }
                        >
                            { __( 'Cancel Order' ) }
                        </Button>&nbsp;
                
                   </Fragment> 
                ) }
                
                { orderModel.status == 'COMPLETED' && (
                   <Fragment>
                        
                        <ContinueCancelDialog 
                            isOpen={ isConfirmRefundOpen }
                            setOpen={ open => setConfirmRefundOpen( open ) }
                            title={ __( 'Are you sure you want to refund this order?' ) }
                            content={ __( 'This operation cannot be undone.' ) }
                            onContinueClicked={ () => onRefundOrderClicked() }
                        />
                        
                        <Button 
                            variant="outlined"
                            onClick={ () => setConfirmRefundOpen( true ) }
                            disabled={ isLoading }
                        >
                            { __( 'Refund Order' ) }
                        </Button>&nbsp;
                
                   </Fragment> 
                ) }
            </Fragment>
        )
    }

    return (
        <div className="views_view views__edit-order">

            <PageHeader
                header={ 'Order: ' + id }
                breadcrumbs={ [ 
                    { 
                        to: '/order-transfers',
                        label: __( 'Orders' )
                    } ,
                    { 
                        to: '/orders/id/' + id,
                        label: id,
                        active: true
                    } 
                ] }
                alerts={ errors?.length > 0 ? [ 
                    ... alerts,
                    {
                        severity: 'error',
                        title: __( 'Error(s) while updating order.' ),
                        withList: errors
                    }
                ] : alerts }

                SubHeaderComponent={ () => <i>{ __( "Status: " ) + orderModel.status }</i> }
            />
            
            <Card>
                <CardBody>
            
                    <OrderEditor 
                        orderModel={ orderModel }
                        readonly={ true }
                        onOrderModelChanged={ orderModel => {
                            setOrderModel( orderModel ) 
                        } }
                        isOrderModelLoading={ isOrderModelLoading }
                        ActionsComponent={ ActionsComponent }
                    />
                </CardBody>
            </Card>
        </div>
    )
}