import { autocomplete } from "../../services/vendor-service";
import { __ } from "../../app"
import AjaxAutocomplete from './index'

export default function AutocompleteVendor( {
    vendor,
    onVendorChanged,
    isVendorLoading,
    label = __("Vendor"),
    wrapperProps = {},
    disabled = false
} ) {


    // Assign component classname
    if( !wrapperProps?.className ) {
        wrapperProps.className = 'components__vendor-autocomplete';
    } else {
        wrapperProps.className = 'components__vendor-autocomplete ' + wrapperProps.className;
    }
 
    // Render
    return (
        <div { ...wrapperProps }>

            {/* Render primary component when loaded */}
           <AjaxAutocomplete
                label={ label }
                value={ vendor }
                onValueChanged={ site => onVendorChanged?.( { ...site } ) }
                isValueLoading={ isVendorLoading }
                allowEmptyTerm={ true }
                getAutocompleteResults={ 
                    async ( term, abortController ) => await autocomplete( term, { abortController } ) 
                }
                disabled={ disabled }
            />

        </div>
    )
}