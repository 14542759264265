import { Autocomplete as MUIAutocomplete, CircularProgress } from "@mui/material";
import { Fragment, useState, useEffect, useRef } from "react";
import { defaultFieldVariant, __ } from "../../app";
import { TextField } from '@mui/material';

export default function Autocomplete( {
    label = __( 'Search' ),
    value = '',
    inputTimeout = 1000,
    allowEmptyTerm = false,
    isValueLoading = false,
    onValueChanged = false,
    getAutocompleteResults = false,
    className = "",
    disabled,
    readonly = false,
    autocompleteProps = {}
} ) {

    const [ term, setTerm ]       = useState( '' )
    const [ options, setOptions ] = useState( [] )
    const [ loading, setLoading ] = useState( false )
    const [ open, setOpen ]       = useState( false )
    const isMounted               = useRef( false );

    useEffect( () => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    } )

    var timeout = false;
    var abortController = false;

    function maybeDoAjaxSearch() {
        if( timeout ) clearTimeout( timeout );
        timeout = setTimeout( () => doAjaxSearch(), inputTimeout );
    }

    async function doAjaxSearch() {
        if( !term && !allowEmptyTerm ) {
            return;
        }

        // Abort previous request
        if( abortController ) {
            abortController?.abort();
        }
        abortController = new AbortController();


        // Get autocomplete results
        if( !isMounted.current ) return;
        setLoading( true )
        var results = await getAutocompleteResults?.( term, abortController )
        abortController = false;
        
        if( !isMounted.current ) return;
        if( !results?.data?.length ) {
            setLoading( false );
            setOptions( [] )
            return; // likely aborted
        }


        
        setLoading( false )
        setOptions( results.data );
    }

    // When term changes, refresh results
    useEffect(() => {
        maybeDoAjaxSearch();
    }, [ term ] )

    // function TheTextField( params ) {
    //     return (
    //     )
    // }

    return (
        <MUIAutocomplete
            className={ className }
            value={ value?.label ?  value : '' }
            onChange={ ( e, value ) => isMounted.current && onValueChanged( value ) } 
            disabled={ disabled || readonly }

            // sx={{ width: 300 }}
            open={ open }
            onOpen={ () => isMounted.current && setOpen( true ) }
            onClose={ () => isMounted.current && setOpen( false ) }
            onInputChange={ (event, term) => isMounted.current && setTerm( term ) }
            label={ label }
            
            options={ options }
            loading={ loading } 
            filterOptions={ (x) => x }
            renderInput={ params => (
                <TextField
                    id="autocompleteField"
                    disabled={ isValueLoading }
                    variant={ defaultFieldVariant }
                    { ...params }
                    label={ label }
                    InputProps={{
                        ...params.InputProps,
                        label,
                        endAdornment: (
                            <Fragment>
                                <div style={{ minWidth: '24px', marginTop: '4px' }}>
                                    { ( loading || isValueLoading ) && !value?.label ? <CircularProgress size={20} /> : false }
                                </div>
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                    }}
                />
            ) }

            
            { ...autocompleteProps }
        />
    );

}