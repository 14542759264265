import React from "react"
import { Card, CardBody } from "reactstrap"
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

import { __ } from "../../app"
import { apiHost } from "../../config"
import DataTable from '../../components/datatable'
import { Link, useHistory } from "react-router-dom";
import { urlEncode } from "../../helpers/url-encoding";
import PageHeader from "../../components/page-header";


var url = apiHost + "/api/admin/SiteOwner/datatables";
function getColumns( { history } ) {
    return [
        {
            headerName: __( 'Owner Id' ),
            field: 'ownerId',
            headerAlign: 'left',
            align: 'left',
            flex: 1,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/site-owners/id/" + row.ownerId } >{ formattedValue }</Link>
            ),
            hide: true
    
        },
        {
            headerName: __( 'Site Owner' ),
            field: 'companyName',
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/site-owners/id/" + row.ownerId } >{ formattedValue }</Link>
            ),
            flex: 2,
        },
        {
            headerName: __( 'Contact' ),
            field: 'contactName',
            flex: 2
        },
        {
            headerName: __( 'Email' ),
            field: 'email',
            flex: 2,
            renderCell: ( { row, formattedValue } ) => (
                <a href={ "mailto:" + row.email } >{ formattedValue }</a>
            ),
        },
        {
            headerName: __( 'Phone' ),
            field: 'phone',
            flex: 1,
            renderCell: ( { row, formattedValue } ) => (
                <a href={ "tel:" + row.phone } >{ formattedValue }</a>
            ),
        },
        {
            headerName: __( 'No. of Sites' ),
            field: 'totalSites',
            flex: 1,
            renderCell: ( { row, formattedValue } ) => {
                if( formattedValue < 1 ) {
                    return 0;
                }

                var url =  urlEncode( {
                    url: '/sites',
                    data: {
                        query: {
                            filters: {
                                items: [
                                    {
                                        columnField: "siteOwnerId",
                                        operatorValue: 'EQUAL',
                                        value: row.ownerId
                                    }
                                ]
                            }
                        }
                    }
                } );

                return (
                    <Link to={ url } >{ formattedValue }</Link>
                )
            }
        },
        {
            headerName: __( 'Street' ),
            field: 'street',
            flex: 3,
            hide: true
        },
        {
            headerName: __( 'City' ),
            field: 'city',
            flex: 1,
            hide: true
        },
        {
            headerName: __( 'State' ),
            field: 'state',
            flex: 1,
            hide: true
        },
        {
            headerName: __( 'Postcode' ),
            field: 'postCode',
            flex: 1,
            hide: true
        },
        {
            field: 'actions',
            headerName: __( 'Actions' ),
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem 
                    onClick={ () => history.push( '/site-owners/id/' + params.id )  } 
                    icon={<EditIcon />} 
                    label={ __("View / Edit Site Owner") } 
                />,
            ]
        }
    ];
}
 
export default function ListSiteOwners() {
    const history = useHistory();
    const columns = getColumns( { history } )
    
    return (
        <div className="table_list table__site-owners">

            <PageHeader
                header={ __( 'Site Owners' ) }
                breadcrumbs={ [ 
                    { 
                        to: '/site-owners',
                        label: __( 'SIte Owners' ),
                        active: true
                    }
                ] }
            />
            
            <Card>
                <CardBody>
                    <div style={{ height: 'calc( 100vh - 190px)', width: '100%' }}>
                        <div style={{ display: 'flex', height: '100%' }}>
                            <div style={{ flexGrow: 1 }}>
                                <DataTable 
                                    url={ url }
                                    columns={ columns }
                                    getRowId={ row => row.ownerId }
                                />
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>

        </div>
    )
}