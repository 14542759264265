import React from "react"
import { Card, CardBody } from "reactstrap"
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

import { __ } from "../../app"
import { apiHost } from "../../config"
import DataTable from '../../components/datatable'
import { Link, useHistory } from 'react-router-dom'

import PageHeader from "../../components/page-header";
import { enumVegetationTypes, enumZoneStatus } from "../../enums";


var url = apiHost + "/api/admin/Zone/datatables";
function getColumns( { history } ) {
    
    return [
        {
            headerName: __( 'Zone Id' ),
            field: 'zoneId',
            headerAlign: 'left',
            align: 'left',
            flex: 1,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/zones/id/" + row.zoneId } >{ formattedValue }</Link>
            ),
            hide: true
        },
        {
            headerName: __( 'Site' ),
            field: 'siteName',
            flex: 3,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/sites/id/" + row.siteId } >{ formattedValue }</Link>
            ),
        },
        {
            headerName: __( 'Site Reference' ),
            field: 'siteReference',
            flex: 3,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/sites/id/" + row.siteId } >{ formattedValue }</Link>
            ),
            hide: true
        },
        {
            headerName: __( 'Zone' ),
            field: 'zoneReference',
            flex: 1,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/zones/id/" + row.zoneId } >{ formattedValue }</Link>
            )
        },
        {
            headerName: __( 'Vegetation' ),
            field: 'biomeName',
            flex: 2,
            type: "singleSelect",
            valueOptions: enumVegetationTypes
        },
        {
            headerName: __( 'Site Id' ),
            field: 'siteId',
            flex: 1,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/sites/id/" + row.siteId } >{ formattedValue }</Link>
            ),
            hide: true
        },
        {
            headerName: __( 'Owner Id' ),
            field: 'ownerId',
            type: "number",
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            hide: true,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/site-owners/id/" + row.ownerId } >{ formattedValue }</Link>
            )
        },
        {
            headerName: __( 'Site Owner' ),
            field: 'siteOwner',
            flex: 3,
            renderCell: ( { row, formattedValue } ) => (
                <Link to={ "/site-owners/id/" + row.ownerId } >{ formattedValue }</Link>
            )
        },
        {
            headerName: __( 'Contact' ),
            field: 'contactName',
            flex: 3,
            hide: true
        },
        {
            headerName: __( 'Email' ),
            field: 'contactEmail',
            flex: 3,
            renderCell: ( { row, formattedValue } ) => (
                <a href={ "mailto:" + row.contactEmail } >{ formattedValue }</a>
            ),
            hide: true
        },
        {
            headerName: __( 'Phone' ),
            field: 'contactPhone',
            flex: 2,
            renderCell: ( { row, formattedValue } ) => (
                <a href={ "tel:" + row.contactPhone } >{ formattedValue }</a>
            ),
            hide: true
        },
        {
            headerName: __( 'Units Sold' ),
            field: 'unitsSold',
            type: "number",
            align: 'left',
            headerAlign: 'left',
            flex: 1,
        },
        {
            headerName: __( 'Units Retired' ),
            field: 'unitsRetired',
            align: 'left',
            headerAlign: 'left',
            flex: 1,
            hide: true
        },
        {
            headerName: __( 'Units Allocated' ),
            field: 'unitsAllocated',
            type: "number",
            align: 'left',
            headerAlign: 'left',
            flex: 1
        },
        {
            headerName: __( 'Units Unallocated' ),
            field: 'unitsUnallocated',
            align: 'left',
            headerAlign: 'left',
            type: "number",
            flex: 1,
            hide: true
        },
        {
            headerName: __( 'Total Units' ),
            field: 'totalUnits',
            type: "number",
            align: 'left',
            headerAlign: 'left',
            flex: 1
        },
        {
            headerName: __( 'Status' ),
            field: 'status',
            flex: 1,
            type: "singleSelect",
            valueOptions: enumZoneStatus
        },
        {
            field: 'actions',
            headerName: __( 'Actions' ),
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem 
                    onClick={ () => history.push( '/zones/id/' + params.id )  } 
                    icon={<EditIcon />} 
                    label={ __("View / Edit Zone") } 
                />,
            ]
        }
    ];
}

export default function ListZones() {
    const history = useHistory();
    const columns = getColumns( { history } )
    
    return (
        <div className="table_list table__zones">

            <PageHeader
                header={ __( 'Zones' ) }
                breadcrumbs={ [ 
                    { 
                        to: '/zones',
                        label: __( 'Zones' ),
                        active: true
                    }
                ] }
            /> 
            
            <Card>
                <CardBody>
                    <div style={{ height: 'calc( 100vh - 190px)', width: '100%' }}>
                        <div style={{ display: 'flex', height: '100%' }}>
                            <div style={{ flexGrow: 1 }}>
                                <DataTable 
                                    url={ url }
                                    columns={ columns }
                                    getRowId={ row => row.zoneId }
                                />
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>

        </div>
    )
}