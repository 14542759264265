import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { maybeSimulateLoading } from "../../app";

export async function fetchResultsByTerm( url, term, { params = {}, abortController = new AbortController() } = {} ) {

    // Simulate loading if in demo mode
    await maybeSimulateLoading();

    // Fetch results 
    const response = await axios.get( url, {
        signal: abortController.signal,
        params: { ...params, term }
    });

    if( axios.isCancel( response ) ) {
        return false;
    }


    return response;

}

export async function fetchResultByUrl( url, { params = {}, abortController = new AbortController() } = {} ) {

    // Simulate loading if in demo mode
    await maybeSimulateLoading();

    const response = await axios.get( url, {
        signal: abortController?.signal,
        params
    } );

    if( axios.isCancel( response ) ) {
        return false;
    }

    return response;
}

export function useFetchResultByUrl( {
    initialState = {},
    getId = r => r?.Id,
    getUrl = false,
    params = {}, 
    abortController = new AbortController() } = {} 
) {

    const [ isLoading, setLoading ] = useState( false );
    const [ result, _setResult ] = useState( {
        label: false,
        error: false,
        response: false,
        ... initialState
    } )

    // Wrap set result for debugging / later notifications
    function setResult( result ) {
        _setResult( result )
    }

    // Autoload ID / Label if either are undefined
    useEffect( () => {
        async function fetch() {
                
            // Don't run if a response is already set or already loading for this result
            if( result.response || isLoading ) {
                return;
            }

            // Don't run if label is already loaded or resultId is invalid
            // TODO: Don't know why things are being converted to strings here but it's breaking null|false values, this is a hotfix
            if( !getId( result ) || result?.label !== false || getId( result ) == 'undefined' || getId( result ) < 1 || getId( result ) == 'false') {
                return; 
            }
            
            var url = getUrl( result );
            if( !url ) {
                return;
            }

            // Fetch result
            setLoading( true );
            var response = await fetchResultByUrl( url, { params, abortController } );
            if( response?.data ) {
                setResult( { ... response.data, error: false, response } );
                setLoading( false )
                return; 
            }

            // Error
            setResult( { ... result, error: true, response } )
            setLoading( false )
        }

        fetch();
    }, [ result, isLoading, abortController, getId, getUrl ] );

    
    // Reset state if the initial state id differs from the result's
    useEffect( () => {
        if( getId( initialState ) != getId( result ) ) {
            setResult( {
                label: false,
                error: false,
                response: false,
                ... initialState
            } )

            setLoading( false );
        }
    }, [ getId, initialState, result ] )

    return [ result, setResult, isLoading ];
} 